import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import DataGridToolTip from './DataGridToolTip';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  color: #0000ffba;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: underline;
`;

function handleClick(
  onClickHandler,
  parentProps,
  actionColumnType,
  hrefValue,
  hyperlinkDBCode,
  rowDetails,
  dispatch,
  modalCss
) {
  let column = {};
  column['actionColumnType'] = actionColumnType;
  column['hrefValue'] = hrefValue;
  column['hyperlinkDBCode'] = hyperlinkDBCode;
  column['modalCss'] = modalCss;
  if (onClickHandler) {
    onClickHandler(column, rowDetails, parentProps, dispatch);
  }
}

const HyperLinkCell = ({ cell, row, column: { id } }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(cell.value);
  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);
  return cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    !cell.column.isEllipsesEnable ? (
    <StyledDiv
      onClick={(e) =>
        handleClick(
          cell.column.onClickHandler,
          cell.column.parentProps,
          cell.column.actionColumnType,
          cell.column.hrefValue,
          cell.column.hyperlinkDBCode,
          row,
          dispatch,
          cell.column.modalCss
        )
      }
    >
      {value}
    </StyledDiv>
  ) : cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    cell.column.isEllipsesEnable ? (
    <StyledDiv
      onClick={(e) =>
        handleClick(
          cell.column.onClickHandler,
          cell.column.parentProps,
          cell.column.actionColumnType,
          cell.column.hrefValue,
          cell.column.hyperlinkDBCode,
          row,
          dispatch,
          cell.column.modalCss
        )
      }
    >
      <DataGridToolTip width={cell.column.width} tooltipValue={value} />
    </StyledDiv>
  ) : (
    // <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    // </React.Fragment>
  );
};

HyperLinkCell.propTypes = {
  column: PropTypes.any, 
  cell: PropTypes.shape({
    value: PropTypes.any, 
    isRepeatedValue: PropTypes.any, 
    column: PropTypes.shape({
      isEllipsesEnable: PropTypes.any, 
      onClickHandler: PropTypes.any, 
      parentProps: PropTypes.any, 
      actionColumnType: PropTypes.any, 
      hrefValue: PropTypes.any, 
      hyperlinkDBCode: PropTypes.any,
      modalCss: PropTypes.any, 
      width: PropTypes.any, 
    }),
  }),
  row: PropTypes.any, 
};

export default React.memo(HyperLinkCell);
