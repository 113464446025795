import {
  call,
  put,
  takeEvery,
  all,
  fork,
  select,
  // takeLatest,
} from 'redux-saga/effects';
import {
  GET_CHART_DATA,
  setChartData,
  setStore,
  GET_MY_PROCESS_INSTANCE_DATA,
  GET_MY_TASK_DATA,
  GET_SEND_FEEDBACK_DATA,
  UPDATE_WORKFLOW_STATUS,
  GET_CONFIGURATION_STRUCTURE,
} from '../actions/index';
import * as base from './base';
import CardData from '../models/carddata.model';
import { CardType, UpsertMessage } from '../constants/appeng.enum';
import cloneDeep from 'lodash/cloneDeep';
import { executeJavascriptPreprocessorUsingFunction } from '../utils/ClientUtils';

const getCard = (state: any) => state.appState.cardsData;

export default function* basicSaga() {
  yield all([
    fork(watchGetChartData),
    fork(watchGetMyTaskData),
    fork(watchGetSendFeedbackData),
    fork(watchGetMyProcessInstanceData),
    fork(watchUpdateWorkflowStatus),
    fork(watchGetConfiguarationStructure),
  ]);
}

function* getChartDataSaga(action: any): Generator<any, any, any> {
  let chartData: any = {};
  let processedValue: any = {};
  const existingCardData = yield select(getCard);
  const { data } = yield call(
    base.getConfigData,
    'WidgetQuery',
    action.chartId
  );
  const hasPreprocessor =
    data.Widget.datasetquery &&
    data.Widget.datasetquery.includes('JS_PREPROCESSOR:');
  if (hasPreprocessor) {
    const dataToRefer: any = {};
    Object.assign(
      dataToRefer,
      existingCardData,
      action.data,
      base.getUserDetail(),
      { SubComponentId: action.referralId.split('_')[0] + '_' }
    );
    chartData = executeJavascriptPreprocessorUsingFunction(
      data.Widget.datasetquery
        ? data.Widget.datasetquery.split('JS_PREPROCESSOR:')[1]
        : '',
      dataToRefer
    );
  } else if (!hasPreprocessor && data.Widget.datasetquery) {
    chartData = yield call(
      base.getChartData,
      action.chartId,
      Object.assign(action.data, base.getUserDetail())
    );
  }
  if (data.Widget.dataParser) {
    const dataToRefer: any = {};
    let refinedData: any = '';
    Object.assign(
      dataToRefer,
      existingCardData,
      action.data,
      base.getUserDetail(),
      { SubComponentId: action.referralId.split('_')[0] + '_' }
    );
    if (Object.keys(chartData).length) {
      refinedData = base.formatChartResponse(cloneDeep(chartData));
      Object.assign(dataToRefer, { queryData: [...refinedData] });
    }
    processedValue = executeJavascriptPreprocessorUsingFunction(
      data.Widget.dataParser,
      dataToRefer
    );
  }

  const card = existingCardData[action.referralId]
    ? { ...existingCardData[action.referralId] }
    : {};

  let referenceData: any = Object.assign(action.data, base.getUserDetail());
  if (
    !processedValue ||
    (Object.keys(processedValue).length === 0 && data.Widget.defaultSelected)
  ) {
    if (
      chartData.columns &&
      chartData.columns.length > 0 &&
      chartData.rows &&
      chartData.rows.length > 0
    ) {
      referenceData[chartData.columns[0]] = chartData.rows[0][0];
    }
  } else if (
    !processedValue ||
    (Object.keys(processedValue).length === 0 && !data.Widget.defaultSelected)
  ) {
    if (
      chartData.columns &&
      chartData.columns.length > 0 &&
      chartData.rows &&
      chartData.rows.length > 0
    ) {
      referenceData[chartData.columns[0]] = 'dummy';
    }
  }

  const cardData = new CardData(
    action.referralId,
    CardType.WIDGET,
    processedValue && Object.keys(processedValue).length
      ? processedValue
      : chartData,
    action.portalId,
    action.parentId,
    referenceData,
    card.errorData,
    card.options
  );
  yield put(setChartData(cardData, action.referralId));
}

function myProcessInstanceParser(gridDatas: any) {
  let gridDataList: any = [];
  gridDatas.map((gridData: any) => {
    let dataMap: any = {};
    dataMap['INO_PROC_INST_ID_'] = gridData.inoProcessInstanceId;
    dataMap['INO_PROC_INST_DESC_'] = gridData.processInstanceId;
    dataMap['INO_INITIATOR_'] = gridData.inoInitiator;
    dataMap['INSTANCE_CREATION'] = gridData.startTime;
    dataMap['START_TIME_'] = gridData.startTime;
    dataMap['TASK'] = gridData.processItemName;
    dataMap['ASSIGNED_TO'] = gridData.assignee;
    gridDataList.push(dataMap);
  });
  return gridDataList;
}
function myTaskParser(gridDatas: any) {
  let gridDataList: any = [];
  gridDatas.map((gridData: any) => {
    let dataMap: any = {};
    dataMap['PROCESS_ITEM_ID'] = gridData.id;
    dataMap['PROCESS_ITEM_NAME'] = gridData.processItemName;
    dataMap['PROCESS'] = gridData.processItemName;
    dataMap['INITIATOR'] = gridData.inoInitiator;
    dataMap['ITEM_CREATION_TIME'] = gridData.processInstanceStartTime;
    dataMap['TASK'] = gridData.taskName;
    dataMap['ASSIGNED_TO'] = gridData.assignee;
    dataMap['TASK_START_TIME'] = gridData.taskCreateTime;
    dataMap['TASK_STATUS'] = 'Active';
    gridDataList.push(dataMap);
  });
  return gridDataList;
}
function sendFeedbackParser(gridDatas: any) {
  let gridDataList: any = [];
  gridDatas.map((gridData: any) => {
    let dataMap: any = {};
    dataMap['FEEDBACK_UUID'] = gridData.feedbackUuid;
    dataMap['SUBMITTER_USER_ID'] = gridData.submitterUserId;
    dataMap['APPLICATION_NAME'] = gridData.applicationName;
    dataMap['PRODUCT_NAME'] = gridData.productName;
    dataMap['TENNANT_NAME'] = gridData.tennantName;
    dataMap['FEEDBACK_TITLE'] = gridData.feedbackTitle;
    dataMap['COMMENTS'] = gridData.comments;
    dataMap['SUBMISSION_DATE'] = gridData.submissionDate;
    gridDataList.push(dataMap);
  });
  return gridDataList;
}
function* getMyProcessInstanceData(action: any): Generator<any, any, any> {
  const response = yield call(base.getMyProcessInstanceData);
  let datas = myProcessInstanceParser(response);
  yield put(setChartData({ data: datas }, action.gridId));
}
function* getMyTaskData(action: any): Generator<any, any, any> {
  const userSessionData = base.getUserDetail();
  const response = yield call(base.getMyTaskData, {
    roleId: userSessionData.APP_LOGGED_IN_ROLE_ID,
    userId: userSessionData.APP_LOGGED_IN_USER_ID,
  });
  let datas = myTaskParser(response);
  yield put(setChartData({ data: datas }, action.gridId));
}
function* getSendFeedbackData(action: any): Generator<any, any, any> {
  const response = yield call(base.getSendFeedbackData);
  let datas = sendFeedbackParser(response);
  yield put(setChartData({ data: datas }, action.gridId));
}

function* updateWorkflowStatus(action: any): Generator<any, any, any> {
  const response = yield call(base.upsertEntityRecord, action.reqBody);
  if (response.pk) {
    const existingCardsData = yield select(getCard);
    const cardsData = { ...existingCardsData };
    const existingCard = cardsData[action.cardId];
    const rd = {
      ...existingCard.referenceData,
      AE_RELOAD: {
        ID: response.transactionId,
        TS: new Date().getTime(),
        STATUS: 'R',
      },
    };
    const cardData = new CardData(
      action.cardId,
      existingCard.type,
      existingCard.data,
      existingCard.portalId,
      existingCard.parentId,
      rd,
      existingCard.errorData,
      existingCard.options,
      existingCard.datagridErrorData,
      existingCard.toggleTabRefresh
    );
    cardsData[action.cardId] = cardData;

    const messages: string[] = [];
    messages.push(
      response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
    );
    const updateStore: any = {
      cardsData: cardsData,
      toggleMessages: messages,
    };
    yield put(setStore(updateStore));
  }
}

function* getConfiguarationStructure(action: any): Generator<any, any, any> {
  const response = yield call(
    base.getConfiguarationStructure,
    action.applicationId,
    action.parentId
  );
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(response)]));
  let a = document.createElement('a');
  a.href = url;
  a.download = action.parentId + '.json';
  a.click();
}

function* watchUpdateWorkflowStatus() {
  yield takeEvery(UPDATE_WORKFLOW_STATUS, updateWorkflowStatus);
}

function* watchGetChartData() {
  yield takeEvery(GET_CHART_DATA, getChartDataSaga);
}

function* watchGetMyTaskData() {
  yield takeEvery(GET_MY_TASK_DATA, getMyTaskData);
}

function* watchGetSendFeedbackData() {
  yield takeEvery(GET_SEND_FEEDBACK_DATA, getSendFeedbackData);
}
function* watchGetMyProcessInstanceData() {
  yield takeEvery(GET_MY_PROCESS_INSTANCE_DATA, getMyProcessInstanceData);
}

function* watchGetConfiguarationStructure() {
  yield takeEvery(GET_CONFIGURATION_STRUCTURE, getConfiguarationStructure);
}
