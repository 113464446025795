export * from './error.code.enum';
export * from './auth.message';
export * from './storage.enum';
export * from './provider.enum';

export const AUTH: string = 'auth';
export const SECRET_KEY: any = 'defaultsecret';
export const ID_TOKEN_KEY: string = 'info-auth-id-token';
export const REFRESH_TOKEN_KEY: string = 'info-auth-refresh-token';
export const ACCESS_TOKEN_KEY: string = 'info-auth-access-token';

