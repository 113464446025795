import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabGroup, FormErrorContainers, Button } from 'info-ui-library';
import { injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';

import { StyledConfirModalDiv } from '../form/FormStyle';
import { IComplexForm } from '../interfaces/index';
import {
  generateComplexFormSectionCards,
  generateComplexFormSectionGridCards,
  isConfigObjectAccessible,
} from './CardGenerators';
import { ThemeContext } from '../../theme/ThemeContext';
import { handleRepeatable } from '../../actions';
import SkeletonCore from '../../components/SkeletonCore';
import { UserContext } from '../../user-context/UserContext';
import { getPrivilege, isExpressionResolved } from '../../utils/ClientUtils';
import { NOPRIVILEGE } from '../../constants/appeng.enum';
import { extractLabel } from '../../utils/intlutils';
import { StyledComplexFormDiv, StyledComplexFormSpan } from './FormStyle';
import {
  getEditFormButtons,
  getFormValidationMessage,
} from '../CompositeEntityCommonUtils';
import { useLocation } from 'react-router';
import 'react-confirm-alert/src/react-confirm-alert.css';


const isErrorDataPresent = (existingErrorData: any) => {
  let isErrorDataAvailable =
    existingErrorData &&
    existingErrorData.errorData &&
    Object.entries(existingErrorData.errorData.formValidation).length > 0
      ? true
      : false;
  return isErrorDataAvailable;
};

const isChildPresent = (childFormList: any) => {
  let isChildFormAvailable = childFormList ? true : false;
  return isChildFormAvailable;
};

const isShowChildGrid = (childObject: any) => {
  let isChildGridAvailable = childObject.showGridOnly ? true : false;
  return isChildGridAvailable;
};

const isChildAddAllowedAndOpen = (formData: any, childObject: any) => {
  let isChildFormAvailable =
    Object.keys(formData).length > 0 &&
      Object.keys(formData[0]['child']).length > 0 &&
      Object.keys(formData[0]['child'][childObject.form.configObjectId]).length >
      0
      ? true
      : false;
  return isChildFormAvailable;
};

const isMaxRepeatCompleted = (formData: any, childObject: any) => {
  let isMaxRepeatedDone =
    childObject.form.maxRepeatation ===
      Object.keys(formData[0]['child'][childObject.form.configObjectId]).length
      ? true
      : false;
  return isMaxRepeatedDone;
};

const isMinRepeatCompleted = (formData: any, childObject: any) => {
  let isMinRepeatedDone =
    childObject.form.minRepeatation ===
      Object.keys(formData[0]['child'][childObject.form.configObjectId]).length
      ? true
      : false;
  return isMinRepeatedDone;
};

const getChildFormData = (formData: any, childObject: any, key: any) => {
  return formData[0]['child'][childObject.form.configObjectId][key];
};

const isNodeAccessibleCheck = (childObject: any, updatedFormData: any) => {
  let isNodeAccessible = !childObject.expressionAvailable
    ? true
    : childObject.expressionAvailable &&
    childObject.accessibilityRegex &&
    !isExpressionResolved(childObject.accessibilityRegex, updatedFormData);
  return isNodeAccessible;
};

const isChildAddAllowed = (formData: any, childObject: any) => {
  let isAddAllowed =
    childObject.form.isRepeatable && childObject.form.addAllowed ? true : false;
  return isAddAllowed;
};

const getDisplayComponent = (
  formDataObject: any,
  props: any,
  themeContext: any,
  handleClick: any,
  onChange: any,
  activeTab: any,
  userContext: any,
  existingErrorData: any,
  tabDetailMap: any,
  setTabDetailMap: any,
  attachmentTypeError: any
) => {
  if (formDataObject) {
    const formData = formDataObject.data;
    let tabOptions = {};
    let errorData: string[] = [];

    if (isErrorDataPresent(existingErrorData)) {
      const error = getFormValidationMessage(
        props.entityId + '=>' + 0,
        existingErrorData,
        props.intl.locale
      );
      if (error) {
        errorData.push(error);
      }
    }

    let details = {
      'nodeExpressionAvailble': props.nodeExpressionAvailble,
      'nodeEditabilityRegex': props.nodeEditabilityRegex,
      'portalFormPrivilege': props.portalFormPrivilege
    }

    tabOptions = generateComplexFormSectionCards(
      props.nodeName,
      props.parentForm.formSections,
      themeContext,
      tabOptions,
      props.parentForm.configObjectId,
      undefined,
      props.mode,
      0,
      undefined,
      false,
      false,
      props.nodeId,
      formData[0],
      userContext,
      props.entityId,
      undefined,
      props.intl.locale,
      props.parentForm.addAllowed,
      props.parentForm.isDeletionAllowed,
      props.formPrivilege,
      props.isFormEditable,
      props.referralId,
      tabDetailMap,
      setTabDetailMap,
      false,
      false,
      details
    );

    if (isChildPresent(props.childFormList)) {
      props.childFormList.map((childObject: any) => {
        let isFirstFormData = true;

        if (isShowChildGrid(childObject)) {
          let referId = props.compositeEntityKey + '_' + childObject.gridId;
          let newProps = {
            nodeName: childObject.nodeName,
            context: themeContext,
            tabOptions: tabOptions,
            gridId: childObject.gridId,
            nodeId: childObject.nodeId,
            referralId: referId,
            routerParameter: props.routerParameter,
            locale: props.intl.locale,
          };
          Object.assign(
            tabOptions,
            generateComplexFormSectionGridCards(newProps)
          );
        } else {
          if (isChildAddAllowedAndOpen(formData, childObject)) {
            Object.keys(formData[0]['child'][childObject.form.configObjectId])
              .map(Number)
              .sort((a, b) => a - b)
              .map((key) => {
                if (isErrorDataPresent(existingErrorData)) {
                  const error = getFormValidationMessage(
                    props.entityId +
                    '=>' +
                    0 +
                    '=>' +
                    childObject.entityId +
                    '=>' +
                    key,
                    existingErrorData,
                    props.intl.locale
                  );
                  if (error) {
                    errorData.push(error);
                  }
                }

                const childFormData = getChildFormData(
                  formData,
                  childObject,
                  key
                );
                let updatedFormData = { ...childFormData };
                Object.assign(updatedFormData, userContext.getCurrentRole());
                let details = {
                  'nodeExpressionAvailble': props.nodeExpressionAvailble,
                  'nodeEditabilityRegex': props.nodeEditabilityRegex,
                  'portalFormPrivilege': props.portalFormPrivilege
                }
                if (isNodeAccessibleCheck(childObject, updatedFormData)) {
                  // call generator for child node
                  Object.assign(tabOptions, generateComplexFormSectionCards(childObject.nodeName,
                    childObject.form.formSections, themeContext, tabOptions,
                    childObject.form.configObjectId, props.parentForm.configObjectId,
                    props.mode, key, handleClick,
                    childObject.form.isRepeatable, isFirstFormData,
                    props.nodeId, childFormData, userContext, props.entityId
                    , childObject.entityId, props.intl.locale,
                    childObject.form.addAllowed, childObject.form.isDeletionAllowed,
                    props.formPrivilege, props.isFormEditable, props.referralId, tabDetailMap, setTabDetailMap,
                    isMaxRepeatCompleted(formData, childObject), isMinRepeatCompleted(formData, childObject),details))
                }
                isFirstFormData = false;
                return null;
              })
          } else if (isChildAddAllowed(formData, childObject)) {
            let isAccessible = isConfigObjectAccessible(childObject && childObject.form && childObject.form.accessibilityRegex, formData && formData[0]);
            if (isAccessible) {
              let details = {
                'nodeExpressionAvailble': props.nodeExpressionAvailble,
                'nodeEditabilityRegex': props.nodeEditabilityRegex,
                'portalFormPrivilege': props.portalFormPrivilege
              }
              const tb: any = generateComplexFormSectionCards(childObject.nodeName,
                childObject.form.formSections, themeContext, tabOptions,
                childObject.form.configObjectId, props.parentForm.configObjectId,
                props.mode, 0, handleClick,
                childObject.form.isRepeatable, isFirstFormData,
                props.nodeId, {}, userContext, props.entityId
                , childObject.entityId, props.intl.locale,
                childObject.form.addAllowed, childObject.form.isDeletionAllowed,
                props.formPrivilege, props.isFormEditable,
                props.referralId, tabDetailMap, setTabDetailMap, false, false,details);
              Object.assign(tabOptions, tb);
            }
          }
        }
        return null;
      })
    }
    // privilege check for button panel
    const buttonPanelPrivilege = getPrivilege(props.buttonPanel ? props.buttonPanel.privileges : props.parentForm.buttonPanels.privileges,
      userContext.getCurrentRole());
    let editFormButtons;
    if (!props.modalRequired && buttonPanelPrivilege !== NOPRIVILEGE) {
      editFormButtons = getEditFormButtons(props.nodeId, props.mode,
        props.displayName, props.routerParameter.nodeType, props.keys,
        props.routerParameter.referenceData,
        props.ceId, props.buttonPanel.buttons,
        buttonPanelPrivilege, props.parentForm.configObjectId,
        themeContext, props.nodeEditable, props.gridId,
        props.referralId, props.routerParameter, props.resetForm, props.portalForm, props.entityName,
        props.compositeEntityKey, props.setCardButtons,
        props.portalId, props.parentId,
        props.usePortalReferenceData,
        props.handleClick, props.buttonPanel.menuButtons);
    }
    let error;
    if (errorData.length > 0) {
      error = (
        <FormErrorContainers ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={'danger'}
          lists={errorData} />
      )
    }
    let tabErrorClassMap: any = {};
    if (existingErrorData && existingErrorData.errorData) {
      if (tabDetailMap) {
        Object.keys(tabDetailMap).map(tabName => {
          Object.keys(tabDetailMap[tabName]).map(dbCode => {
            let dbList = dbCode.split("#");
            Object.keys(existingErrorData.errorData.formFieldValidation).map(errorKey => {
              if ((errorKey.split('=>')[0] === dbList[0] && errorKey.split('=>')[2] === dbList[1]) || (errorKey.split('=>').length > 4 && errorKey.split('=>')[2] === dbList[0] && errorKey.split('=>')[4] === dbList[1])) {
                tabErrorClassMap[tabName] = "error";
              }
            })
          })
        })
      }
    }
    if (attachmentTypeError) {
      if (tabDetailMap && Object.keys(tabDetailMap).includes("Attachment")) {
        tabErrorClassMap["Attachment"] = "error";
      }
    }
    const displayComponent = (
      <React.Fragment>
        <div>
          {!props.setCardLabel &&
            <StyledComplexFormSpan
              styleName={themeContext.styleName}
              ioTheme={themeContext.ioTheme}
              ioMode={themeContext.ioMode}>
              {extractLabel(props.nodeName, props.intl.locale)}
            </StyledComplexFormSpan>}
          {!props.setCardButtons && editFormButtons}
        </div>
        <StyledComplexFormDiv $styleName={themeContext.styleName}
          $ioTheme={themeContext.ioTheme}

          data-tip={extractLabel(props.nodeName, props.intl.locale)}
          $ioMode={themeContext.ioMode}
          id={props.parentForm.configObjectId}
          name={props.parentForm.configObjectType}>
          {error}
          <TabGroup ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
            tabErrorClassMap={tabErrorClassMap}
            showInkBar={true}
            options={tabOptions}
            onChange={(e: any) => onChange(e)}
            selectedTabKey={activeTab ? activeTab : Object.keys(tabOptions)[0]} />
        </StyledComplexFormDiv>
      </React.Fragment>
    )
    return { displayComponent, editFormButtons }
  } else {
    const displayComponent = <SkeletonCore count={1} />
    const editFormButtons = <SkeletonCore count={1} />
    return { displayComponent, editFormButtons };
  }
}

const ComplexFormGenerator: React.FC<IComplexForm> = (props: IComplexForm) => {
  const currentLocation = useLocation();
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('');
  const [tabDetailMap, setTabDetailMap] = useState({});
  const onChange = (value: string, tabOptions: any) => {
    if (value && activeTab !== value) {
      setActiveTab(value);
    }
  };

  const handleClick = (formId: any, parentFormId: any, operationType: any, occuranceNumber: any, userDetail: any, isNewFormData: any) => {
    const formDataRepObject = {
      isNew: true
    };
    Object.assign(formDataRepObject, userDetail);
    if (operationType !== 'DELETE') {
      dispatch(handleRepeatable(formDataRepObject, formId,
        parentFormId, operationType, occuranceNumber, props.nodeId, props.mode, props.referralId, currentLocation,
        props.parentId, props.portalId, props.usePortalReferenceData))
    } else {
      //todo: Zinel need to move to a different component
      if (isNewFormData) {
        dispatch(handleRepeatable(formDataRepObject, formId, parentFormId, operationType, occuranceNumber, props.nodeId, props.mode, props.referralId, currentLocation, props.parentId, props.portalId, props.usePortalReferenceData))
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <StyledConfirModalDiv $ioTheme={themeContext.ioTheme}
                $ioMode={themeContext.ioMode}
                $styleName={themeContext.styleName} className='custom-ui'>
                <h1>Are you sure?</h1>
                {/* <p>You want to delete this file?</p> */}
                <Button ioTheme={themeContext.ioTheme}
                  ioMode={themeContext.ioMode}
                  styleName={themeContext.styleName} onClick={onClose}
                  disabled={false}
                  name={'No'} />
                <Button ioTheme={themeContext.ioTheme}
                  ioMode={themeContext.ioMode}
                  styleName={themeContext.styleName}
                  disabled={false}
                  name={'Yes'}
                  onClick={() => {
                    dispatch(handleRepeatable(formDataRepObject, formId,
                      parentFormId, operationType, occuranceNumber, props.nodeId, props.mode, props.referralId, currentLocation,
                      props.parentId, props.portalId, props.usePortalReferenceData))
                    onClose();
                  }}
                />
              </StyledConfirModalDiv>
            );
          }
        });
      }
    }

  }

  // fetch formdata from store
  const existingCardsData = useSelector((state: any) => state.appState.cardsData);
  const formDataObject = existingCardsData[props.referralId];
  const attachmentTypeError = useSelector((state: any) => state.appState.cardsData[props.referralId]?.attachmentTypeError);
  // fetch the error data from store
  // const existingErrorData = useSelector((state: any) => state.errorData);
  const existingErrorData = formDataObject ? formDataObject.errorData : undefined;

  const { displayComponent, editFormButtons } = getDisplayComponent(
    formDataObject,
    props,
    themeContext,
    handleClick,
    onChange,
    activeTab,
    userContext,
    existingErrorData,
    tabDetailMap,
    setTabDetailMap,
    attachmentTypeError
  );

  useEffect(() => {
    if (props.setCardButtons) {
      props.setCardButtons(editFormButtons);
    }
    if (props.setCardLabel) {
      props.setCardLabel(props.nodeName);
    }
  }, [props.referralId]);
  try {
    return <React.Fragment>{displayComponent}</React.Fragment>;
  } catch {
    const error = ['Error occurred in Complex Form Generator'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default injectIntl(React.memo(ComplexFormGenerator));