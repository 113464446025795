import React, { useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { FormErrorContainers, FloatingButton } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';

export interface IFloatingButtonCore {
  handleClick: any;
  intl: any;
  styleName: any;
  ioTheme: any;
  ioMode: any;
  configId: any;
  name: any;
  disabled: any;
  buttonClass: any;
  buttons: any[];
  showloadingSymbol: boolean;
}

const FloatingButtonCore: React.FC<IFloatingButtonCore> = (
  props: IFloatingButtonCore
) => {
  const themeContext = useContext(ThemeContext);

    
    try {
        const handleClick = useCallback((buttonClass: string, lable: string, childRelations: any[], configObjectId: string) => {
            props.handleClick(buttonClass, lable, false, childRelations, configObjectId);
        },[]);
        return (
            <FloatingButton
                menuLabel={extractLabel(props.name, props.intl.locale)}
                menuButtons={props.buttons}
                styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                configId={props.configId}
                disabled={props.disabled}
                handleClick={handleClick}
                buttonClass={props.buttonClass}
                showloadingSymbol={props.showloadingSymbol}
                key={"Unique_"+ props.configId} />
        )
    }
    catch {
        const error = ['Error occurred in Floating Button']
        return <FormErrorContainers $ioTheme={themeContext.ioTheme}
            $ioMode={themeContext.ioMode}
            $styleName={'danger'}
            lists={error} />
    }

}

export default React.memo(injectIntl(FloatingButtonCore));
