import { OktaAuth } from '@okta/okta-auth-js';

export const refreshOktaToken = async (config: any) => {
  const authClient = new OktaAuth({
    clientId: config.provider[config.provider.type].clientId,
    redirectUri: config.provider[config.provider.type].redirectUri, // Or the redirect URI for your app
    issuer: config.provider[config.provider.type].issuer, // OIDC URL (e.g., https://dev-123456.okta.com/oauth2/default)
    scopes: config.provider[config.provider.type].scopes,
    postLogoutRedirectUri:
      config.provider[config.provider.type].postLogoutRedirectUri,
    pkce: true,
  });
  
  try {
    const freshTokens: any = await authClient.tokenManager.renew("accessToken");
    if (config.storage.type === 'session') {
        sessionStorage.setItem('REFRESH_AUTH_TOKEN', freshTokens.accessToken);
      } else if (config.storage.type === 'window') {
        localStorage.setItem('REFRESH_AUTH_TOKEN', freshTokens.accessToken);
      } else {
        throw new Error('Invalid storage type specified');
      }
  } catch (err) {
    console.error("Failed to refresh token:", err);
    return null; // Handle refresh failure (e.g., user must re-login)
  }
};
