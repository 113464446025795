import React from 'react';
import { DateTimePicker, Button } from 'info-ui-library';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  &.btn {
    position: relative;
    top: 4px;
  }
`;

function DatePickerFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    styleName,
    ioMode,
    ioTheme,
    dateFormat,
    width,
  },
}) {
  const [value, setValue] = React.useState(filterValue || undefined);
  const [showPicker, setShowPicker] = React.useState(!value);  // Show picker only if no date is selected

  const onChange = (e) => {
    if (e) {
      setFilter(moment.utc(e).toISOString()); // Update the filter value
      setValue(e);  // Update the value state with the selected date
      setShowPicker(false);  // Hide the picker once a date is selected
    } else {
      setFilter(undefined);  // Clear the filter if no date is selected
      setValue(undefined);  // Reset the value state
      setShowPicker(true);   // Show the picker again if the filter is cleared
    }
  };

  let dateFormatReplace = dateFormat
    ? dateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')
    : null;
  let [dateFormatValue] = dateFormatReplace.split(' ');

  let utcToLocalDate = value
    ? moment.utc(value).local().format(dateFormat ? dateFormat.toUpperCase() : 'DD-MMM-YYYY')
    : null;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {showPicker && (  // Only show the DateTimePicker if showPicker is true
        <DateTimePicker
          placeholderText={'Please select a date'}
          styleName={styleName}
          ioTheme={ioTheme}
          ioMode={ioMode}
          label={''}
          displayType={'SingleLine'}
          showTimeSelect={false}
          showTimeSelectOnly={false}
          dateFormat={dateFormatValue ? dateFormatValue : 'yyyy-MM-dd'}
          onChange={(e) => onChange(e)}
          withPortal
          widthCell={width}
          selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
          isInlineGridComponent={true}
          className={'inlineGrid'}
        />
      )}
      
      {/* Display selected date if present */}
      {value && !showPicker && (
        <div style={{ marginTop: '5px', fontSize: '14px' }}>
          Selected Date: {utcToLocalDate}
        </div>
      )}
      
      {value && (
        <StyledButton
          onClick={() => {
            onChange(undefined);  // Clear the filter and show the picker again
          }}
          disabled={false}
          styleName={styleName}
          ioTheme={ioTheme}
          ioMode={ioMode}
          className="fa fa-times-circle"
          name={''}
          title={'Clear Filter'}
          placeHolder={`Search records...`}
        />
      )}
    </div>
  );
}

DatePickerFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.any,
    preFilteredRows: PropTypes.any,
    setFilter: PropTypes.func.isRequired,
    styleName: PropTypes.string,
    ioMode: PropTypes.string,
    ioTheme: PropTypes.string,
    dateFormat: PropTypes.string,
    width: PropTypes.string,
  }),
};

export default DatePickerFilter;
