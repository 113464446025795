import React, { useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { DraftEditor, FormErrorContainers } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { IDraftEditorCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const DraftEditorCore: React.FC<IDraftEditorCore> = (
  props: IDraftEditorCore
) => {
  const currentLocation = useLocation();

  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    props.configProp.logicalColumn.dbCode,
    props.parentFormId,
    props.referralId
  );

  let errorData: any = useErrorData(props.location, props.referralId);

  let parsedError = processErrorData(errorData, props.intl.locale);

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleChange = useCallback((updatedValue: string) => {
    dispatch(
      changeFormData(
        props.formId,
        props.occuranceNumber,
        props.configProp.logicalColumn.dbCode,
        updatedValue,
        props.parentFormId,
        props.mode,
        props.nodeId,
        props.configProp.configObjectId,
        props.configProp.refreshFormOnChange,
        props.configProp.resetOnRefresh,
        props.configProp.placeHolder,
        props.referralId,
        currentLocation
      )
    );
  }, []);

  const handleBlur = useCallback((updatedValue: string) => {
    let modifiedValue: any = updatedValue || null;
    if (modifiedValue !== initialvalue) {
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          props.configProp.logicalColumn.dbCode,
          modifiedValue,
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
    }
  }, []);
  try {
    return (
      <DraftEditor
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={initialvalue}
        displayType={themeContext.componentType}
        editorType={props.configProp.editorType}
        toolbarHidden={props.configProp.toolbarHidden}
        onEditorStateChange={(editorStateParam: any) =>
          handleChange(editorStateParam)
        }
        handleBlur={(editorStateParam: any) => {
          handleBlur(editorStateParam);
        }}
        readOnly={!props.privilegesMap.isEditable}
        errorMessage={parsedError}
        hasError={Boolean(errorData)}
        mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        configId={props.configProp.configObjectId}
        occuranceNumber={props.occuranceNumber}
      />
    );
  } catch {
    const error = ['Error occurred in DraftEditor'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(DraftEditorCore));
