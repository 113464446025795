import React, { useContext, useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TextBox, FormErrorContainers } from 'info-ui-library';

import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { ThemeContext } from '../theme/ThemeContext';
import { IPasswordCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const PasswordCore: React.FC<IPasswordCore> = (props: IPasswordCore) => {
  const currentLocation = useLocation();

  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  const [value, setValue] = useState(initialvalue);

  useEffect(() => {
    if (value !== initialvalue) {
      setValue(initialvalue);
    }
  });

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const handleChange = useCallback((updatedValue: string) => {
    setValue(updatedValue);
    dispatch(
      changeFormData(
        props.formId,
        props.occuranceNumber,
        dbCode,
        updatedValue,
        props.parentFormId,
        props.mode,
        props.nodeId,
        props.configProp.configObjectId,
        props.configProp.refreshFormOnChange,
        props.configProp.resetOnRefresh,
        props.configProp.placeHolder,
        props.referralId,
        currentLocation
      )
    );
  }, []);

  const handleBlur = useCallback((updatedValue: string) => {
    setValue(updatedValue);
    dispatch(
      changeFormData(
        props.formId,
        props.occuranceNumber,
        dbCode,
        updatedValue,
        props.parentFormId,
        props.mode,
        props.nodeId,
        props.configProp.configObjectId,
        props.configProp.refreshFormOnChange,
        props.configProp.resetOnRefresh,
        props.configProp.placeHolder,
        props.referralId,
        currentLocation
      )
    );
  }, []);
  try {
    return (
      <TextBox
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={value}
        displayType={themeContext.componentType}
        handleChange={(updatedValue: any) => handleChange(updatedValue)}
        handleBlur={(updatedValue: any) => handleBlur(updatedValue)}
        disabled={!props.privilegesMap.isEditable}
        errorMessage={parsedError}
        hasError={Boolean(errorData)}
        mandatory={props.configProp.isMandatory}
        id={props.configProp.configObjectId}
        type="Password"
      />
    );
  } catch {
    const error = ['Error occurred in password'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(PasswordCore));
