import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers/connectedReducer';

const sagaMiddleware = createSagaMiddleware();

export interface IState {
  addModals: any[];
  cardsData: any;
  treeDatas: any;
  leftMenuIndicator: string;
  droppableIndicator: boolean;
  rootNodeId: string;
  options: {};
  errorData: {};
  toggleMessages: string[];
  locale: string;
  messages: any;
  userDetails: string;
  currentFinancialYear: string;
  portalDetails: any;
  gridIndexes: any;
  portalLayout: any;
  applications: any;
  currentProject: any;
  openModals: any;
  warningMessages: any;
  currentLogoURL: string;
  navigation: any;
}

const middleware = [sagaMiddleware];

export function makeStore(preLoadedState: any) {
  const store: any = createStore(
    createRootReducer(),
    preLoadedState,
    composeWithDevTools(applyMiddleware(...middleware))  // Added Redux DevTools here
  );
  sagaMiddleware.run(rootSaga);
  return store;
}