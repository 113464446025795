import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { IState } from '../store/index';
import { getGridData, setCurrentRootNode } from '../actions';
// import { v4 as uuid } from 'uuid';

export const useFetchAppGridData = (
  gridId: string,
  data: any,
  pathname: string,
  nodeId: string,
  ceId: string,
  referralId: string,
  refreshCardData: boolean,
  resetOtherGrids: boolean,
  parentId: string,
  portalId: string,
  reloadData: any,
  tabRefresh?: boolean
) => {
  const dispatch = useDispatch();
  const cardsData = useSelector((state: any) => state.appState.cardsData);
  const userDetails = useSelector((state: any) => state.appState.userDetails);
  let card = cardsData[referralId];

  let gridData = card ? card.data : [];
  let loading: boolean = true;
  let appData: any = undefined;
  const [result, setResult] = useState({
    loadingApp: loading,
    appData: appData,
  });
  const financialYear = useSelector(
    (state: any) => state.appState.currentFinancialYear
  );

  useEffect(() => {
    if (reloadData && reloadData.STATUS !== 'RT') {
      dispatch(
        getGridData(
          gridId,
          data || {},
          // data ? data : {},
          referralId,
          resetOtherGrids,
          parentId,
          portalId,
          tabRefresh,
          null,
          card ? card?.paginatedDetails?.page : null,
          card ? card.paginatedDetails?.limit : null
        )
      );
      if (pathname && pathname.includes('/app/ce/grid') && nodeId) {
        dispatch(setCurrentRootNode(nodeId));
      }
      setResult({ loadingApp: true, appData: {} });
    }
  }, [gridId, ceId, financialYear, data, userDetails]);

  useEffect(() => {
    if (
      result.appData &&
      gridData &&
      gridData.length !== result.appData.length
    ) {
      setResult({ loadingApp: false, appData: gridData });
    }
  }, [gridData, refreshCardData]);

  return result;
};
