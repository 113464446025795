import {
  restHttp,
  restHttpBlob,
  restHttpPagination,
  voidRestHttp,
} from './apiUtils';
import { SignJWT } from 'jose';
import { createStorage } from '../storage';
import {
  USER_SESSION_LOCAL_STORAGE_KEY,
  SECRET_KEY,
} from '../constants/appeng.enum';
import cloneDeep from 'lodash/cloneDeep';
import ReportOptions from '../models/report.options.model';
import { PublicClientApplication } from '@azure/msal-browser';
import { v4 as uuid } from 'uuid';
// const { PublicClientApplication } = await import('@azure/msal-browser');
let inboundService: any;
let dataPreProcessor: any;
let outBoundService: any;
let excelReport: any;
let metadataGenerator: any;
let outBoundQueryService: any;
if (window.env.REACT_APP_PLATFORM == 'desktop') {
  // inboundService = require('appeng-core').inboundService
  // dataPreProcessor = require('appeng-core').dataPreProcessor
  // outBoundService = require('appeng-outbound').outBoundService
  // outBoundQueryService = require('appeng-outbound').queryService
  // excelReport = require('appeng-reports').excelReport
  // metadataGenerator = require('appeng-reports').metadataGenerator
}

export const userDetailsJwtKey = async () => {
  // Creation of User details Token
  const storage = createStorage();
  const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
  const userProfileMap = JSON.parse(userProfile || '{}');
  const usersession = cloneDeep(userProfileMap);
  delete usersession.AdditionalDetails;
  delete usersession.SpecialMappingDetails;
  usersession.APP_CURRENT_DATE = new Date();
  usersession.sub = 'dummy';
  if (userProfileMap?.AdditionalDetails?.UserContext && Object.keys(userProfileMap.AdditionalDetails.UserContext).length > 0) {
      Object.keys(userProfileMap.AdditionalDetails).forEach(key => {
        if (!userProfileMap.AdditionalDetails.UserContext.hasOwnProperty(key) && key !== 'UserContext') {
          userProfileMap.AdditionalDetails.UserContext[key] = userProfileMap.AdditionalDetails[key];
        }
      });
    Object.assign(usersession, userProfileMap.AdditionalDetails.UserContext);
  } else {
    Object.assign(usersession, userProfileMap.AdditionalDetails);
  }
  const secretKeyUint8Array = new TextEncoder().encode(SECRET_KEY);
  const jwtToken: string = await new SignJWT(usersession)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(secretKeyUint8Array);
  return jwtToken;
};

const getRestConfigForAttachment = async (
  authToken: string,
  requestBody?: any
) => {
  let userDetails = await userDetailsJwtKey();
  const headers = {
    authorization: authToken,
    userDetails: userDetails,
    provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
  };

  const postFetchConfig = {
    method: 'POST',
    body: requestBody,
    headers: headers,
  };

  const getFetchConfig = {
    method: 'GET',
    headers: headers,
  };

  return requestBody ? postFetchConfig : getFetchConfig;
};
const getDeleteRestConfig = async (
  authToken: string,
  requestBody?: any,
  transactionId?: string
) => {
  const headers = {
    'Content-Type': 'application/json',
    authorization: authToken,
    userDetails: await userDetailsJwtKey(),
    aeTransactionId: transactionId,
  };

  const deleteFetchConfig = {
    method: 'DELETE',
    body: JSON.stringify(requestBody),
    headers: headers,
  };

  return deleteFetchConfig;
};
const getRestConfig = async (
  authToken: string,
  requestBody?: any,
  transactionId?: string,
  type?: string
) => {
  const userDetails = await userDetailsJwtKey();
  const headers = {
    'Content-Type': 'application/json',
    authorization: authToken,
    userDetails: userDetails,
    aeTransactionId: transactionId,
    provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
  };

  const postFetchConfig = {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: headers,
  };

  const putFetchConfig = {
    method: 'PUT',
    body: JSON.stringify(requestBody),
    headers: headers,
  };

  const deleteConfig = {
    method: 'DELETE',
    body: JSON.stringify(requestBody),
    headers: headers,
  };

  const getFetchConfig = {
    method: 'GET',
    headers: headers,
  };

  switch (type) {
    case 'PUT':
      return putFetchConfig;
    case 'DELETE':
      return deleteConfig;
    default:
      return requestBody ? postFetchConfig : getFetchConfig;
  }
  //return type && type === "PUT" ? putFetchConfig : requestBody ? postFetchConfig : getFetchConfig;
};

const apiServer = {
  async getConfigItem(itemId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + 'meta/getConfigItem/' + itemId,
      config
    );
    return response;
  },

  async getDataViaQuery(datasource: string, query: string, authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      'getDataViaQuery/' +
      datasource +
      '/' +
      query,
      config
    );
    return response;
  },

  async getMappingDataFromMaster(
    datasource: string,
    tabeName: string,
    columnKeyName: string,
    columnValueName: string,
    filterValue: string,
    condition: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      'getMasterData/' +
      datasource +
      '/' +
      tabeName +
      '/' +
      columnKeyName +
      '/' +
      columnValueName +
      '/' +
      filterValue +
      '/' +
      condition,
      config
    );
    return formatResponse(response);
  },

  async getUserApplicationSpecialAttributes(
    userId: string,
    applicationId: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL +
      '/userApplicationAttributes/' +
      userId +
      '/' +
      applicationId,
      config
    );
  },

  async applicationRoleMicrosoftAd() {
    const microsoftADConfig =
      window.env.REACT_APP_AUTH_DETAILS!.provider['microsoft-ad'];
    const pca = new PublicClientApplication(microsoftADConfig);
    const accounts = pca.getAllAccounts();
    const account = accounts[0];
    let scopes = ['openid', 'profile', 'User.Read'];
    const resp = await pca.acquireTokenSilent({
      scopes: scopes,
      account,
      forceRefresh: false,
    });
    const configuration = await getRestConfig('Bearer ' + resp.accessToken);
    const response = await restHttp(
      'https://graph.microsoft.com/v1.0/me/appRoleAssignments',
      configuration
    );
    return response;
  },

  async downloadReport(reportOptions: ReportOptions, authToken: string) {
    reportOptions.jwt = await userDetailsJwtKey();
    reportOptions.params.JWT = authToken;
    const config = await getRestConfig(authToken, reportOptions);
    const excelData = await restHttp(
      window.env.REACT_APP_APPENG_REPORTS_URL
        ? window.env.REACT_APP_APPENG_REPORTS_URL
        : '',
      config
    );
    return excelData.data;
  },

  async getGridData(
    gridId: string,
    data: any,
    authToken: string,
    isServerPaginationEnable: boolean,
    page?: number,
    limit?: number
  ) {
    const config = await getRestConfig(authToken, data);
    if (isServerPaginationEnable) {
      return await restHttpPagination(
        window.env.REACT_APP_APPENG_OUTBOUND_URL + 'gridData/' + gridId,
        config,
        page,
        limit
      );
    } else {
      return await restHttp(
        window.env.REACT_APP_APPENG_OUTBOUND_URL + 'gridData/' + gridId,
        config
      );
    }
  },

  async upsertEntityRecord(
    requestBody: any,
    authToken: string,
    transactionId: string
  ) {
    const config = await getRestConfig(authToken, requestBody, transactionId);
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'submit',
      config
    );
  },
  async insertForm(
    requestBody: any,
    authToken: string,
    transactionId: string,
    logicalEntityIdOrName: string
  ) {
    const config = await getRestConfig(authToken, requestBody, transactionId);
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + logicalEntityIdOrName,
      config
    );
  },
  async updateForm(
    requestBody: any,
    authToken: string,
    transactionId: string,
    logicalEntityIdOrName: string,
    primaryKey: string
  ) {
    const config = await getRestConfig(
      authToken,
      requestBody,
      transactionId,
      'PUT'
    );

    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL +
      logicalEntityIdOrName +
      '/' +
      primaryKey,
      config
    );
  },
  async saveAttachmentDetails(attachmentData: any, authToken: string) {
    const config = await getRestConfig(authToken, attachmentData);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/movefilesfromTemptoPermanent',
      config
    );
  },
  async changeAttachmentType(data: string, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/changeAttachmentType',
      config
    );
  },
  async reOrderAttachments(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/re-order',
      config
    );
  },
  async deleteAttachment(
    id: string,
    user: string,
    parentId: string,
    attachmentData: any,
    authToken: string
  ) {
    const config = await getRestConfig(authToken, attachmentData);
    return await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/delete/' +
      id +
      '/' +
      parentId +
      '/' +
      user,
      config
    );
  },
  async removeAttachment(attachmentData: any, authToken: string) {
    const config = await getRestConfig(authToken, attachmentData);
    return await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/tempdelete',
      config
    );
  },
  async getFormData(
    gridId: string,
    primaryKey: string,
    authToken: string,
    referenceData: any
  ) {
    const config = await getRestConfig(authToken, referenceData);
    return await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      'formData/' +
      gridId +
      '/' +
      primaryKey,
      config
    );
  },
  async getEntityData(entityId: string, primaryKey: string, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + entityId + '/' + primaryKey,
      config
    );
  },
  async getFormDataUsingLEId(
    logicalEntityId: string,
    primaryKey: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      logicalEntityId +
      '/' +
      primaryKey,
      config
    );
  },
  async getChartData(chartId: string, data: any, authToken: string) {
    let requestBody = { chartId: chartId, ...data };
    const config = await getRestConfig(authToken, requestBody);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + 'query',
      config
    );
    return response.data;
  },
  async getCombinedChildFormData(
    entityName: string,
    data: any,
    authToken: string
  ) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + entityName + '/multiSelect',
      config
    );
    if (response?.errors && response?.code === 512) {
      return response;
    } else {
      return formatMultiSelectData(response);
    }
  },
  async getOptionsData(formFieldId: string, data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + formFieldId + '/option',
      config
    );
    if (response.errors) {
      return response;
    } else {
      return formatResponse(response);
    }
  },

  async getAllOptionsData(
    data: any,
    parentId: any,
    occuranceNumber: any,
    authToken: string
  ) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + 'fetchBulkSelectOptions',
      config
    );
    if (response.errors) {
      return response;
    } else {
      return formatResponseDataMap(response, parentId, occuranceNumber);
    }
  },

  async executePreprocessors(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'datapreprocess',
      config
    );
    return response;
  },
  async executePreprocessorsOnLoad(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'dataPreprocessonLoad',
      config
    );
    return response;
  },
  async updateInlineRecord(
    primaryKey: string,
    logicalEntityId: string,
    requestBody: any,
    authToken: string,
    transactionId: string
  ) {
    const config = await getRestConfig(
      authToken,
      requestBody,
      transactionId,
      'PUT'
    );
    let primaryKy = primaryKey ?? uuid();
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + logicalEntityId + '/' + primaryKy,
      config
    );
  },
  async deleteRecord(
    primaryKey: string,
    logicalEntityIdOrName: string,
    authToken: string,
    transactionId: string,
    transactionName: string
  ) {
    const config = await getRestConfig(
      authToken,
      transactionName,
      transactionId,
      'DELETE'
    );
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL +
      logicalEntityIdOrName +
      '/' +
      primaryKey,
      config
    );
  },
  async uploadAttachment(formData: FormData, authToken: string) {
    const config = await getRestConfigForAttachment(authToken, formData);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files',
      config
    );
    return response;
  },
  async uploadAttachmentToPermanent(formData: any, authToken: string) {
    const config = await getRestConfigForAttachment(authToken, formData);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/uploadToPermanent',
      config
    );
    return response;
  },
  async fetchAttachment(
    parentId: string,
    entityName: string,
    authToken: string
  ) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/fetch/' +
      entityName +
      '/' +
      parentId,
      config
    );
    return response;
  },
  async downloadAttachment(id: string, name: string, authToken: string) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttpBlob(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/' + id + '/' + name,
      config
    );
    return response;
  },
  async downloadAllAttachments(fileData: any, authToken: string) {
    const config = await getRestConfig(authToken, {
      fileData: JSON.stringify(fileData),
    });
    const response = await restHttpBlob(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/all-files',
      config
    );
    return response;
  },
  async getMyProcessInstanceData(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_BPMN_URL +
      '/history/process-instance?unfinished=true',
      config
    );
  },
  async getMyTaskData(authToken: string, filter: any) {
    const config = await getRestConfig(authToken);
    let filterString = '';
    let mytaskUri = '/mytask';
    Object.entries(filter).forEach(([key, value]) => {
      filterString = filterString + key + '=' + value + '&';
    });
    if (filterString) {
      mytaskUri =
        mytaskUri + '?' + filterString.substring(0, filterString.length - 1);
    }
    return await restHttp(window.env.REACT_APP_BPMN_URL + mytaskUri, config);
  },
  async getPossibleWorkflowActions(authToken: string, taskId: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_BPMN_URL + '/form/' + taskId,
      config
    );
  },
  async getSendFeedbackData(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_SEND_FEEDBACK_URL + 'feedbacks',
      config
    );
  },
  async executeActionFlow(flowId: string, data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'actionflow/' + flowId,
      config
    );
  },
  async getProjectOption(userId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL +
      '/users/' +
      userId +
      '/applications',
      config
    );
  },
  async getProjects(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/applications',
      config
    );
  },
  async getProjectByProjectId(id: string | number, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/applications/' + id,
      config
    );
  },
  async getDefaultProjectAndRoleFromUserId(
    userId: string,
    projectId: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    if (projectId) {
      return await restHttp(
        window.env.REACT_APP_APPENG_AUTH_URL +
        '/defaultAppRole/' +
        userId +
        '/' +
        projectId,
        config
      );
    }
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/defaultAppRole/' + userId,
      config
    );
  },

  async getOktaUserInfo(authToken: string) {
    const configuration = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_AUTH_DETAILS!.provider.okta.userInfoUri,
      configuration
    );
  },

  async getRoleOption(userId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/users/' + userId + '/roles',
      config
    );
  },

  async getAllRoleOption(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/roles',
      config
    );
  },

  async getConfiguarationStructure(
    authToken: string,
    applicationId: any,
    rootItemId: any
  ) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_META_RELOAD_URL +
      'getConfgurationTree/' +
      applicationId +
      '/' +
      rootItemId,
      config
    );
  },

  async deployMeta(authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_META_RELOAD_URL + 'reload',
      config
    );
    return response;
  },

  async fetchApplicationLogo(
    parentId: string,
    entityName: string,
    datasource: string,
    authToken: string
  ) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/fetchLogo/' +
      entityName +
      '/' +
      parentId +
      '/' +
      datasource,
      config
    );
    return response;
  },
};

const apiDesktop = {
  async getConfigItem(itemId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + 'meta/getConfigItem/' + itemId,
      config
    );
    return response;
  },

  async getDataViaQuery(datasource: string, query: string, authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      'getDataViaQuery/' +
      datasource +
      '/' +
      query,
      config
    );
    return response;
  },

  async getMappingDataFromMaster(
    datasource: string,
    tabeName: string,
    columnKeyName: string,
    columnValueName: string,
    filterValue: string,
    condition: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL +
      'getMasterData/' +
      datasource +
      '/' +
      tabeName +
      '/' +
      columnKeyName +
      '/' +
      columnValueName +
      '/' +
      filterValue +
      '/' +
      condition,
      config
    );
    return formatResponse(response);
  },

  async getUserApplicationSpecialAttributes(
    userId: string,
    applicationId: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL +
      '/userApplicationAttributes/' +
      userId +
      '/' +
      applicationId,
      config
    );
  },

  async applicationRoleMicrosoftAd() {
    const microsoftADConfig =
      window.env.REACT_APP_AUTH_DETAILS!.provider['microsoft-ad'];
    const pca = new PublicClientApplication(microsoftADConfig);
    const accounts = pca.getAllAccounts();
    const account = accounts[0];
    let scopes = ['openid', 'profile', 'User.Read'];
    const resp = await pca.acquireTokenSilent({
      scopes: scopes,
      account,
      forceRefresh: false,
    });
    const configuration = await getRestConfig('Bearer ' + resp.accessToken);
    const response = await restHttp(
      'https://graph.microsoft.com/v1.0/me/appRoleAssignments',
      configuration
    );
    return response;
  },

  async downloadReport(reportOptions: ReportOptions, authToken: string) {
    reportOptions.jwt = await userDetailsJwtKey();
    reportOptions.params.JWT = authToken;
    const excelReportObj: typeof excelReport =
      await metadataGenerator.createReportMetadata(reportOptions);
    const excelData = await metadataGenerator.buildReport(
      excelReportObj,
      reportOptions
    );
    return excelData;
  },
  async getGridData(gridId: string, data: any, authToken: string) {
    const response = await outBoundService.fetchGridDataByGridId(
      gridId,
      data,
      authToken,
      userDetailsJwtKey(),
      window.env.REACT_APP_AUTH_DETAILS!.provider.type
    );
    return formatResponse(response);
  },
  async upsertEntityRecord(
    requestBody: any,
    authToken: string,
    transactionId: string
  ) {
    const requestData = JSON.stringify(requestBody);
    if (inboundService) {
      return await inboundService.process(
        authToken,
        await userDetailsJwtKey(),
        JSON.parse(requestData)
      );
    } else {
      return null;
    }
  },
  async insertForm(
    requestBody: any,
    authToken: string,
    transactionId: string,
    logicalEntityIdOrName: string
  ) {
    const requestData = JSON.stringify(requestBody);
    if (inboundService) {
      return await inboundService.process(
        authToken,
        await userDetailsJwtKey(),
        JSON.parse(requestData)
      );
    } else {
      return null;
    }
  },
  async updateForm(
    requestBody: any,
    authToken: string,
    transactionId: string,
    logicalEntityIdOrName: string,
    primaryKey: string
  ) {
    const requestData = JSON.stringify(requestBody);
    if (inboundService) {
      return await inboundService.process(
        authToken,
        await userDetailsJwtKey(),
        JSON.parse(requestData)
      );
    } else {
      return null;
    }
  },
  async deleteAttachment(
    id: string,
    user: string,
    parentId: string,
    attachmentData: any,
    authToken: string
  ) {
    const config = await getRestConfig(authToken, attachmentData);
    return await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/delete/' +
      id +
      '/' +
      parentId +
      '/' +
      user,
      config
    );
  },

  async removeAttachment(attachmentData: any, authToken: string) {
    const config = await getRestConfig(authToken, attachmentData);
    return await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/tempdelete',
      config
    );
  },
  async saveAttachmentDetails(attachmentData: any, authToken: string) {
    const config = await getRestConfig(authToken, attachmentData);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/s3/copy',
      config
    );
  },
  async changeAttachmentType(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/changeAttachmentType',
      config
    );
  },
  async reOrderAttachments(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await voidRestHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/re-order',
      config
    );
  },
  async getFormData(
    gridId: string,
    primaryKey: string,
    authToken: string,
    referenceData: any
  ) {
    return await outBoundService.fetchFormDataByGridId(
      gridId,
      primaryKey,
      authToken,
      await userDetailsJwtKey(),
      window.env.REACT_APP_AUTH_DETAILS!.provider.type
    );
  },
  async getEntityData(entityId: string, primaryKey: string, authToken: string) {
    return await outBoundService.singleSelect(
      entityId,
      primaryKey,
      authToken,
      await userDetailsJwtKey(),
      'infoauth'
    );
  },
  async getFormDataUsingLEId(
    logicalEntityId: string,
    primaryKey: string,
    authToken: string
  ) {
    return await outBoundService.singleSelect(
      logicalEntityId,
      primaryKey,
      authToken,
      await userDetailsJwtKey(),
      'infoauth'
    );
  },
  async getChartData(chartId: string, authToken: string) {
    return await outBoundQueryService.getAndProcessChartConfigData(
      chartId,
      authToken,
      await userDetailsJwtKey(),
      window.env.REACT_APP_AUTH_DETAILS!.provider.type
    );
  },
  async getCombinedChildFormData(
    entityName: string,
    data: any,
    authToken: string
  ) {
    const response = await outBoundService.executeMultiSelectQuery(
      entityName,
      data,
      authToken,
      await userDetailsJwtKey(),
      window.env.REACT_APP_AUTH_DETAILS!.provider.type
    );
    if (response?.errors && response?.code === 512) {
      return response;
    } else {
      return formatMultiSelectData(response);
    }
  },
  async getOptionsData(formFieldId: string, data: any, authToken: string) {
    const response = await outBoundService.fetchSelectOptionData(
      formFieldId,
      data,
      authToken,
      await userDetailsJwtKey(),
      window.env.REACT_APP_AUTH_DETAILS!.provider.type
    );
    if (response.errors) {
      return response;
    } else {
      return formatResponse(response);
    }
  },

  async getAllOptionsData(
    data: any,
    parentId: any,
    occuranceNumber: any,
    authToken: string
  ) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_OUTBOUND_URL + 'fetchBulkSelectOptions',
      config
    );
    if (response.errors) {
      return response;
    } else {
      return formatResponseDataMap(response, parentId, occuranceNumber);
    }
  },

  async executePreprocessors(data: any, authToken: string) {
    const requestData = JSON.stringify(data);
    const response = await dataPreProcessor.process(
      authToken,
      await userDetailsJwtKey(),
      JSON.parse(requestData)
    );
    return response;
  },
  async executePreprocessorsOnLoad(data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'dataPreprocessonLoad',
      config
    );
    return response;
  },
  async updateInlineRecord(
    primaryKey: string,
    logicalEntityId: string,
    requestBody: any,
    authToken: string,
    transactionId: string
  ) {
    const requestData = JSON.stringify(requestBody);
    if (inboundService) {
      return await inboundService.upsertData(
        authToken,
        await userDetailsJwtKey(),
        JSON.parse(requestData),
        transactionId,
        logicalEntityId,
        primaryKey
      );
    } else {
      return null;
    }
  },
  async uploadAttachment(data: FormData, authToken: string) {
    // const requestData = JSON.stringify(data);
    let response;
    return response;
  },
  async uploadAttachmentToPermanent(formData: FormData, authToken: string) {
    const config = await getRestConfigForAttachment(authToken, formData);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/uploadToPermanent',
      config
    );
    return response;
  },
  async fetchAttachment(
    parentId: string,
    entityName: string,
    authToken: string
  ) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/fetch/' +
      entityName +
      '/' +
      parentId,
      config
    );
    return response;
  },
  async downloadAttachment(id: string, name: string, authToken: string) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/' + id + '/' + name,
      config
    );
    return await response;
  },
  async downloadAllAttachments(fileData: any, authToken: string) {
    const config = await getRestConfig(authToken, {
      fileData: JSON.stringify(fileData),
    });
    const response = await restHttpBlob(
      window.env.REACT_APP_APPENG_ATTACHMENT + 'files/all-files',
      config
    );
    return response;
  },
  async getMyProcessInstanceData(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_BPMN_URL +
      '/history/process-instance?unfinished=true',
      config
    );
  },
  async getMyTaskData(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_BPMN_URL + '/task?assignee=user1',
      config
    );
  },
  async getPossibleWorkflowActions(authToken: string, taskId: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_BPMN_URL + '/form/' + taskId,
      config
    );
  },
  async getSendFeedbackData(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_SEND_FEEDBACK_URL + 'feedbacks',
      config
    );
  },
  async executeActionFlow(flowId: string, data: any, authToken: string) {
    const config = await getRestConfig(authToken, data);
    return await restHttp(
      window.env.REACT_APP_APPENG_CORE_URL + 'actionflow/' + flowId,
      config
    );
  },
  async getProjectOption(userId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL +
      '/users/' +
      userId +
      '/applications',
      config
    );
  },
  async getProjects(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/applications',
      config
    );
  },
  async getProjectByProjectId(id: string | number, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/applications/' + id,
      config
    );
  },
  async getDefaultProjectAndRoleFromUserId(
    userId: string,
    projectId: string,
    authToken: string
  ) {
    const config = await getRestConfig(authToken);
    if (projectId) {
      return await restHttp(
        window.env.REACT_APP_APPENG_AUTH_URL +
        '/defaultAppRole/' +
        userId +
        '/' +
        projectId,
        config
      );
    }
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/defaultAppRole/' + userId,
      config
    );
  },
  async getOktaUserInfo(authToken: string) {
    const configuration = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_AUTH_DETAILS!.provider.okta.userInfoUri,
      configuration
    );
  },

  async getRoleOption(userId: string, authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/users/' + userId + '/roles',
      config
    );
  },

  async getAllRoleOption(authToken: string) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_AUTH_URL + '/roles',
      config
    );
  },

  async deleteRecord(
    primaryKey: string,
    logicalEntityIdOrName: string,
    authToken: string,
    transactionId: string,
    transactionName: string
  ) {
    if (inboundService) {
      return await inboundService.deleteData(
        authToken,
        await userDetailsJwtKey(),
        transactionId,
        logicalEntityIdOrName,
        primaryKey
      );
    } else {
      return null;
    }
  },
  async getConfiguarationStructure(
    authToken: string,
    applicationId: any,
    rootItemId: any
  ) {
    const config = await getRestConfig(authToken);
    return await restHttp(
      window.env.REACT_APP_APPENG_META_RELOAD_URL +
      'getConfgurationTree/' +
      applicationId +
      '/' +
      rootItemId,
      config
    );
  },

  async deployMeta(authToken: string) {
    const config = await getRestConfig(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_META_RELOAD_URL + 'reload',
      config
    );
    return response;
  },

  async fetchApplicationLogo(
    parentId: string,
    entityName: string,
    datasource: string,
    authToken: string
  ) {
    const config = await getRestConfigForAttachment(authToken);
    const response = await restHttp(
      window.env.REACT_APP_APPENG_ATTACHMENT +
      'files/fetchLogo/' +
      entityName +
      '/' +
      parentId +
      '/' +
      datasource,
      config
    );
    return response;
  },
};

const isServerEnv = window.env.REACT_APP_REACT_APP_PLATFORM !== 'desktop';
const api = isServerEnv ? apiServer : apiDesktop;
console.log('--------------------', api);

const formatMultiSelectData = (response: any) => {
  if (response?.data?.columns && response?.data?.rows) {
    const columns: string[] = response.data.columns;
    const records: any = {};

    response.data.rows.map((row: any) => {
      const record: any = {};
      for (const [i, value] of row.entries()) {
        record[columns[i]] = value;
      }
      records[response.data.rows.indexOf(row)] = record;
      return null;
    });
    return records;
  }
  return null;
};

const formatResponseDataMap = (
  response: any,
  parentId: any,
  occuranceNumber: any
) => {
  let optionDataMap: any = {};
  Object.keys(response).forEach((key) => {
    let formatedData: any = formatResponse(response[key]);
    optionDataMap[parentId + '_' + occuranceNumber + '_' + key] = formatedData;
  });
  return optionDataMap;
};

const formatResponse = (response: any) => {
  if (response?.data?.rows && response?.data?.columns) {
    const columns: string[] = response.data.columns;
    const records: any[] = [];

    response.data.rows.map((row: any) => {
      const record: any = {};
      for (const [i, value] of row.entries()) {
        record[columns[i]] = value;
      }
      records.push(record);
      return null;
    });
    return records;
  }
  return null;
};
export default api;
