import React from 'react';
// import React, { useEffect } from 'react';
import { TextBox } from 'info-ui-library';
import PropTypes from 'prop-types';

function DefaultColumnFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    styleName,
    ioMode,
    ioTheme,
    id,
  },
}) {
  // const count = preFilteredRows ? preFilteredRows.length : 0;
  const onChange = (e) => {
    setFilter(e || undefined);
  };

  const handleBlur = (e) => {
    setFilter(e || undefined);
  };
  return (
    <TextBox
      value={filterValue || ''}
      handleChange={(e) => onChange(e)}
      handleBlur={(e) => handleBlur(e)}
      styleName={styleName}
      ioTheme={ioTheme}
      ioMode={ioMode}
      label={''}
      displayType={'SingleLine'}
      placeHolder={'Search Records'}
      isInlineGridComponent={true}
      isFilterComponent={true}
      height="40px"
      configId={'grid_filter_' + id}
    />
  );
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.any,
  'column.filterValue': PropTypes.any,
  'column.preFilteredRows': PropTypes.any,
  'column.setFilter': PropTypes.any,
  'column.styleName': PropTypes.any,
  'column.ioMode': PropTypes.any,
  'column.ioTheme': PropTypes.any,
  'column.id': PropTypes.any,
  'column.preFilteredRows.length': PropTypes.any
};

export default DefaultColumnFilter;
