import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import * as React from 'react';
// import { useState } from 'react';
import LanguageLink from './LanguageLink';
import { injectIntl } from 'react-intl';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';

const ContainerDiv = styled(Container)`
  height: 50px;
  width: 100%;
  background: #004269;
  display: flex;
  margin-bottom: 2px;
  max-width: 100%;
`;

const InnerContainer = styled.div`
  height: 50px;
  width: 170%;
  background: transparent;
  display: flex;
  font-size: '20px' !important;
  margin: '10px' !important;
  float: left;
`;
const LinksContainer = styled.div`
  height: 50px;
  width: auto;
  background: #004269;
  display: flex;
  float: right;
  margin-bottom: 20px;

  .login_icon {
    padding-left: 10px;
    cursor: pointer;
    color: rgba(232, 232, 232, 0.7);

    &:hover {
      color: white;
    }
  }
`;
const Title = styled.a`
  color: white !important;
  margin: 0;
  padding: 12px;
  text-align: left;
  font-size: 20px;
  display: inline-block;
`;
const StyledMenuItem = styled.a`
  color: white !important;
  margin: 0;
  padding-top: 12px;
  padding-right: 15px;
  text-align: right;
  font-size: 16px;
  display: inline-block;

  .login_icon {
    cursor: pointer;
    color: rgba(232, 232, 232, 0.7);
    display: inline-block;
    vertical-align: middle;

    &:hover {
      color: white;
    }
  }
`;
const StyledLanguageLink = styled(LanguageLink)`
  color: black !important;
  margin: 2px;
  padding-top: 12px;
  padding-right: 15px;
  text-align: right;
  font-size: 16px;
  display: inline-block;
  background: white;
`;

interface IHeader {
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
  setLanguage: any;
  isLanguageRequire?: boolean;
}

const Header: React.FC<IHeader> = (props: IHeader) => {
  const navigate = useNavigate();
  // const [isLanguageRequire, setisLanguageRequire] = useState(true);
  const handleClick = async (type: string) => {
    navigate('/' + type);
  };

  return (
    <ContainerDiv className="col-md-12">
      <InnerContainer>
        <div>
          <a
            role="presentation"
            onClick={() => handleClick('')}
            style={{
              height: '100%',
              margin: '10px',
              display: 'inline-block',
              color: '#fff',
            }}
          >
            Info QA
          </a>
        </div>
      </InnerContainer>
      {}
      {}
      <StyledMenuItem onClick={() => handleClick('login')}>
        {}
        <FaUserCircle className="login_icon" size={27} id="userProfileImg" />
      </StyledMenuItem>
      {}
      {}
    </ContainerDiv>
  );
};

export default injectIntl(Header);
