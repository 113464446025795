import React, { useContext, useEffect } from 'react';
import { Form, FormErrorContainers } from 'info-ui-library';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import { ThemeContext } from '../../theme/ThemeContext';
import FormSectionGenerator from './FormSectionGenerator';
import SkeletonCore from '../../components/SkeletonCore';
import { ISimpleForm } from '../interfaces/index';
import { getPrivilege } from '../../utils/ClientUtils';
import { NOPRIVILEGE } from '../../constants/appeng.enum';
import { UserContext } from '../../user-context/UserContext';
import { extractLabel } from '../../utils/intlutils';
import {
  getEditFormButtons,
  getFormValidationMessage,
} from '../CompositeEntityCommonUtils';
import { StyledSimpleFormDiv, StyledSimpleFormSpan } from './FormStyle';

const getDisplayComponent = (formDataObject: any, props: any, themeContext: any, userContext: any, existingErrorData: any) => {
    if (formDataObject) {
        // privilege check for button panel
        const buttonPanelPrivilege = props.buttonPanel ? getPrivilege(props.buttonPanel.privileges,
            userContext.getCurrentRole()) : NOPRIVILEGE;
        let errorData: string[] = [];          // list for having the errordata from the stire if present
        let editFormButtons;
        if (!props.modalRequired && buttonPanelPrivilege !== NOPRIVILEGE) {
            editFormButtons = getEditFormButtons(props.nodeId, props.mode,
                props.displayName, props.routerParameter.nodeType, props.keys,
                props.routerParameter.referenceData,
                props.ceId, props.buttonPanel.buttons,
                buttonPanelPrivilege, props.parentForm.configObjectId,
                themeContext, props.nodeEditable, props.gridId,
                props.referralId, props.routerParameter, props.resetForm, props.portalForm, props.entityName,
                props.compositeEntityKey, props.setCardButtons,
                props.portalId, props.parentId,
                props.usePortalReferenceData,
                props.handleClick, props.buttonPanel.menuButtons);
        }
        // push all the error data for parent
        // if (Object.entries(existingErrorData?.errorData?.formValidation).length > 0) {
          if (existingErrorData?.errorData?.formValidation && Object.entries(existingErrorData.errorData.formValidation).length > 0) {
            const error = getFormValidationMessage(props.entityId + '=>' + 0, existingErrorData, props.intl.locale)
            if (error) {
                errorData.push(error)
            }
        }
        let error;
        if (errorData.length > 0) {
            error = (
                <FormErrorContainers ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    styleName ={'danger'}
                    lists={errorData} />
            )
        }

        const displayComponent = (<React.Fragment>
            <div >
                {!props.setCardLabel &&
                    <StyledSimpleFormSpan $styleName={themeContext.styleName}

                        $ioTheme={themeContext.ioTheme}
                        $ioMode={themeContext.ioMode}>
                        {extractLabel(props.nodeName, props.intl.locale)}</StyledSimpleFormSpan>}
                {!props.setCardButtons && editFormButtons}
            </div>

            <StyledSimpleFormDiv $styleName={themeContext.styleName}

                $ioTheme={themeContext.ioTheme}
                $ioMode={themeContext.ioMode}>

                {error}
                <Form id={props.parentForm.configObjectId}
                    styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}>
                    <FormSectionGenerator
                        mode={props.mode}
                        parentFormId={undefined}
                        formId={props.parentForm.configObjectId}
                        formSections={props.parentForm.formSections}
                        occuranceNumber={0}
                        nodeId={props.nodeId}
                        formPrivilege={props.formPrivilege}
                        isFormEditable={props.isFormEditable}
                        parentEntityId={props.entityId}
                        referralId={props.referralId} />
                </Form>
            </StyledSimpleFormDiv>
        </React.Fragment>
        )
        return { displayComponent, editFormButtons }
    } else {
        const displayComponent = <SkeletonCore count={1} />
        const editFormButtons = <SkeletonCore count={1} />
        return { displayComponent, editFormButtons };
    }

}

const SimpleFormGenerator: React.FC<ISimpleForm> = (props) => {
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  const existingCardsData = useSelector(
    (state: any) => state.appState.cardsData
  );
  const formDataObject = existingCardsData[props.referralId];

  const existingErrorData = formDataObject
    ? formDataObject.errorData
    : undefined;

  const { displayComponent, editFormButtons } = getDisplayComponent(
    formDataObject,
    props,
    themeContext,
    userContext,
    existingErrorData
  );

  useEffect(() => {
    if (props.setCardButtons) {
      props.setCardButtons(editFormButtons);
    }
    if (props.setCardLabel) {
      props.setCardLabel(props.nodeName);
    }
  }, [props.referralId]);

    try {
        return (
            <React.Fragment>
                {displayComponent}
            </React.Fragment>
        );
    } catch {
        const error = ['Error occurred in simple form generator']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.routerParameter[prevProps.referralId]) ===
    JSON.stringify(nextProps.routerParameter[nextProps.referralId])
  );
}

export default injectIntl(React.memo(SimpleFormGenerator, isPropsAreEqual));
