import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setToggleMessages,
  GO_TO_PREVIOUS_ROUTE,
  ACTION_FLOW_ROUTING,
  setStore,
  setPortalDetails,
  setChartData,
  setEditableGridStatus,
} from '../../actions';
import {
  UpsertMessage,
  ROOT_NODE_ID,
  RECORD_INDEX,
  ActionType,
  ErrorMessage,
  WORKFLOW_BUTTON_CLASS,
  UPDATE_BUTTON_CLASS,
  SAVE_BUTTON_CLASS,
  CUSTOM_BUTTON_CLASS,
  DELETE_BUTTON_CLASS,
  DOWNLOAD_REPORT_BUTTON_CLASS,
  DELETE,
  DELETE_FORM_DATA_CLASS,
} from '../../constants/appeng.enum';
import { router } from '../../routes/Routes';
import { getPrimaryDBCode } from '../../utils/ConfigUtils';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import {
  createRequestBody,
  validateCompositeEntityRecord,
  validateFormRecord,
} from '../utilitySags';
import { processingForRefresheOtherCards } from '../executer/preprocessorExecuter/executeActionFlowPreprocessor';
import { openPortalInModal } from '../modal/modalSaga';
import {
  generateConvertPDFTemplateFile,
  generateExcelDocument,
  generateTemplateFile,
  generateWordDocument,
  generateCSVDocument
} from '../documentGenerator/documentGenrator';
import { gateWayForActionFlowOperation } from '../gateWay/gateWayForActionFlowOperation';
import { deleteRecordSaga } from '../form/deleteRecordSaga';
import { gateWayForUpsertOperation } from '../gateWay/gateWayForUpsertOperationSaga';
import { downloadReport } from '../dataGridSaga/gridSaga';

const getCard = (state: any) => state.appState.cardsData;

export default function* actionFlowRoutingSaga() {
  yield all([fork(watchActionFlowRouting), fork(watchGoToPreviousRoute)]);
}

function* watchActionFlowRouting() {
  yield takeEvery(ACTION_FLOW_ROUTING, actionFlowRouting);
}

function* watchGoToPreviousRoute() {
  yield takeEvery(GO_TO_PREVIOUS_ROUTE, goToPreviousRoute);
}

function* createBodyForActionFlow(
  attributes: any,
  data: any
): Generator<any, any, any> {
  let reqBody: any = {};
  if (attributes.nodeId && attributes.nodeId !== ROOT_NODE_ID) {
    const node = cloneDeep(data.CompositeEntityNode);
    const formId = base.getFormId(node, attributes.mode);
    const requestBody = yield createRequestBody(
      attributes,
      node,
      attributes.referralId ? attributes.referralId : formId
    );
    reqBody.data = requestBody;
  } else if (attributes.referralId) {
    const existingCardData = yield select(getCard);
    let parentFormData =
      attributes.gridButtonAction && attributes.rowDetail
        ? attributes.rowDetail
        : attributes.gridButtonAction
          ? {}
          : cloneDeep(existingCardData[attributes.referralId]['data']['0']);
    parentFormData[RECORD_INDEX] = 0;
    parentFormData['transactionName'] = attributes.transactionName
      ? attributes.transactionName
      : data.Form.formLabel + '_' + attributes.actionName;
    const pkDBCode = getPrimaryDBCode(data.Form.logicalEntity.logicalColumns);
    const params: any = {
      entityName: data.Form.logicalEntity.name,
      action:
        attributes && attributes.actionName == 'Delete'
          ? 'Delete'
          : attributes.keys[pkDBCode]
            ? 'Update'
            : 'Insert',
      primaryKey: attributes.keys[pkDBCode],
    };
    reqBody.optionalAttr = params;
    reqBody.data = parentFormData;
  } else {
    reqBody.data = attributes;
  }
  return reqBody;
}

function* navigateToPortal(
  portalId: any,
  oldPortalId: any,
  attributes?: any
): Generator<any, any, any> {
  if (!portalId && attributes) {
    const updatedParam = {};
    Object.assign(
      updatedParam,
      attributes.location.state,
      attributes.routeStateParams
    );
    yield router.navigate(attributes.url, { state: updatedParam });
  } else {
    if (portalId === oldPortalId) {
      const existingCardData: any = yield select(getCard);
      let cardsData: any = cloneDeep(existingCardData);
      Object.keys(cardsData).forEach(function (key) {
        if (
          cardsData[key]['type'] === 'PortalDataGrid' ||
          cardsData[key]['type'] === 'PortalForm'
        ) {
          const rd = cardsData[key].referenceData
            ? { ...cardsData[key].referenceData }
            : {};
          Object.assign(rd, {
            AE_RELOAD: {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            },
          });
          cardsData[key]['referenceData'] = rd;
        }
      });
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    } else if (portalId !== oldPortalId) {
      const details = { portalId: portalId, data: {}, loading: true, unmarkMenu: attributes?.unmarkMenu, menuId: attributes?.menuId };
      yield put(setPortalDetails(details));
      const payload: any = {
        state: {
          portalId: '0_' + portalId,
          data: {},
          loading: true,
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'R',
          },
        },
      };
      router.navigate('/app/ce/portal/' + portalId, payload);
    }
  }
}

function* menuRouting(attributes: any): Generator<any, any, any> {
  let oldPath = attributes.location.pathname.split('/');
  const oldPortalId = oldPath[oldPath.length - 1];
  if (
    attributes.location.pathname !== '/app/ce' &&
    oldPortalId !== attributes.url
  ) {
    yield router.navigate('/app/ce/');
  }
  let configObjectClicked = attributes.url;
  let portalId = attributes.url;
  let errorMessage;
  if (oldPortalId !== attributes.url) {
    const updateStore: any = {
      cardsData: [],
      gridIndexes: {},
      toggleMessages: [],
    };
    yield put(setStore(updateStore));
  }

  switch (attributes.linkType) {
    case 'ACTION_FLOW':
      if (!attributes.actionFlowId) {
        errorMessage = ErrorMessage.ACTION_FLOW_NOT_MAPPED;
        configObjectClicked = 'Toast';
        break;
      }
      const reqBody = yield call(
        createBodyForActionFlow,
        { portalId: portalId },
        {}
      );
      const response = yield call(
        base.executeActionFlow,
        attributes.actionFlowId,
        reqBody
      );
      if (!response.errors) {
        portalId = response.navigation.portalId;
        configObjectClicked = 'Portal';
      } else {
        errorMessage = response.message;
        configObjectClicked = 'Toast';
      }
      break;

    case 'PORTAL':
      if (attributes.url.includes('/app/logout')) {
        configObjectClicked = 'Logout';
      } else {
        configObjectClicked = 'Portal';
      }
      break;

    case 'CUSTOM':
      configObjectClicked = 'Custom';
      break;
    case 'DEPLOY':
      configObjectClicked = 'Deploy';
      break;
  }

  switch (configObjectClicked) {
    case 'Toast': {
      const messages: string[] = [];
      messages.push(errorMessage);
      yield put(setToggleMessages(messages));
      break;
    }
    case 'Logout': {
      yield router.navigate(attributes.url);

      break;
    }
    case 'Portal': {
      let responseAttributes: any = {};
      responseAttributes['attributes'] = {};
      responseAttributes['attributes']['unmarkMenu'] = attributes?.unmarkMenu ? attributes?.unmarkMenu : false;
      responseAttributes['attributes']['menuId'] = attributes?.menuId ? attributes?.menuId : '';
      yield call(navigateToPortal, portalId, oldPortalId, responseAttributes?.attributes);
      break;
    }
    case 'Custom':
      if (attributes.url) {
        switch (attributes.url) {
          case '/config-app-index.html':
            window.open(attributes.url, '_self');
            break;
          default:
            window.open(attributes.url, '_blank');
        }
      } else {
      }
      break;
    case 'Deploy':
      const result = yield call(base.deployMeta);
      let messages: string[] = [];
      if (result === 'Deployed Successfully') {
        messages.push(UpsertMessage.DEPLOYSUCCESSFUL);
        yield put(setToggleMessages(messages));
      } else {
        messages.push(UpsertMessage.DEPLOYUNSUCCESSFUL);
        yield put(setToggleMessages(messages));
      }
      break;
    default:
  }
}

function* handleErrors(
  response: any,
  attributes: any
): Generator<any, any, any> {
  const messages: string[] = [];
  messages.push(UpsertMessage.VALIDATION_FAILED);
  const appError: any = {
    code: response.code,
    errorData: {
      formFieldValidation: {},
      formValidation: {},
    },
  };
  response.errors.map((err: any) => {
    const lastElement =
      err.location.split('=>')[err.location.split('=>').length - 1];
    if (isNaN(Number(lastElement))) {
      appError.errorData.formFieldValidation[err.location] = err.message;
    } else {
      appError.errorData.formValidation[err.location] = err.message;
    }
    return null;
  });
  let existingCardsData = yield select(getCard);
  const cardsData = cloneDeep(existingCardsData);
  const currentCardData = cardsData[attributes.referralId];
  currentCardData.errorData = appError;
  currentCardData.buttonClicked = '';
  const updateStore: any = {
    cardsData: cardsData,
    toggleMessages: messages,
  };
  yield put(setStore(updateStore));
}

function* buttonClickRouting(attributes: any): Generator<any, any, any> {
  let proceedAction = true;

  if (attributes.buttonId) {
    let existingCardData = yield select(getCard);
    let existingCard = existingCardData[attributes.referralId];

    if (existingCard && existingCard.isAttachTypeApplicable) {
      if (existingCardData[attributes.referralId].data.attachmentData) {
        for (const attachment of existingCardData[attributes.referralId].data
          .attachmentData) {
          if (!attachment.attachment[0].info5) {
            existingCard.attachmentTypeError = true;
            if (existingCard.buttonClicked === '') {
              existingCard.buttonClicked = false;
            } else {
              existingCard.buttonClicked = '';
            }
            proceedAction = false;
            yield put(setToggleMessages([]));
            yield put(setChartData(existingCard, attributes.referralId));
            const messages: string[] = [];
            messages.push(UpsertMessage.ATTACHMENT_TYPE_REQUIRED);
            yield put(setToggleMessages(messages));
            break;
          }
        }
      }
    }

    if (existingCard && proceedAction) {
      let cardData = new CardData(
        attributes.referralId,
        existingCard.type,
        existingCard.data,
        existingCard.portalId,
        existingCard.parentId,
        existingCard.referenceData,
        existingCard.errorData,
        existingCard.options,
        existingCard.datagridErrorData,
        existingCard.toggleTabRefresh,
        existingCard.editableGrid,
        existingCard.workflowActions,
        existingCard.tabGroupId,
        existingCard.entityName,
        existingCard.tabPortalId,
        existingCard.entityId,
        existingCard.parentCardId,
        existingCard.enableFilterInGrid,
        attributes.buttonId
      );
      yield put(setEditableGridStatus(attributes.referralId, cardData));
    }
  }

  if (attributes.actionFlowId && proceedAction) {
    let resData: any = {};
    let attributeReffrelId = attributes.referralId;
    if (attributes.nodeId && attributes.nodeId !== ROOT_NODE_ID) {
      resData = yield call(base.getConfigData, 'NodeQuery', attributes.nodeId);
    } else if (attributes.parentFormId) {
      resData = yield call(
        base.getConfigData,
        'FormQuery',
        attributes.parentFormId
      );
    }

    const reqBody = yield call(
      createBodyForActionFlow,
      attributes,
      resData.data
    );

    if (attributes.callType !== "GET") {
      //Validation Check for Action Flow
      if (attributes.nodeId && attributes.nodeId !== ROOT_NODE_ID) {
          yield call(validateCompositeEntityRecord, reqBody.data, resData.data.CompositeEntityNode, attributes.mode, attributes.referralId);
      } else if (attributes.parentFormId) {
          yield call(validateFormRecord, reqBody.data, resData.data.Form.logicalEntity, attributes.referralId, attributes.mode, resData.data.Form);
      }
  }
    const existingCardData = yield select(getCard);

    if (
      !existingCardData[attributes.referralId] ||
      Object.keys(existingCardData[attributes.referralId].errorData).length ===
      0 ||
      (existingCardData[attributes.referralId].errorData &&
        Object.keys(
          existingCardData[attributes.referralId].errorData.errorData
            .formFieldValidation
        ).length === 0)
    ) {
      const response: any = yield call(
        base.executeActionFlow,
        attributes.actionFlowId,
        reqBody
      );

      if (
        (typeof response !== 'string' && !response.errors) ||
        (response.hasOwnProperty('errors') && response.errors.length === 0)
      ) {
        yield call(processingForRefresheOtherCards, response, null, attributes);

        switch (response.navigation && response.navigation.operationType) {
          case 'Go Back':
            yield call(goToPreviousRoute, {});
            break;
          case 'Another Portal': {
            let oldPath = attributes.location.pathname.split('/');
            const oldPortalId = oldPath[oldPath.length - 1];
            let responseAttributes = { ...response };
            responseAttributes['attributes'] = {};
            responseAttributes['attributes']['unmarkMenu'] = true;
            responseAttributes['attributes']['menuId'] = response.navigation && response.navigation.menuId ? response.navigation.menuId : '';
            yield call(
              navigateToPortal,
              response.navigation.portalId,
              oldPortalId,
              responseAttributes.attributes
            );
          }
            break;
          case 'OpenModal':
            let responseAttributes = response.attributes;
            responseAttributes['MODAL_PATH'] = 'ACTION_FLOW';
            yield call(
              openPortalInModal,
              responseAttributes,
              'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
            );
            let existingCardsData = yield select(getCard);
            const cardsData = cloneDeep(existingCardsData);
            const currentCardData = cardsData[attributes.referralId];
            if (currentCardData && Object.keys(currentCardData).length > 0) {
              currentCardData.errorData = {};
              currentCardData.buttonClicked = '';
              yield put(setChartData(currentCardData, attributes.referralId));
            }
            break;
          case 'Open In New Tab': {
              if (response.navigation.url) {
                const url =
                    window.location.origin +
                    '/#/app/ce?' +
                    response.navigation.url.split('?')[1];
                window.open(url, '_blank');
            } else if (response.navigation.otherURL) {
                window.open(
                    response.navigation.otherURL,
                    '_blank'
                );
            }
            break;
          }
          case 'Open In Same Tab':
            if (response.navigation.portalId) {
              const url = window.location.origin + '/#/app/ce/portal/' + response.navigation.portalId;
              window.open(url, '_self');
              const details = { portalId: response.navigation.portalId, data: {}, loading: true, unmarkMenu: true, menuId: response.navigation?.menuId };
              yield put(setPortalDetails(details));
            } else {
              window.open(response.navigation.url, '_self');
            }
            break;
          case 'Open In New Window':
            window.open(
              response.navigation.url,
              '_blank',
              'width=500,height=300'
            );
            break;
          case 'GenerateExcelDocument':
            // yield call(generateExcelDocument, response.documentData, response.mode === "Insert" ? "" : response.message, response.documentName);
            yield call(generateExcelDocument, response.headerData, response.documentData, response.documentName, response.mode === "Insert" ? "" : response.message);
            break;
          case 'GenerateExcelTemplateDocument':
            yield call(
              generateTemplateFile,
              response.excelModifiedTemplate,
              response.mode === 'Insert' ? '' : response.message,
              response.documentName,
              existingCardData,
              attributeReffrelId
            );
            break;
          case 'ConvertExcelToPdfDocument':
            yield call(
              generateConvertPDFTemplateFile,
              response.excelModifiedTemplate,
              response.mode === 'Insert' ? '' : response.message,
              response.documentName
            );
            break;
          case 'GenerateWordDocument':
            yield call(
              generateWordDocument,
              response.wordFiles,
              response.mode === 'Insert' ? '' : response.message,
              response.documentName,
              existingCardData,
              attributeReffrelId
            );
            break;
          case "GenerateCSVDocument":
            yield call(generateCSVDocument, response.documentData, response.mode === "Insert" ? "" : response.message, response.documentName, response.mode);
            break;




        }

        if (response.mode && response.mode !== 'Enable Message') {
          yield call(
            gateWayForActionFlowOperation,
            response,
            attributes,
            resData.data,
            attributes.nodeId && attributes.nodeId !== ROOT_NODE_ID
              ? 'CompositeEntity'
              : 'PortalForm'
          );
        } else if (response.mode && response.mode === 'Enable Message') {
          const messages: string[] = [];
          if (response.message) {
            messages.push(response.message);
          }
          let existingCardsData = yield select(getCard);
          const cardsData = cloneDeep(existingCardsData);
          const currentCardData = cardsData[attributes.referralId];
          if (currentCardData && Object.keys(currentCardData).length > 0) {
            currentCardData.errorData = {};
            currentCardData.buttonClicked = '';
            const updateStore: any = {
              cardsData: cardsData,
              toggleMessages: messages,
            };
            yield put(setStore(updateStore));
          } else {
            yield put(setToggleMessages(messages));
          }
        }
      } else if (
        (response.errors && typeof response.errors === 'string') ||
        (typeof response === 'string' &&
          response.toLowerCase().includes('error'))
      ) {
        const messages: string[] = [];
        typeof response === 'string'
          ? messages.push(response)
          : messages.push(response.errors);
        yield put(setToggleMessages(messages));
      } else {
        yield call(handleErrors, response, attributes);
      }
    }
  } else if (proceedAction) {
    switch (attributes.buttonType) {
      case WORKFLOW_BUTTON_CLASS:
      case UPDATE_BUTTON_CLASS:
      case SAVE_BUTTON_CLASS:
      case CUSTOM_BUTTON_CLASS:
      case DELETE_BUTTON_CLASS: {
        const action: any = {
          nodeId: attributes.nodeId,
          actionName: attributes.actionName,
          transactionName: attributes.transactionName,
          keys: attributes.keys,
          referenceData: attributes.referenceData,
          displayName: attributes.displayName,
          nodeType: attributes.nodeType,
          ceId: attributes.ceId,
          mode: attributes.mode,
          modalRequired: attributes.modalRequired,
          location: attributes.location,
          referralId: attributes.referralId,
          resetForm: attributes.resetForm,
          portalForm: attributes.portalForm,
          parentFormId: attributes.parentFormId,
          portalId: attributes.portalId,
          parentId: attributes.parentId,
          usePortalReferenceData: attributes.usePortalReferenceData,
        };
        yield call(gateWayForUpsertOperation, action);
        break;
      }
      case DOWNLOAD_REPORT_BUTTON_CLASS: {
        yield call(downloadReport, { formId: attributes.parentFormId });
        break;
      }
      case DELETE:
      case DELETE_FORM_DATA_CLASS: {
        const actionData: any = {
          primaryKey: attributes.compositeEntityKey,
          logicalEntityIdOrName: attributes.entityId,
          ceId: attributes.ceId,
          transactionName: attributes.transactionName,
          nodeId: attributes.nodeId,
          gridId: attributes.gridId,
          redirectToGrid: true,
          referralId: attributes.referralId,
          usePortalReferenceData: attributes.usePortalReferenceData,
          portalId: attributes.portalId,
          parentId: attributes.parentId,
          parentFormId: attributes.parentFormId,
        };
        yield call(deleteRecordSaga, actionData);
        break;
      }
      case 'Portal Form':
      case 'Side Node': {
        try {
          const updatedParam: any = {};

          Object.assign(
            updatedParam,
            attributes?.location?.state,
            attributes.routeStateParams
          );

          yield router.navigate(attributes.url, { state: updatedParam });
        } catch (e) {
          console.log('e=====================', e);
        }
        break;
      }
      default:
    }
  }
}

function* actionFlowRouting(action: any): Generator<any, any, any> {
  switch (action.actionType) {
    case ActionType.MENU_CLICK:
      yield call(menuRouting, action.attributes);
      break;

    case ActionType.BUTTON_CLICK:
      yield call(buttonClickRouting, action.attributes);
      break;
  }
}

export function* goToPreviousRoute(action: any): Generator<any, any, any> {
  router.navigate(-1);
}