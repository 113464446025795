import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Container = styled.div`
  padding: 3px;
`;

interface IToolTipComp {
  max8digit: any;
  upperCase: any;
  lowerCase: any;
  digits: any;
  specialSymbol: any;
  component: any;
  intl: any;
}

const ToolTipComp: React.FC<IToolTipComp> = (props: IToolTipComp) => {
  const [isTooltipActive, setTooltipActive] = useState(false);

  const showTooltip = () => {
    setTooltipActive(true);
  };

  const hideTooltip = () => {
    setTooltipActive(false);
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <p
        role='presentation'
        id="text"
        data-tip
        data-for="tooltip"
        onMouseEnter={showTooltip}
        onFocusCapture={showTooltip}
        onKeyPress={showTooltip}
        onBlurCapture={hideTooltip}
        onMouseLeave={hideTooltip}
      >
        {props.component}
      </p>
      {isTooltipActive && (
        <ReactTooltip id="tooltip" place="top">
          <Container>
            {props.max8digit}
            {props.upperCase}
            {props.lowerCase}
            {props.digits}
            {props.specialSymbol}
          </Container>
        </ReactTooltip>
      )}
    </div>
  );
};

export default ToolTipComp;
