import React, { useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SelectOption } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { getPrivilege } from '../../../utils/ClientUtils';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { UserContext } from '../../../user-context/UserContext';
import styled from 'styled-components';
import {
  EDIT_MODE,
  FormNavigationType,
  ActionType,
  GridType,
  NodeType,
} from '../../../constants/appeng.enum';
import {
  setPortalDetails,
  actionFlowRouting,
  getOptions,
} from '../../../actions';
import { isConfigObjectEditable } from '../../../composite-entity/form/CardGenerators';
import { v4 as uuid } from 'uuid';
// import DataGridToolTip from './DataGridToolTip';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  color: #0000ffba;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSelectOption = styled(SelectOption)``;

function handleLinkClick(
  onClickHandler,
  parentProps,
  actionColumnType,
  hrefValue,
  hyperlinkDBCode,
  rowDetails,
  dispatch,
  modalCss
) {
  let column = {};
  column['actionColumnType'] = actionColumnType;
  column['hrefValue'] = hrefValue;
  column['hyperlinkDBCode'] = hyperlinkDBCode;
  column['modalCss'] = modalCss;
  if (onClickHandler) {
    onClickHandler(column, rowDetails, parentProps, dispatch);
  }
}

const EditableSelectCell = ({ cell, row, column: { id }, updateMyData }) => {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const location = useLocation();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tooltipRef = useRef(null);
  const ffPrivilege = getPrivilege(
    cell.column.configProp.privileges,
    userContext.getCurrentRole()
  );
  const tooltipUniqueId = uuid();
  const [isEditable, setEditable] = React.useState(false);
  const [value, setValue] = React.useState({});
  const [count, setCount] = React.useState(0);
  // const existingCards = useSelector((state) => state.appState.cardsData);
  //WHEN INLINE EDIT IS DISABLE AND ADD NEW ROW IS ENABLE
  // let cellEditable =
  //   row.original &&
  //     row.original['expander'] === 'NewRow' &&
  //     row.values[cell.column.primaryDBCode] === ''
  //     ? true
  //     : false;
  let cellEditable =
    row.original?.expander === 'NewRow' &&
    row.values[cell.column.primaryDBCode] === '';

  let rowData = row.original;
  Object.assign(rowData, userContext.getUserDetail());
  let columnIsEditibale = isConfigObjectEditable(
    true,
    cell.column.privilege,
    cell.column.editabilityRegex,
    rowData,
    'EDIT',
    true
  );

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
      row.id.toString(),
      id,
      cell.column.referralId,
      true
    )
    : null;

  // const customStyles = {
  //     control: (base, state) => ({
  //         ...base,
  //         border: (cell.column.isDefaultEditable ? 'unset!important' : errorData && errorData["dbCode"] && errorData["dbCode"] === id ? '2px solid red!important' : ''),
  //         boxShadow: 'none!important',
  //     }),
  //     menuPortal: (base, state) => ({
  //         ...base,
  //         zIndex: '9999!important',
  //         'margin-top': "-7px!important"
  //     }),
  //     singleValue: (base, state) => ({
  //         ...base,
  //         'margin-top': '1.50px!important',
  //     }),
  //     input: styles => {
  //         return {
  //             ...styles,
  //             height: '1.85rem'
  //         };
  //     },
  //     indicatorSeparator: styles => ({ display: 'none' })
  // }

  let optionList = [];
  if (
    cell.column.configProp &&
    optionList.length === 0 &&
    cell.column.configProp.multivalueList
  ) {
    optionList = cell.column.processDropDownValues(
      cell.column.configProp.multivalueList,
      cell.column.parentProps.intl.locale,
      optionList
    );
  } else if (
    cell.column.configProp &&
    optionList.length === 0 &&
    cell.column.cardsData
  ) {
    let referenceID =
      cell.column.formId +
      '_' +
      cell.column.occuranceNumber +
      '_' +
      cell.column.configProp.configObjectId;
    optionList = cell.column.cardsData.options[referenceID]
      ? cell.column.cardsData.options[referenceID]
      : [];
  }

  React.useEffect(() => {
    if (optionList.length > 0) {
      let isFound = false;
      optionList.forEach(function (option, index) {
        let optionId = option.id ? option.id : option.Id ? option.Id : option.ID;
        if (optionId == cell.value) {
          isFound = true;
          let selectedValue = {};
          selectedValue['value'] = optionId;
          selectedValue['label'] = option.label ? option.label : option.LABEL;
          setValue(selectedValue);
        }
      });
      if (!isFound && cell.value) {
        // const regexExp =
        //   /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        // const isQueryGetFire = regexExp.test(cell.value);
        if (count < 1 && !cell.column.configProp.multivalueList) {
          let qurCount = count + 1;
          setCount(qurCount);
          dispatch(
            getOptions(
              cell.column.parentFormId,
              cell.column.formId,
              cell.column.configProp,
              cell.column.occuranceNumber,
              cell.column.parentProps.nodeId,
              cell.column.referralId,
              cell.column.parentProps.location
            )
          );
        } else {
          isFound = true;
          let selectedValue = {};
          selectedValue['value'] = cell.value;
          selectedValue['label'] = cell.value;
          setValue(selectedValue);
        }
      } else if (!isFound) {
        isFound = true;
        let selectedValue = {};
        selectedValue['value'] = '';
        selectedValue['label'] = '';
        setValue(selectedValue);
      }
    }
  }, [cell.column]);

  React.useEffect(() => {
    if (optionList.length > 0) {
      let isFound = false;
      optionList.forEach(function (option, index) {
        let optionId = option.id ? option.id : option.Id ? option.Id : option.ID;
        if (optionId == cell.value) {
          isFound = true;
          let selectedValue = {};
          selectedValue['value'] = optionId;
          selectedValue['label'] = option.label ? option.label : option.LABEL;
          setValue(selectedValue);
        }
      });
      if (!isFound && cell.value) {
        isFound = true;
        let selectedValue = {};
        selectedValue['value'] = cell.value;
        selectedValue['label'] = cell.value;
        setValue(selectedValue);
      } else if (!isFound) {
        isFound = true;
        let selectedValue = {};
        selectedValue['value'] = '';
        selectedValue['label'] = '';
        setValue(selectedValue);
      }
    }
  }, [cell.column, isEditable, cell.column.filteredRows, row]);

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  React.useEffect(() => {
    if (tooltipRef.current) {
      const isOverflow =
        tooltipRef.current.scrollWidth > tooltipRef.current.clientWidth;
      setIsOverflowing(isOverflow);
    }
  }, [value, cell.value, isEditable]);

  const onChange = (e) => {
    setEditable(false);
    setValue(e ? e : {});
    if (e && (e.id !== value.value || e.ID !== value.value || e.Id !== value.value) && updateMyData) {
      updateMyData(row.index, id, e.id ? e.id : e.Id ? e.Id : e.ID, row.id);
    } else if (e === null && updateMyData) {
      updateMyData(row.index, id, e, row.id);
    }
  };

  const handleBlur = () => {
    setEditable(false);
  };

  const handleClick = () => {
    if (columnIsEditibale) {
      if (errorData || (errorData && errorData.path !== row.id.toString())) {
        setEditable(false);
      } else {
        setEditable(true);
      }
    }
  };

  const linkHandleClick = (value) => {
    // const location = useLocation();
    const gotoLink = JSON.parse(cell.column.configProp.gotoLink);
    const prefixValue = cell.column.cardsData.id.includes('_')
      ? cell.column.cardsData.id.split('_')[0] + '_'
      : '0_';
    const portalId = prefixValue + gotoLink.portalId;
    const componentType =
      cell.column.gridType === GridType.PARENT ? 'ParentForm' : 'ChildForm';
    // todo: zinel this is a patch need to change. the column should conatin foriegn key
    const rowDetails = { ...row.values };
    const updateObj = {};
    updateObj[gotoLink.primaryDBCode] =
      rowDetails[cell.column.configProp.logicalColumn.dbCode];
    Object.assign(rowDetails, updateObj);
    const referenceData = {
      componentType: componentType,
      ceId: gotoLink.ceId,
      nodeId: gotoLink.nodeId,
      referenceData: rowDetails,
      data: rowDetails,
      nodeType:
        componentType === 'ParentForm'
          ? NodeType.PARENTNODE
          : NodeType.CHILDNODE,
      mode: EDIT_MODE,
      previousPortalId: cell.column.routerParam.portalId,
      primaryKey: value.value,
      AE_RELOAD: {
        ID: uuid(),
        TS: new Date().getTime(),
        STATUS: 'RT',
      },
      primaryDBCode: gotoLink.primaryDBCode,
    };
    // Obtain location from React Router
    const routerParam = {
      url: '/app/ce/portal/' + uuid(),
      location: location,
      buttonType: FormNavigationType.PORTAL_FORM,
      resetReferenceData: false,
      routeStateParams: {},
    };

    Object.assign(routerParam.routeStateParams, cell.column.routerParam);
    routerParam.routeStateParams['portalId'] = portalId;
    routerParam.routeStateParams[portalId] = referenceData;
    const details = { portalId: portalId, data: referenceData, loading: true };
    dispatch(setPortalDetails(details));
    dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
  };

  let tooltipID = 'selectOptionError_' + id + '_' + row.id.toString();
  let innerWidth = window.innerWidth - 20;
  let isDisabled =
    ffPrivilege === 'VIEW' ||
      cell.column.configProp.isHyperlink ||
      cell.column.configProp.hyperLink
      ? true
      : false;
  return columnIsEditibale &&
    (cell.column.editEnable || cellEditable) &&
    cell.column.isDefaultEditable &&
    cell.column?.changeEnable ? (
    <div style={{ display: 'flex', 'flex-wrap': 'wrap' }}>
      {errorData && errorData['dbCode'] && errorData['dbCode'] === id ? (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          aria-hidden="true"
          data-for={tooltipID}
          data-tip={tooltipID}
          style={{
            'align-self': 'center',
            'padding-left': '3px',
            color: 'red',
          }}
        />
      ) : (
        ''
      )}
      <div
        style={{
          height: '100%',
          position: 'relative',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '98%'
              : '99%',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <StyledSelectOption
          value={value}
          onChange={(e) => onChange(e)}
          options={optionList}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          isDisabled={isDisabled}
          displayType={'SingleLine'}
          isDefaultEditable={cell.column.isDefaultEditable}
          hasError={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? true
              : false
          }
          isInlineGridComponent={true}
          autoFocus={false}
          isClearable={!cell.column.configProp.isMandatory}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </div>
  ) : columnIsEditibale &&
    ((cell.column.editEnable && cellEditable && cell.column?.changeEnable) ||
      (cell.column.editEnable && isEditable && cell.column?.changeEnable)) ? (
    <React.Fragment>
      <div
        role="presentation"
        onMouseLeave={() => setEditable(false)}
        style={{
          height: '100%',
          position: 'relative',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          // width:
          //   errorData && errorData['dbCode'] && errorData['dbCode'] === id
          //     ? '99%'
          //     : '99%',
          width: '99%',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <StyledSelectOption
          value={value}
          onChange={(e) => onChange(e)}
          options={optionList}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          isDisabled={isDisabled}
          displayType={'SingleLine'}
          isDefaultEditable={cell.column.isDefaultEditable}
          hasError={
            errorData?.dbCode === id ? true : false
            // errorData && errorData['dbCode'] && errorData['dbCode'] === id
            //   ? true
            //   : false
          }
          isInlineGridComponent={true}
          autoFocus={false}
          isClearable={!cell.column.configProp.isMandatory}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData?.dbCode === id ? 'rgb(255, 0, 0)' : 'none',
            // errorData && errorData['dbCode'] && errorData['dbCode'] === id
            //   ? 'rgb(255, 0, 0)'
            //   : 'none',
          }}
          id={
            errorData?.dbCode === id ? errorData.message : ''
            // errorData && errorData['dbCode'] && errorData['dbCode'] === id
            //   ? errorData.message
            //   : ''
          }
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true && value.value ? (
        !cell.column.configProp.isHyperlink &&
          !cell.column.configProp.hyperLink ? (
          <>
            <div
              role="presentation"
              ref={tooltipRef}
              data-tooltip-id={tooltipUniqueId}
              data-tooltip-content={value.label}
              onBlur={() => handleBlur()}
              onClick={() => handleClick()}
              style={{
                padding: '10px 10px',
                // width: cell.column.width + 'px',
                // position: 'absolute',
                height: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {value.label}
            </div>
            {isOverflowing && (
              <ReactTooltip
                id={tooltipUniqueId}
                place={'bottom'}
                style={{
                  zIndex: 1000,
                  wordWrap: 'break-word',
                  maxWidth: `${innerWidth}px`,
                  maxHeight: '400px',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  wordBreak: 'break-all',
                }}
                positionStrategy="fixed"
              />
            )}
          </>
        ) : (
          <>
            <StyledDiv
              ref={tooltipRef}
              data-tooltip-id={tooltipUniqueId}
              data-tooltip-content={value.label}
              onClick={() =>
                cell.column.configProp.isHyperlink
                  ? linkHandleClick(value)
                  : handleLinkClick(
                    cell.column.onClickHandler,
                    cell.column.parentProps,
                    cell.column.actionColumnType,
                    cell.column.hrefValue,
                    cell.column.hyperlinkDBCode,
                    row,
                    dispatch,
                    cell.column.modalCss
                  )
              }
              style={{
                width: cell.column.width + 'px',
                position: 'relative',
                height: '100%',
              }}
            >
              {value.label}
            </StyledDiv>
            {isOverflowing && (
              <ReactTooltip
                id={tooltipUniqueId}
                place={'bottom'}
                style={{
                  zIndex: 1000,
                  wordWrap: 'break-word',
                  maxWidth: `${innerWidth}px`,
                  maxHeight: '400px',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  wordBreak: 'break-all',
                }}
                positionStrategy="fixed"
              />
            )}
          </>
        )
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          style={{
            // width: cell.column.width + 'px',
            // position: 'absolute',
            height: '100%',
          }}
          onBlur={() => handleBlur()}
          onClick={() => handleClick()}
          role="presentation"
        ></div>
      )}
    </React.Fragment>
  );
};

EditableSelectCell.propTypes = {
  row: PropTypes.any,
  cell: PropTypes.any,
  column: PropTypes.any,
  'column.id': PropTypes.any,
  updateMyData: PropTypes.any,
  'cell.value': PropTypes.any,
  'cell.column.useErrorDataUsingDBCode': PropTypes.any,
  'cell.column.referralId': PropTypes.any,
  'row.id': PropTypes.any,
  'row.index': PropTypes.any,
  'cell.column.editEnable': PropTypes.any,
  'cell.column.changeEnable': PropTypes.any,
  'cell.column.optionList': PropTypes.any,
  'cell.column.styleName': PropTypes.any,
  'cell.column.ioTheme': PropTypes.any,
  'cell.column.ioMode': PropTypes.any,
  'cell.isRepeatedValue': PropTypes.any,
  'cell.column.configProp.privileges': PropTypes.any,
  'cell.column.configProp': PropTypes.any,
  'row.original': PropTypes.any,
  'row.original.expander': PropTypes.any,
  'cell.column.primaryDBCode': PropTypes.any,
  'row.values': PropTypes.any,
  'cell.column.privilege': PropTypes.any,
  'cell.column.editabilityRegex': PropTypes.any,
  'cell.column.configProp.multivalueList': PropTypes.any,
  'cell.column.processDropDownValues': PropTypes.any,
  'cell.column.parentProps.intl.locale': PropTypes.any,
  'cell.column.parentProps.intl': PropTypes.any,
  'cell.column.parentProps': PropTypes.any,
  'cell.column.cardsData': PropTypes.any,
  'cell.column.formId': PropTypes.any,
  'cell.column.occuranceNumber': PropTypes.any,
  'cell.column.configProp.configObjectId': PropTypes.any,
  'cell.column.cardsData.options': PropTypes.any,
  'cell.column.configProp.selectItemsReferenceID': PropTypes.any,
  'cell.column.parentFormId': PropTypes.any,
  'cell.column.parentProps.nodeId': PropTypes.any,
  'cell.column.parentProps.location': PropTypes.any,
  'cell.column.configProp.gotoLink': PropTypes.any,
  'cell.column.cardsData.id.includes': PropTypes.any,
  'cell.column.cardsData.id': PropTypes.any,
  'cell.column.cardsData.id.split': PropTypes.any,
  'cell.column.configProp.logicalColumn.dbCode': PropTypes.any,
  'cell.column.configProp.logicalColumn': PropTypes.any,
  'cell.column.routerParam.portalId': PropTypes.any,
  'cell.column.routerParam': PropTypes.any,
  'cell.column.configProp.isHyperlink': PropTypes.any,
  'cell.column.configProp.hyperLink': PropTypes.any,
  'cell.column.width': PropTypes.any,
  'cell.column.onClickHandler': PropTypes.any,
  'cell.column.actionColumnType': PropTypes.any,
  'cell.column.hrefValue': PropTypes.any,
  'cell.column.hyperlinkDBCode': PropTypes.any,
  'cell.column.modalCss': PropTypes.any,
};

export default React.memo(EditableSelectCell);
