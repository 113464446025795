import React, { lazy } from 'react';
import { Provider } from 'react-redux';

import { makeStore } from './store/index';
import { client } from './utils/ClientUtils';
import { createStorage } from './storage';

import ReactDOM from 'react-dom/client';

import { getLocaleMessages } from './utils/intlutils';
// import './index.css';
// import '../src/components/styles.css';
import { IntlProvider } from 'react-intl';

const CoreApp = lazy(() => import('./CoreApp'));

const locale = 'en';
const localeData = {
  en: () => import('@formatjs/intl-pluralrules/locale-data/en'),
  gu: () => import('@formatjs/intl-pluralrules/locale-data/gu'),
  hi: () => import('@formatjs/intl-pluralrules/locale-data/hi'),
};

const loadLocaleData = async (locale) => {
  if (localeData[locale]) {
    await localeData[locale]();
  }
};
declare global {
  interface Window {
    require: any;
    env: any;
  }
}

const requiredummy = () => {
  console.log('abcd');
  return '123';
};

window.require = requiredummy;
window.env.REACT_APP_APPENG_AUTH_URL = window.env.HOST_URL + '/api-auth/v1';
window.env.REACT_APP_APPENG_OUTBOUND_URL = window.env.HOST_URL + '/api-out/v1/';
window.env.REACT_APP_APPENG_META_RELOAD_URL =
  window.env.HOST_URL + '/api-meta/';
window.env.REACT_APP_APPENG_CORE_URL = window.env.HOST_URL + '/api-core/v1/';
window.env.REACT_APP_BPMN_URL = window.env.HOST_URL + '/v1';
window.env.REACT_APP_APPENG_REPORTS_URL = window.env.HOST_URL + '/v1/download';
window.env.REACT_APP_APPENG_ATTACHMENT =
  window.env.HOST_URL + '/api-attachment/v1/';
window.env.REACT_APP_TREE_STRUCTURE_URL =
  window.env.HOST_URL + '/api-feature-struct/v1/';
window.env.REACT_APP_INFO_ACTIVITY_TRACKING_SERVER_URL =
  window.env.HOST_URL + '/';
window.env.REACT_APP_SEND_FEEDBACK_URL = window.env.HOST_URL + '/dev/v1/';
window.env.REACT_APP_HIDE_APPLICATION_LIST = 'true';
window.env.REACT_APP_PLATFORM = 'web';
window.env.REACT_APP_BUCKET_NAME = 'corso-application';
window.env.REACT_APP_STORYBOOK_BUCKET_NAME = 'storybook-autodeploy-script';

const storage = createStorage();
const localLang = getLocaleMessages(locale);
const { ApolloProvider } = await import('@apollo/client');

const preLoadedState = {
  addModals: [],
  cardsData: {},
  treeDatas: {},
  leftMenuIndicator: '',
  droppableIndicator: false,
  rootNodeId: '',
  options: {},
  errorData: {},
  toggleMessages: [],
  locale: 'en',
  messages: localLang,
  userDetails: '',
  currentFinancialYear: '',
  portalDetails: {},
  gridIndexes: {},
  portalLayout: {},
  applications: [],
  currentProject: '',
  openModals: {},
  warningMessages: {},
};

const store = makeStore({ appState: preLoadedState });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

loadLocaleData(locale).then(() => {
  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={localLang}>
          <CoreApp />
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  );
});
