import React, { useContext } from 'react';
import { FullSizeModal, FormErrorContainers } from 'info-ui-library';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ThemeContext } from '../theme/ThemeContext';
import Portal from '../new-portal-layout/Portal';

interface IPortalModalDenerator extends WrappedComponentProps {
  header?: any;
  subportalId: any;
  routerParameter: any;
  subComponentParentId: string;
  portalId: string;
  modalAttribute?: string;
  parentCardId?: any;
}
const PortalModalDenerator: React.FC<IPortalModalDenerator> = (props: IPortalModalDenerator) => {
    const themeContext = useContext(ThemeContext);
    const modalStyleList = props.modalAttribute ? props.modalAttribute.split(";") : [];
    let modalStyleMap: any = {};
    modalStyleList.forEach(element=>{
        const styleMap = element.split(":");
        if (styleMap && styleMap.length === 2) {
            modalStyleMap[styleMap[0].trim()] = styleMap[1].trim();
        }
    })
    try {

        const modalList =
            (
                <FullSizeModal ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    id={'dfhgj-45t3dfg'}
                    isOpen={true}
                    modalStyle={modalStyleMap}
                    key={"_" + props.parentCardId}
                >
                    <React.Fragment>
                        <Portal routerParameter={props.routerParameter} 
                        portalId={props.portalId}
                        isSubPortal={true} 
                        key={"_" + props.portalId}
                        headerButtons={props.header} 
                        subportalId={props.subportalId} 
                        subComponentParentId={props.subComponentParentId}
                        parentCardId={props.parentCardId}/>
                    </React.Fragment>
                </FullSizeModal>
            )
        return (
            <React.Fragment>
                {modalList}
            </React.Fragment>
        );
    }
    catch{
        const error = ['Error occurred in Core FullSizeModal']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
};

export default injectIntl(PortalModalDenerator);
