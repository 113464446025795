import {
  put,
  all,
  fork,
  takeLatest,
  // takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import { setStore, UPSERT_ENTITY_USING_LE } from '../../actions';
import { UpsertMessage, QueryType } from '../../constants/appeng.enum';

const getCard = (state: any) => state.appState.cardsData;

export default function* upsertEntityUsingLogicalEntitySaga() {
  yield all([fork(watchUpsertEntityUsingLogicalEntity)]);
}

function* watchUpsertEntityUsingLogicalEntity() {
  yield takeLatest(UPSERT_ENTITY_USING_LE, upsertEntityUsingLogicalEntity);
}

function* upsertEntityUsingLogicalEntity(
  action: any
): Generator<any, any, any> {
  const { data } = yield call(
    base.getConfigData,
    QueryType.LOGICAL_ENTITY,
    action.leId
  );
  const response = yield call(
    base.upsertFormRecord,
    action.reqBody,
    data.LogicalEntity.name,
    action.primaryKey
  );
  const messages: string[] = [];
  const existingCardsData = yield select(getCard);
  const cardsData = { ...existingCardsData };
  const existingCard = cardsData[action.cardId];
  const rd = {
    ...existingCard.referenceData,
    AE_RELOAD_ID: response.transactionId,
  };
  const parsedError: any = {};
  if (response.pk) {
    messages.push(
      response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
    );
  } else if (response.errors) {
    messages.push(UpsertMessage.VALIDATION_FAILED);
    for (const err of response.errors) {
      const elements = err.location.split('=>');
      const dbCode = elements[elements.length - 1];
      if (!parsedError.hasOwnProperty(dbCode)) {
        parsedError[dbCode] = [err.message];
      } else {
        const errors = parsedError[dbCode];
        errors.push(err.message);
        parsedError[dbCode] = errors;
      }
    }
  }
  const cardData = new CardData(
    action.cardId,
    existingCard.type,
    existingCard.data,
    existingCard.portalId,
    existingCard.parentId,
    rd,
    parsedError,
    existingCard.options,
    existingCard.datagridErrorData,
    existingCard.toggleTabRefresh
  );
  cardsData[action.cardId] = cardData;
  const updateStore: any = {
    cardsData: cardsData,
    toggleMessages: messages,
  };
  yield put(setStore(updateStore));
}
