import React, {
  useContext,
  useEffect,
  useState,
  DragEvent as ReactDragEvent,
  useCallback,
} from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Dropzone, FormErrorContainers } from 'info-ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadAttachment,
  deleteAttachment,
  setFileDroppableIndicator,
  removeAttachment,
  fetchAttachment,
  downloadAttachment,
  changeAttachmentType,
  setChartData,
  reOrderAttachments,
  setToggleMessages,
} from '../actions/index';
import { IDropzone } from './interfaces/index';
import { v4 as uuid } from 'uuid';
import { useLocation } from 'react-router';
import { downloadAllAttachments } from '../actions/attachment/attachment';
import { UpsertMessage } from '../constants/appeng.enum';

const DropzoneCore: React.FC<IDropzone> = (props: IDropzone) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const cardDatas = useSelector((state: any) => state.appState.cardsData);
  const reloadAttachmentFiles = useSelector(
    (state: any) =>
      state.appState.cardsData[props.referralId].reloadAttachmentFiles
  );
  const droppableIndicator = useSelector(
    (state: any) => state.appState.droppableIndicator
  );
  const attachmentTypeError = useSelector(
    (state: any) =>
      state.appState.cardsData[props.referralId].attachmentTypeError
  );

  const [parentKey, setParentKey] = useState(
    cardDatas[props.referralId].data[0][props.configProp.logicalColumn.dbCode]
      ? cardDatas[props.referralId].data[0][
          props.configProp.logicalColumn.dbCode
        ]
      : ''
  );
  const onDragLeaveFromDiv = (e: any) => {
    dispatch(setFileDroppableIndicator(false));
  };

  const handleFetchAttachment = useCallback(() => {
    let parentPrimaryKey = '';
    let primaryDbcode = props.configProp.logicalColumn.dbCode;
    if (cardDatas[props.referralId].data[0][primaryDbcode]) {
      parentPrimaryKey = cardDatas[props.referralId].data[0][primaryDbcode];
    }
    if (
      parentPrimaryKey != '' &&
      (!cardDatas[props.referralId].data.attachmentData ||
        cardDatas[props.referralId].data.attachmentData.length === 0)
    ) {
      const TABLE_NAME = primaryDbcode.trim().replace('_UUID', '');
      dispatch(fetchAttachment(TABLE_NAME, parentPrimaryKey, props.referralId));
    }
  }, []);

  const initAttachTypeApplicable = () => {
    const updatedCardData = cardDatas[props.referralId];
    updatedCardData.isAttachTypeApplicable =
      props.configProp.multivalueList && props.configProp.multivalueList.trim()
        ? true
        : false;
    dispatch(setChartData(updatedCardData, props.referralId));
  };

  useEffect(() => {
    initAttachTypeApplicable();
  }, []);

  useEffect(() => {
    if (parentKey === '') {
      let primaryDbcode = props.configProp.logicalColumn.dbCode;
      if (cardDatas[props.referralId].data[0][primaryDbcode]) {
        setParentKey(cardDatas[props.referralId].data[0][primaryDbcode]);
      }
    }
    handleFetchAttachment();
  }, [parentKey]);

  const handleDownloadAttachment = useCallback(
    (id: any, name: any, data: any) => {
      dispatch(downloadAttachment(id, name, data, props.referralId));
    },
    []
  );

  const handleDownloadAllAttachments = (fileList: any[]) => {
    if (fileList && fileList.length > 0) {
      const fileData: any = [];
      fileList.forEach((file) => {
        if (file.type.includes('_saved')) {
          const fileSplit = file.type.split('_');
          const obj: any = {};
          obj.id = fileSplit[fileSplit.length - 1];
          obj.name = file.name;

          fileData.push(obj);
        }
      });
      dispatch(downloadAllAttachments(fileData, props.referralId));
    }
  };

  const handleDeleteAttachment = useCallback(
    (id: any, name: any, data: any) => {
      let parentPrimaryKey = '';
      let primaryDbcode = props.configProp.logicalColumn.dbCode;
      if (cardDatas[props.referralId].data[0][primaryDbcode]) {
        parentPrimaryKey = cardDatas[props.referralId].data[0][primaryDbcode];
      }
      if (parentPrimaryKey != '') {
        let extractIdList: any = id.split('_');
        dispatch(
          deleteAttachment(
            extractIdList[extractIdList.length - 1],
            name,
            parentPrimaryKey,
            props.referralId
          )
        );
      }
    },
    []
  );

  const handleAddFile = useCallback((data: any, attachmentOrder: number) => {
    let parentPrimaryKey = '';
    let primaryDbcode = props.configProp.logicalColumn.dbCode;
    if (cardDatas[props.referralId].data[0][primaryDbcode]) {
      parentPrimaryKey = cardDatas[props.referralId].data[0][primaryDbcode];
    }
    const TABLE_NAME = primaryDbcode.trim().replace('_UUID', '');
    let attachmentDetails: any = {};
    attachmentDetails['entityName'] = TABLE_NAME;
    attachmentDetails['keyName'] = primaryDbcode;
    attachmentDetails['attachmentOrder'] = attachmentOrder;
    attachmentDetails['isAttachTypeApplicable'] =
      props.configProp.multivalueList && props.configProp.multivalueList.trim()
        ? true
        : false;
    attachmentDetails['parentPrimaryKey'] = parentPrimaryKey
      ? parentPrimaryKey
      : ''; 
    if (props.configProp.APP_LOGGED_IN_PROJECT_ID != '-9') {
      attachmentDetails['appendDetails'] = 'BeforeExtension';
    }
    if (parentPrimaryKey != '') {
      dispatch(
        uploadAttachment(
          attachmentDetails,
          parentPrimaryKey,
          data,
          props.referralId,
          currentLocation
        )
      );
    } else {
      attachmentDetails['attachmentOrder'] = null;
      dispatch(
        uploadAttachment(
          attachmentDetails,
          uuid(),
          data,
          props.referralId,
          currentLocation
        )
      );
    }
  }, []);

  const handleRemoveFile = useCallback((data: any, name: any) => {
    let extractIdList: any = data.includes('uploaded')
      ? data.split('_')
      : [name];
    dispatch(
      removeAttachment(
        extractIdList[extractIdList.length - 1],
        props.referralId,
        currentLocation
      )
    );
  }, []);

  const handleChangeAttachmentType = useCallback(
    (file: File, attachmentType: string, attachmentOrder: number) => {
      let parentPrimaryKey = '';
      let primaryDbcode = props.configProp.logicalColumn.dbCode;
      if (cardDatas[props.referralId].data[0][primaryDbcode]) {
        parentPrimaryKey = cardDatas[props.referralId].data[0][primaryDbcode];
      }

      const TABLE_NAME = primaryDbcode.trim().replace('_UUID', '');
      let attachmentDetails: any = {};
      attachmentDetails['entityName'] = TABLE_NAME;
      attachmentDetails['keyName'] = primaryDbcode;
      attachmentDetails['attachmentOrder'] = attachmentOrder;
      if (props.configProp.APP_LOGGED_IN_PROJECT_ID != '-9') {
        attachmentDetails['appendDetails'] = 'BeforeExtension';
      }

      let extractIdList: any = file.type.includes('saved')
        ? file.type.split('_') : [file.name];

      dispatch(
        changeAttachmentType(
          attachmentDetails,
          parentPrimaryKey,
          extractIdList[extractIdList.length - 1],
          attachmentType,
          props.referralId,
          currentLocation
        )
      );
    },
    []
  );


  const handleReorderAttachments = (fileData: any) => {
    dispatch(reOrderAttachments(fileData, props.referralId));
  }

  const handleUploadValidation = (message : string, variable: string | undefined) => {
    const messages: string[] = [];

    let currentMessage: string = UpsertMessage[message];
    if(currentMessage.includes('$') && variable) {
      currentMessage = currentMessage.split('$').join(variable);
    }
    messages.push(currentMessage);

    dispatch(setToggleMessages(messages));
  }

  try {
    return (
      <Dropzone
        onDragLeaveFromDiv={onDragLeaveFromDiv}
        droppableIndicator={droppableIndicator}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        key={props.configProp.logicalColumn.configObjectId}
        handleAddFile={handleAddFile}
        layout={props.configProp.layout}
        text={props.configProp.label}
        placeHolder={props.configProp.placeHolder}
        mode={props.mode}
        fetchAttachment={handleFetchAttachment}
        downloadAttachment={handleDownloadAttachment}
        downloadAllAttachments={handleDownloadAllAttachments}
        parentId={parentKey}
        attachmentData={cardDatas[props.referralId]}
        handleRemoveFile={handleRemoveFile}
        deleteAttachment={handleDeleteAttachment}
        attachmentType={props.configProp.multivalueList}
        privilegesMap={props.privilegesMap}
        previewPrivilegesMap={props.previewPrivilegesMap}
        deletePrivilegesMap={props.deletePrivilegesMap}
        changeAttachmentType={handleChangeAttachmentType}
        attachmentTypeError={attachmentTypeError}
        reloadAttachmentFiles={reloadAttachmentFiles}
        reOrderAttachments={handleReorderAttachments}
        acceptedFileTypes={props.acceptedFileTypes}
        maxFilesAllowed={props.maxFilesAllowed}
        isReorderAllowed={props.isReorderAllowed}
        handleUploadValidation={handleUploadValidation}
      />
    );
  } catch {
    const error = ['Error occurred in Attachment'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(DropzoneCore);
