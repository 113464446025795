import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DataGridToolTip from './DataGridToolTip';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  color: #0000ffba;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: underline;
`;

function handleClick(
  onClickHandler,
  parentProps,
  actionColumnType,
  hrefValue,
  hyperlinkDBCode,
  rowDetails,
  dispatch,
  auditExtractedData,
  modalCss
) {
  let column = {};
  column['actionColumnType'] = actionColumnType;
  column['hrefValue'] = hrefValue;
  column['hyperlinkDBCode'] = 'AUDIT';
  column['auditExtractedData'] = auditExtractedData;
  column['modalCss'] = modalCss;
  if (onClickHandler && auditExtractedData.length > 0) {
    onClickHandler(column, rowDetails, parentProps, dispatch);
  }
}

const AuditColumnJson = ({ cell, row, column: { id } }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(cell.value);
  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  let columnNames = cell.column.auditColumnName;
  const columnNameAsList = columnNames.split(',');

  let jsonArray = JSON.parse(value);
  let concatevalue = '';
  const optionObject = [];

  for (let key in jsonArray) {
    if (columnNameAsList.includes(key)) {
      for (let i = 0; i < columnNameAsList.length; i++) {
        if (columnNameAsList[i] === key) {
          let childArray = jsonArray[key];
          concatevalue =
            concatevalue +
            key +
            ' : ' +
            childArray['oldValue'] +
            ' ->' +
            childArray['newValue'] +
            ' , ';
          childArray['dbCode'] = key;
          optionObject.push(childArray);
        }
      }
    }
  }

  return cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    !cell.column.isEllipsesEnable ? (
    <StyledDiv
      onClick={(e) =>
        handleClick(
          cell.column.onClickHandler,
          cell.column.parentProps,
          cell.column.actionColumnType,
          cell.column.hrefValue,
          cell.column.hyperlinkDBCode,
          row,
          dispatch,
          optionObject,
          cell.column.modalCss
        )
      }
    >
      {concatevalue}
    </StyledDiv>
  ) : cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    cell.column.isEllipsesEnable ? (
    <StyledDiv
      onClick={(e) =>
        handleClick(
          cell.column.onClickHandler,
          cell.column.parentProps,
          cell.column.actionColumnType,
          cell.column.hrefValue,
          cell.column.hyperlinkDBCode,
          row,
          dispatch,
          optionObject,
          cell.column.modalCss
        )
      }
    >
      <DataGridToolTip width={cell.column.width} tooltipValue={concatevalue} />
    </StyledDiv>
  ) : (
    // <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    // </React.Fragment>
  );
};

AuditColumnJson.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    isRepeatedValue: PropTypes.any,
    column: PropTypes.shape({
      id: PropTypes.any,
      auditColumnName: PropTypes.any,
      isEllipsesEnable: PropTypes.any,
      onClickHandler: PropTypes.any,
      parentProps: PropTypes.any,
      actionColumnType: PropTypes.any,
      hrefValue: PropTypes.any,
      hyperlinkDBCode: PropTypes.any,
      modalCss: PropTypes.any,
      width: PropTypes.any,
    }),
  }),
  
  row: PropTypes.any, 
  
  column: PropTypes.shape({
    id: PropTypes.any,
    auditColumnName: PropTypes.any,
    isEllipsesEnable: PropTypes.any,
    onClickHandler: PropTypes.any,
    parentProps: PropTypes.any,
    actionColumnType: PropTypes.any,
    hrefValue: PropTypes.any,
    hyperlinkDBCode: PropTypes.any,
    modalCss: PropTypes.any,
    width: PropTypes.any,
  }),
};

export default React.memo(AuditColumnJson);
