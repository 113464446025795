import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { useState } from 'react';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import LanguageLink from './LanguageLink';
import { useNavigate } from 'react-router';


const ContainerDiv = styled(Container)`
  height: 50px;
  width: 100%;
  background: #004269;
  display: flex;
  margin-bottom: 2px;
  max-width: 100%;
`;

const InnerContainer = styled.div`
  height: 50px;
  width: 100%;
  background: #004269;
  display: flex;
  font-size: '25px' !important;
  margin: '10px' !important;
  float: none;
`;
const LinksContainer = styled.div`
  height: 50px;
  width: 100%;
  background: #004269;
  display: flex;
  float: right;
`;
const Title = styled.a`
  color: white !important;
  margin: 0;
  padding: 12px;
  text-align: left;
  font-size: 20px;
  display: inline-block;
`;

const StyledLanguageLink = styled(LanguageLink)`
  color: white !important;
  margin: 2px;
  padding-top: 7px;
  padding-right: 15px;
  text-align: right;
  font-size: 16px;
  display: inline-block;
`;

interface ICommonHeader {
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
  setLanguage: any;
  isLanguageRequire?: boolean;
}
const CustomContainer = styled(Container)`
  background-color: #dc3545;
`;

const CommonHeader: React.FC<ICommonHeader> = (props: ICommonHeader) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLanguageRequire, setisLanguageRequire] = useState(false);
  const navigate = useNavigate();
  const handleClick = async (type: string) => {
    navigate('/' + type);
  };
  return (
    <ContainerDiv className="col-md-12">
      <InnerContainer>
        <div>
          <a
            onClick={() => handleClick('')}
            role='presentation'
            style={{
              height: '100%',
              margin: '10px',
              display: 'inline-block',
              color: '#fff',
            }}
          >
            Info QA
          </a>
        </div>
      </InnerContainer>
      {isLanguageRequire ? (
        <LinksContainer>
          <StyledLanguageLink {...props} setLanguage={props.setLanguage} />
        </LinksContainer>
      ) : (
        ''
      )}
    </ContainerDiv>
  );
};
export default injectIntl(CommonHeader);
