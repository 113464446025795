const FORGOT_PASSWORD = 'फॉरगॉट  पासवर्ड';
const PWD = 'पासवर्ड*';
const RESET_PASSWORD = 'रिसेट पासवर्ड';
export default {
  header: {
    logo: 'इन्फो बीआई',
    login: 'लॉगिन',
    signup: 'साइन अप',
  },
  signin: {
    signin: 'लॉगिन',
    signup: 'साइन अप',
    forgotpassword: FORGOT_PASSWORD,
    login: 'लॉगिन',
    email: 'ईमेल*',
    password: PWD,
    inlineMsgEmail: 'ईमेल जरुरी है',
    inlineMsgPwd: 'पासवर्ड की आवश्यकता है',
  },
  signup: {
    headSignup: 'साइन अप',
    email: 'ईमेल*',
    inlineMsgEmail: 'ईमेल जरुरी है',
    password: PWD,
    inlineMsgPwd: 'पासवर्ड की आवश्यकता है',
    pwdInfoMsg:
      'पासवर्ड में न्यूनतम 8 और अधिकतम 20 अक्षर होने चाहिए।' +
      'इसमें कम से कम एक अपरकेस और एक लोअरकेस वर्ण होना चाहिए।' +
      'इसमें कम से कम एक अंक होना चाहिए। इसमें कम से कम एक विशेष प्रतीक होना चाहिए।',
    buttonSignup: 'साइन अप',
    haveaccountCheckMessage: 'पहले से ही एक खाता है?',
    linkLogin: 'लॉगिन',
    confirmationMsgStart: 'दिए गए ईमेल आईडी की जाँच करें ',
    confirmationMsgEnd: ' सत्यापन कोड के लिए|',
    headConfirmation: 'साइन अप पूर्ण करें',
    floatingConfirmationCode: 'सत्यापन कोड*',
    buttonConfirmSignup: 'जमा करें',
    errMsgEmail: 'अमान्य ईमेल',
    resend: 'सत्यापन कोड पुन: भेजें',
  },
  resetPwd: {
    forgotPassword: 'फॉरगॉट पासवर्ड ',
    email: 'ईमेल*',
    pwdInfoMsg:
      'पासवर्ड में न्यूनतम 8 और अधिकतम 20 अक्षर होने चाहिए।' +
      'इसमें कम से कम एक अपरकेस और एक लोअरकेस वर्ण होना चाहिए।' +
      'इसमें कम से कम एक अंक होना चाहिए। इसमें कम से कम एक विशेष प्रतीक होना चाहिए।',
    sendResetPasscode: 'सेंड रिसेट पासकोड ',
    backToLogin: 'लॉगिन पर वापस जाएं',
    resetpassword: RESET_PASSWORD,
    newPassword: 'नया पासवर्ड*',
    passcode: 'पासकोड*',
    btnResetPassword: 'रिसेट पासवर्ड',
    resend: 'रिसेंड',
    inlineMsgEmail: 'ईमेल जरुरी है',
    newPwdIsRequired: 'नया पासवर्ड आवश्यक है',
    inlineMsgAuth: 'औथ कोड आवश्यक है',
    resetPwdMsgStart: 'दिए गए ईमेल आईडी की जाँच करें ',
    resetPwdMsgEnd: ' पासकोड के लिए|',
  },
  headerForSignIn: {
    title: 'इन्फो बीआई',
  },
  changePassword: {
    title: 'पासवर्ड बदलें',
    oldPassword: 'पुराना पासवर्ड*',
    inlineMsgOldPwd: 'पुराना पासवर्ड आवश्यक है',
    newPassword: 'नया पासवर्ड*',
    inlineMsgNewPwd: 'नया पासवर्ड आवश्यक है',
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये*',
    inlineMsgConfirm: 'पासवर्ड की पुष्टि की आवश्यकता है',
    btnChangePassword: 'पासवर्ड बदलें',
    passwordInfoMsg:
      'पासवर्ड में न्यूनतम 8 और अधिकतम 20 अक्षर होने चाहिए।' +
      'इसमें कम से कम एक अपरकेस और एक लोअरकेस वर्ण होना चाहिए।' +
      'इसमें कम से कम एक अंक होना चाहिए। इसमें कम से कम एक विशेष प्रतीक होना चाहिए।',
    changePasswordStart: 'उपयोगकर्ता का पासवर्ड सत्र समाप्त हो गया',
  },
  tooltipMesg: {
    message1: 'पासवर्ड में न्यूनतम 8 और अधिकतम 20 अक्षर होने चाहिए।',
    message2: 'इसमें कम से कम एक अपरकेस  वर्ण होना चाहिए।',
    message3: 'इसमें कम से कम एक लोअरकेस वर्ण होना चाहिए।',
    message4: 'इसमें कम से कम एक अंक होना चाहिए।',
    message5: 'इसमें कम से कम एक विशेष प्रतीक होना चाहिए।',
  },
};
