import React from 'react';
import { DateTimePicker, Button } from 'info-ui-library';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
const StyledButton = styled(Button)`
  &.btn {
    position: relative;
    top: 4px;
  }
`;
function DateTimePickerFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    styleName,
    ioMode,
    ioTheme,
    dateFormat,
    width,
  },
}) {
  // const count = preFilteredRows ? preFilteredRows.length : 0;
  const [value, setValue] = React.useState(undefined);
  // const today = moment().format('YYYY-MM-DD');
  const onChange = (e) => {
    if (e) {
      let localToUtc = e ? moment.utc(e).format('YYYY-MM-DD HH:mm') : null;
      setFilter(localToUtc);
    } else {
      setFilter(undefined);
    }
    setValue(e);
  };

  // const initialvalue =
  //   value && Object.prototype.toString.call(value) === '[object Date]'
  //     ? value
  //     : value
  //       ? new Date(today + ' ' + value).toISOString()
  //       : null;
  let utcToLocalDate = value ? moment.utc(value).local().format() : null;

  let newFormat = dateFormat ? dateFormat.replaceAll('D', 'd') : dateFormat;
  newFormat = newFormat ? newFormat.replaceAll('A', 'a') : newFormat;
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <DateTimePicker
        placeholderText={'Please select Date & Time'}
        styleName={styleName}
        ioTheme={ioTheme}
        ioMode={ioMode}
        label={''}
        autoComplete="off"
        displayType={'SingleLine'}
        showTimeSelect={true}
        timeIntervals={15}
        dateFormat={newFormat}
        onChange={(e) => onChange(e)}
        withPortal
        widthCell={width}
        selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
        isInlineGridComponent={true}
        className={'inlineGrid'}
      />
      {value ? (
        <StyledButton
          onClick={() => onChange(undefined)}
          disabled={false}
          styleName={styleName}
          ioTheme={ioTheme}
          ioMode={ioMode}
          className="fa fa-times-circle"
          name={''}
          title={'Clear Filter'}
        />
      ) : (
        ''
      )}
    </div>
  );
}

DateTimePickerFilter.propTypes = {
  column: PropTypes.any,
  'column.filterValue': PropTypes.any,
  'column.preFilteredRows': PropTypes.any,
  'column.setFilter': PropTypes.any,
  'column.styleName': PropTypes.any,
  'column.ioMode': PropTypes.any,
  'column.ioTheme': PropTypes.any,
  'column.dateFormat': PropTypes.any,
  'column.width': PropTypes.any,
  'column.preFilteredRows.length': PropTypes.any,
  'column.dateFormat.replaceAll': PropTypes.any
};

export default DateTimePickerFilter;
