import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  setEditableGridStatus,
  DOWNLOAD_GRID_REPORT,
  GET_GRID_COLUMN_OPTIONS,
  SET_FILTER_GRID_STATUS,
  GET_GRID_DATA,
  setGridData,
  // setGridPageIndex,
  DOWNLOAD_REPORT,
  GET_PORTAL_GRID_SELECTED_ROW_DATA,
  setPortalGridSelectedRowData,
} from '../../actions';
import { UpsertMessage, CardType } from '../../constants/appeng.enum';
import ReportOptions from '../../models/report.options.model';
import { cloneDeep } from '@apollo/client/utilities';
// import cloneDeep from 'lodash'
const getCard = (state: any) => state.appState.cardsData;
const getGridIndexes = (state: any) => state.appState.gridIndexes;

export default function* gridSaga() {
  yield all([
    fork(watchGetGridOptions),
    fork(watchGetGridData),
    fork(watchSetGridFilterStatus),
    fork(watchDownlaodGridReport),
    fork(watchDownlaodReport),
    fork(watchGetPortalGridSelectedRowData),
  ]);
}

function* watchGetGridOptions() {
  yield takeLatest(GET_GRID_COLUMN_OPTIONS, getGridOptions);
}

function* watchGetGridData() {
  yield takeEvery(GET_GRID_DATA, getGridDataSaga);
}

function* watchSetGridFilterStatus() {
  yield takeEvery(SET_FILTER_GRID_STATUS, setGridFilterStatus);
}

function* watchDownlaodGridReport() {
  yield takeEvery(DOWNLOAD_GRID_REPORT, downloadGridReport);
}

function* watchDownlaodReport() {
  yield takeEvery(DOWNLOAD_REPORT, downloadReport);
}

function* watchGetPortalGridSelectedRowData() {
  yield takeEvery(
    GET_PORTAL_GRID_SELECTED_ROW_DATA,
    getPortalGridSelectedRowDataSaga
  );
}

function* getGridOptions(action: any): Generator<any, any, any> {
  try {
    let existingCardData = yield select(getCard);
    const id = action.parentFormId ? action.parentFormId : action.formId;
    let formData = existingCardData[action.referralId].data;
    let existingOptions = { ...existingCardData[action.referralId].options };
    let mergeData = { ...formData[0] };
    if (action && action.referenceData) {
      for (let [key, value] of Object.entries(formData[0])) {
        for (let [innerKey, innerValue] of Object.entries(
          action.referenceData
        )) {
          if (key === innerKey) {
            mergeData[innerKey] = innerValue;
          }
        }
      }
    }
    const selectedData =
      action.parentFormId && action.parentFormId !== action.formId
        ? formData[0].child[action.formId][action.occuranceNumber]
        : mergeData;
    let data: any = {};
    data['formData'] = selectedData;
    data['configIds'] = action.formFieldConfiData;
    const optionsData = yield call(
      base.getAllOptionsData,
      data,
      id,
      action.occuranceNumber
    );
    Object.assign(existingOptions, optionsData);

    if (optionsData.errors && optionsData.code === 512) {
      const messages: string[] = [];
      messages.push(optionsData.errors);
      yield put(setToggleMessages(messages));
    } else {
      {
        let updatedStoreCardData: any = yield select(getCard);
        if (updatedStoreCardData[action.referralId]) {
          const cardData: any = new CardData(
            action.referralId,
            updatedStoreCardData[action.referralId].type,
            updatedStoreCardData[action.referralId].data,
            updatedStoreCardData[action.referralId].portalId,
            updatedStoreCardData[action.referralId].parentId,
            updatedStoreCardData[action.referralId].referenceData,
            updatedStoreCardData[action.referralId].errorData,
            optionsData,
            updatedStoreCardData[action.referralId].datagridErrorData,
            updatedStoreCardData[action.referralId].toggleTabRefresh,
            updatedStoreCardData[action.referralId].editableGrid,
            updatedStoreCardData[action.referralId].workflowActions,
            updatedStoreCardData[action.referralId].tabGroupId,
            updatedStoreCardData[action.referralId].entityName,
            updatedStoreCardData[action.referralId].tabPortalId,
            updatedStoreCardData[action.referralId].entityId,
            updatedStoreCardData[action.referralId].parentCardId,
            updatedStoreCardData[action.referralId].enableFilterInGrid,
            updatedStoreCardData[action.referralId].buttonClicked,
            updatedStoreCardData[action.referralId].isPreprocessorRunning,
            updatedStoreCardData[action.referralId].isAttachTypeApplicable,
            updatedStoreCardData[action.referralId].paginatedDetails
          );
          let resetForms = true;
          if (action.cardId) {
            resetForms = false;
          }
          if (action.referralId) {
            resetForms = false;
          }
          yield put(
            setFormData(cardData, action.referralId, resetForms, false, false)
          );
        }
      }
    }
  } catch (e) {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* getGridDataSaga(action: any): Generator<any, any, any> {
  try {

    let existingCardData = yield select(getCard);
   
    if(action.data && action.data.advanceQueryString !== undefined){
      const updateCardData= cloneDeep(existingCardData[action.referralId]);
      updateCardData.buttonClicked='advanceQueryString';
      yield put(setGridData(updateCardData, false, true));
    }


    const { data } = yield call(
      base.getConfigData,
      'LogicalEntityOperation',
      action.gridId
    );
    // let existingCardData = yield select(getCard);
    let gridData: any = {};
    if (data.DataGrid.actionFlows && data.DataGrid.actionFlows.length > 0) {
      let reqData: any = {};
      reqData['data'] = action.data.filterMap
        ? { ...action.data.filterMap, ...action.data }
        : action.data;
      let res: any = yield call(
        base.executeActionFlow,
        data.DataGrid.actionFlows[0].configObjectId,
        reqData
      );
      gridData = res.gridData;
    } else if (
      data.DataGrid.logicalEntityOperations &&
      data.DataGrid.logicalEntityOperations.length > 0
    ) {
      gridData = yield call(
        base.getGridData,
        action.gridId,
        action.data.filterMap
          ? { ...action.data.filterMap, ...action.data }
          : action.data,
        data.DataGrid.isServerPaginationEnable,
        action.pageIndex,
        action.pageSize
      );
    } else if (
      existingCardData[action.parentId] &&
      existingCardData[action.parentId].referenceData &&
      existingCardData[action.parentId].referenceData.data
    ) {
      gridData = existingCardData[action.parentId].referenceData.data;
    }
    if (gridData.errors) {
      const messages: string[] = [];
      messages.push(gridData.errors);
      yield put(setToggleMessages(messages));
    } else {
      let cardData;
      let referenceData = existingCardData[action.referralId]
        ? existingCardData[action.referralId].referenceData
        : {};
      if (action.data && action.data.resetReferenceData) {
        referenceData = action.data;
      } else {
        Object.assign(referenceData, action.data);
      }
      const parentReferenceData = existingCardData[action.parentId]
        ? existingCardData[action.parentId].referenceData
        : {};

      if (action.tabRefresh && Object.keys(parentReferenceData).length > 0) {
        let cardData = {
          ...existingCardData[action.parentId],
          referenceData: {},
          toggleTabRefresh: action.tabRefresh,
        };
        yield put(setGridData(cardData, false, true));
      }
      const options = existingCardData[action.referralId]
        ? existingCardData[action.referralId].options
        : {};
      const datagridErrorData = existingCardData[action.referralId]
        ? existingCardData[action.referralId].datagridErrorData
        : {};
      const toggleTabRefresh = existingCardData[action.referralId]
        ? existingCardData[action.referralId].toggleTabRefresh
        : false;
      const editableGrid = existingCardData[action.referralId]
        ? existingCardData[action.referralId].editableGrid
        : false;
      const workflowActions = existingCardData[action.referralId]
        ? existingCardData[action.referralId].workflowActions
        : {};
      const tabGroupId = existingCardData[action.referralId]
        ? existingCardData[action.referralId].tabGroupId
        : '';
      const entityName = existingCardData[action.referralId]
        ? existingCardData[action.referralId].entityName
        : '';
      const tabPortalId = existingCardData[action.referralId]
        ? existingCardData[action.referralId].tabPortalId
        : '';
      const entityId = existingCardData[action.referralId]
        ? existingCardData[action.referralId].entityId
        : '';
      const parentCardId = existingCardData[action.referralId]
        ? existingCardData[action.referralId].parentCardId
        : '';
      referenceData.componentType = 'DataGrid';

      if (
        action.path &&
        Object.entries(datagridErrorData).length > 0 &&
        datagridErrorData[action.path]
      ) {
        delete datagridErrorData[action.path];
      }
      const paginatedData = serverSidePaginatedData(
        gridData,
        data.DataGrid.isServerPaginationEnable
      );
      if (paginatedData.length > 0) {
        cardData = new CardData(
          action.referralId,
          CardType.DATAGRID,
          paginatedData,
          action.portalId,
          action.parentId,
          referenceData,
          {},
          options,
          datagridErrorData,
          toggleTabRefresh,
          editableGrid,
          workflowActions,
          tabGroupId,
          entityName,
          tabPortalId,
          entityId,
          parentCardId,
          null,
          null,
          null,
          null,
          gridData.headers
        );
      } else {
        cardData = new CardData(
          action.referralId,
          CardType.DATAGRID,
          [],
          action.portalId,
          action.parentId,
          referenceData,
          {},
          options,
          datagridErrorData,
          toggleTabRefresh,
          editableGrid,
          workflowActions,
          tabGroupId,
          entityName,
          tabPortalId,
          entityId,
          parentCardId
        );
      }

      yield put(setGridData(cardData, action.resetOtherGrids, true));
      let existingGridIndexes = yield select(getGridIndexes);
      if (
        existingGridIndexes &&
        existingGridIndexes[action.referralId] === undefined
      ) {
      }
    }
  } catch (e) {
    console.log('getGridDataSaga===========', e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* setGridFilterStatus(action: any): Generator<any, any, any> {
  let existingCardData = yield select(getCard);
  let existingCard = existingCardData[action.cardId];
  const enableFilterInGrid = !existingCard.enableFilterInGrid;
  console.log('setGridFilterStatus=============');
  if (existingCard) {
    let cardData = new CardData(
      action.cardId,
      existingCard.type,
      existingCard.data,
      existingCard.portalId,
      existingCard.parentId,
      existingCard.referenceData,
      existingCard.errorData,
      existingCard.options,
      existingCard.datagridErrorData,
      existingCard.toggleTabRefresh,
      existingCardData.editableGrid,
      existingCardData.workflowActions,
      existingCardData.tabGroupId,
      existingCardData.entityName,
      existingCardData.tabPortalId,
      existingCardData.entityId,
      existingCardData.parentCardId,
      enableFilterInGrid
    );
    yield put(setEditableGridStatus(action.cardId, cardData));
  }
}

function* downloadGridReport(action: any): Generator<any, any, any> {
  try {
    const currentUserDetail = base.getUserDetail();
    let formData: any = {};
    formData[action.primaryDBCode] = action.primaryKey;
    Object.assign(formData, currentUserDetail);

    const reportOptions = new ReportOptions(
      action.templateName,
      '',
      formData,
      action.currentLanguage
    );
    const excelBytes = yield call(base.downloadReport, reportOptions);
    let byteArray = new Uint8Array(excelBytes);
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([byteArray], { type: 'application/octet-stream' })
    );
    a.download = action.templateName + '.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

export function* downloadReport(action: any): Generator<any, any, any> {
  try {
    if (action.formId) {
      let existingCardData = yield select(getCard);
      let formData = existingCardData[action.formId].data[0];
      if (formData.REPORT_NAME === null || formData.REPORT_NAME === undefined) {
        return;
      }
      const reportOptions = new ReportOptions(
        formData.REPORT_NAME,
        '',
        formData,
        formData.APP_CURRENT_LANGUAGE
      );
      const excelBytes = yield call(base.downloadReport, reportOptions);
      let byteArray = new Uint8Array(excelBytes);
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: 'application/octet-stream' })
      );
      a.download = formData.REPORT_NAME + '.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
    }
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* getPortalGridSelectedRowDataSaga(
  action: any
): Generator<any, any, any> {
  if (!action.usePortalReferenceData) {
    let existingCardData = yield select(getCard);
    let card = { ...existingCardData[action.cardId] };
    card.referenceData = action.data;
    yield put(setPortalGridSelectedRowData(card, action.cardId));
  } else {
    const cardData = new CardData(
      action.parentId,
      CardType.PORTAL,
      {},
      action.portalId,
      action.parentId,
      action.data
    );
    yield put(setPortalGridSelectedRowData(cardData, action.parentId));
  }
}

const serverSidePaginatedData = (
  gridData: any,
  isServerPaginationEnable: boolean
) => {
  if (isServerPaginationEnable) {
    return gridData.body;
  } else {
    return gridData;
  }
};
