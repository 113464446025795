import {
  put,
  all,
  fork,
  // takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  UPDATE_PORTAL_CARD,
  GET_OPTIONS_IN_PORTAL_CARD,
  HANDLE_PORTAL_LAYOUT_CHANGE,
  setPortalLayout,
  HANDLE_RELOAD,
} from '../../actions';
import { CardType } from '../../constants/appeng.enum';
import { v4 as uuid } from 'uuid';

const getCard = (state: any) => state.appState.cardsData;

export default function* portalAndPortalCardSaga() {
  yield all([
    fork(watchUpdatePortalCard),
    fork(watchGetOptionsInPortalCard),
    fork(watchHandlePortalLayoutChange),
    fork(watchHandleReload),
  ]);
}

function* watchUpdatePortalCard() {
  yield takeEvery(UPDATE_PORTAL_CARD, updatePortalCardSaga);
}

function* watchGetOptionsInPortalCard() {
  yield takeEvery(GET_OPTIONS_IN_PORTAL_CARD, getOptionsInPortalCard);
}

function* watchHandlePortalLayoutChange() {
  yield takeEvery(HANDLE_PORTAL_LAYOUT_CHANGE, handlePortalLayoutChange);
}

function* watchHandleReload() {
  yield takeEvery(HANDLE_RELOAD, handleReload);
}

function* updatePortalCardSaga(action: any): Generator<any, any, any> {
  const result = yield call(base.updatePortalCardData, action.params);
}

function* getOptionsInPortalCard(action: any): Generator<any, any, any> {
  const existingCardData = yield select(getCard);
  const previousCard = existingCardData[action.cardId]
    ? existingCardData[action.cardId]
    : {};
  let existingOptions = previousCard ? { ...previousCard.options } : {};
  const optionsData = yield call(
    base.getOptionsData,
    action.formfieldId,
    action.data
  );
  existingOptions[action.formfieldId] = optionsData;
  const rd = previousCard.referenceData
    ? { ...previousCard.referenceData }
    : {};
  const cardData = new CardData(
    action.cardId,
    previousCard ? previousCard.type : CardType.WIDGET,
    previousCard.data,
    previousCard.portalId,
    previousCard.parentId,
    rd,
    previousCard.errorData,
    existingOptions
  );
  yield put(setFormData(cardData, action.cardId, false, false, false));
}

function* handlePortalLayoutChange(action: any): Generator<any, any, any> {
  yield put(setPortalLayout(action.layout));
}

function* handleReload(action: any): Generator<any, any, any> {
  const existingCardData = yield select(getCard);
  let cardId = action.cardId;

  const currentCard = existingCardData[cardId];
  const rd = currentCard.referenceData ? { ...currentCard.referenceData } : {};
  Object.assign(rd, {
    AE_RELOAD: {
      ID: uuid(),
      TS: new Date().getTime(),
      STATUS: 'R',
    },
  });
  const cardData = new CardData(
    cardId,
    currentCard.type,
    currentCard.data,
    existingCardData[cardId].portalId,
    existingCardData[cardId].parentId,
    rd,
    currentCard.errorData,
    currentCard.options
  );
  yield put(setFormData(cardData, cardId, false, false, true));
}
