import React from 'react';
import moment from 'moment';
import { DateTimePicker } from 'info-ui-library';
import DataGridToolTip from './DataGridToolTip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const TimePickerCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [value, setValue] = React.useState(cell.value);
  const [isEditable, setIsEditable] = React.useState(false);

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [errorData]);

  const handleClick = () => {
    if (errorData || (errorData && errorData.path !== row.id.toString())) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  };

  const onChange = (e) => {
    let localToUtc = e ? moment.utc(e).local().format('HH:mm:ss') : null;
    setValue(localToUtc);
    if (value !== localToUtc && updateMyData) {
      updateMyData(row.index, id, localToUtc, row.id);
    }
    setIsEditable(false);
  };

  const onBlur = () => {
    setIsEditable(false);
  };

  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  // let tooltipID = 'timePickerError_' + id + '_' + row.id.toString();
  let today = moment.utc(new Date()).local().format('YYYY-MM-DD');
  const initialvalue =
    value && Object.prototype.toString.call(value) === '[object Date]'
      ? value
      : value
        ? new Date(today + ' ' + value).toISOString()
        : null;
  let utcToLocalDate = initialvalue
    ? moment.utc(initialvalue).local().format()
    : null;
  let utcToLocalDateNonEditable = initialvalue
    ? moment.utc(initialvalue).local().format(cell.column.dateFormat)
    : null;

  return cell.column.editEnable && isEditable && cell.column?.changeEnable ? (
    // <React.Fragment>
      <div
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
        style={{
          border: errorData && !utcToLocalDate ? '1px solid red' : 'none',
          width: errorData && !utcToLocalDate ? '90%' : '99%',
          borderRadius: '5px',
        }}
      >
        <DateTimePicker
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          dateFormat={'h:mm aa'}
          timeIntervals={15}
          autoComplete="off"
          onChange={(e) => onChange(e)}
          selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
          withPortal
          isInlineGridComponent={true}
          widthCell={cell.column.width}
          className={
            errorData && !utcToLocalDate ? 'inlineGridError' : 'inlineGrid'
          }
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && !utcToLocalDate ? 'rgb(255, 0, 0)' : 'none',
          }}
          id={errorData && !utcToLocalDate ? errorData.message : ''}
        />
      </div>
    // </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true &&
      value !== undefined &&
      value !== null &&
      value !== '' ? (
        <div
          role='presentation'
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
          style={{
            padding: '10px 10px',
            //width: cell.column.width + 'px', position: 'absolute',
            height: '100%',
          }}
        >
          <DataGridToolTip
            width={cell.column.width}
            tooltipValue={
              // value && utcToLocalDateNonEditable && isEditable
              //   ? utcToLocalDateNonEditable
              //   : utcToLocalDateNonEditable
              utcToLocalDateNonEditable
            }
          />
        </div>
      ) : (
        <div
          role='presentation'
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
        ></div>
      )}
    </React.Fragment>
  );
};


TimePickerCell.propTypes = {
  'column.id': PropTypes.any,
  updateMyData: PropTypes.any,
  column: PropTypes.any,
  cell: PropTypes.any,
  row: PropTypes.any,
  'cell.value': PropTypes.any,
  'cell.column.useErrorDataUsingDBCode': PropTypes.any,
  'cell.column': PropTypes.any,
  'row.id': PropTypes.any,
  'cell.column.referralId': PropTypes.any,
  'row.index': PropTypes.any,
  'cell.column.dateFormat': PropTypes.any,
  'cell.column.changeEnable': PropTypes.any,
  'cell.column.editEnable': PropTypes.any,
  'cell.column.styleName': PropTypes.any,
  'cell.column.ioTheme': PropTypes.any,
  'cell.column.ioMode': PropTypes.any,
  'cell.column.width': PropTypes.any,
  'cell.isRepeatedValue': PropTypes.any
};

export default React.memo(TimePickerCell);
