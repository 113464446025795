import {
  AuthProviderEnum,
  parseAuthProviderEnum,
} from '../constants/provider.enum';
import { refreshAwsToken } from './aws.refresh.token';
import { refreshMicrosoftAdToken } from './microsoft-ad.refresh.token';
import { refreshOktaToken } from './okta.refresh.token';

const refreshToken = async (config: any) => {
  const providerEnum = parseAuthProviderEnum(config.provider.type);
  switch (providerEnum) {
    case AuthProviderEnum.AWS:
      await refreshAwsToken(config);
      break;
    case AuthProviderEnum.MICROSOFT_AD_INFO_APPS:
    case AuthProviderEnum.MICROSOFT_AD:
      await refreshMicrosoftAdToken(config);
      break;
    case AuthProviderEnum.OKTA:
      await refreshOktaToken(config);
      break;
  }
};

export default refreshToken;
