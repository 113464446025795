import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const DownloadFile = () => {
  const { fileName } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (fileName) {
      console.log('File to download:', fileName);

      // Construct the file URL dynamically
      const fileUrl = `${window.location.origin}/files/${encodeURIComponent(fileName)}.zip`;

      console.log('File URL:', fileUrl);

      // Create an invisible link and trigger a download
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName); // Suggests a filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Wait a moment to ensure download starts, then navigate
      setTimeout(() => {
        navigate('/'); // Redirect to home page
      }, 2000); // Adjust timing as needed
    }
  }, [fileName]); // Add `fileName` as a dependency

  return <p>Downloading file...</p>; // Display a message instead of an empty return
};

export default DownloadFile;
