import React, { useEffect, useRef, useState } from 'react';
import { createStorage } from '../../../storage';
import {
  ROUTE_STATE_LOCAL_STORAGE_KEY,
  PATHNAME,
} from '../../../constants/appeng.enum';
import SubPortalGenerator from '../../../new-portal-layout/SubPortalGenerator';

const NestedComponent = (props) => {
  const storage = createStorage();
  const parentRef = useRef(null);
  const routerParameter = useRef({}).current;
  const [initialWidth, setInitialWidth] = useState(null); // Store initial window width

  // Set the width dynamically on mount and window resize
  const setWidth = () => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      const windowWidth = window.innerWidth;

      if (windowWidth !== initialWidth) { // Only recalculate if window width changes
        if (parentWidth > windowWidth) {
          const reducedWindowWidth = windowWidth - windowWidth * 0.019;
          parentRef.current.style.width = `${reducedWindowWidth}px`;
          parentRef.current.style.overflowX = 'hidden';
        } else {
          const reducedWindowWidth = parentWidth - parentWidth * 0.019;
          parentRef.current.style.width = `${reducedWindowWidth}px`;
          parentRef.current.style.overflowX = 'hidden';
        }
        setInitialWidth(windowWidth); // Update the stored window width
      }
    }
  };

  useEffect(() => {
    setWidth();
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, [initialWidth]);

  // Handling location state and router parameters
  if (props.location && props.location.state) {
    storage.setItem(
      ROUTE_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(props.location.state)
    );
    Object.assign(routerParameter, { state: props.location.state });
  } else {
    const routeState = storage.getItem(ROUTE_STATE_LOCAL_STORAGE_KEY);
    props.location.state = routeState ? JSON.parse(routeState) : {};
    Object.assign(routerParameter, { state: props.location.state });
  }

  routerParameter[PATHNAME] = props.location.pathname;

  let rowData = props.row.original;
  rowData['ACTION_TYPE'] = props.row.nestedComponentType
    ? props.row.nestedComponentType
    : props.row.original['ACTION_TYPE'];

  let referenceData = {
    data: rowData,
    primaryDBCode: props.pkDBCode,
    AE_RELOAD: props.referenceData.AE_RELOAD,
  };

  Object.assign(routerParameter, { referenceData: referenceData });
  let pk = props.row.original[props.pkDBCode];

  let subPortals;
  if (props.portalGrid.subPortals) {
    subPortals = (
      <SubPortalGenerator
        routerParameter={{ ...routerParameter, referenceData }}
        subPortals={props.portalGrid.subPortals}
        subComponentParentId={pk + '_' + props.datagrid.configObjectId + '_'}
        parentCardId={props.referralId}
      />
    );
  } else {
    subPortals = <div>No View attached to Data Table</div>;
  }

  return (
    <div
      ref={parentRef} // Reference to the parent container
      style={{
        alignItems: 'stretch',
      }}
    >
      {subPortals}
    </div>
  );
};

export default NestedComponent;
