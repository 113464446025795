const FORGOT_PASSWORD = 'Forgot Password';
const PWD = 'Password*';
const RESET_PASSWORD = 'Reset Password';
export default {
  header: {
    logo: 'Info BI',
    login: 'Login',
    signup: 'Sign Up',
  },
  signin: {
    signin: 'Sign In',
    signup: 'Sign Up',
    forgotpassword: FORGOT_PASSWORD,
    login: 'Login',
    email: 'Email*',
    password: PWD,
    inlineMsgEmail: 'Email is required',
    inlineMsgPwd: 'Password is required',
  },
  signup: {
    headSignup: 'Sign Up',
    email: 'Email*',
    inlineMsgEmail: 'Email is required',
    password: PWD,
    inlineMsgPwd: 'Password is required',
    pwdInfoMsg:
      'Password must contain minimum 8 and maximum 20 letters.' +
      'It must contain atleast one uppercase and one lowercase character.' +
      'It must contain atleast one digit. It must contain atleast one special symbol.',
    buttonSignup: 'Sign Up',
    haveaccountCheckMessage: 'Already have an account?',
    linkLogin: 'Login',
    confirmationMsgStart: 'Check Email ',
    confirmationMsgEnd: 'for verification code.',
    headConfirmation: 'Complete Sign Up',
    floatingConfirmationCode: 'Verification Code*',
    buttonConfirmSignup: 'Submit',
    errMsgEmail: 'Invalid Email',
    resend: 'Resend Verification Code',
  },
  resetPwd: {
    forgotPassword: 'Forgot Password',
    email: 'Email*',
    pwdInfoMsg:
      'Password must contain minimum 8 and maximum 20 letters.' +
      'It must contain atleast one uppercase and one lowercase character.' +
      'It must contain atleast one digit. It must contain atleast one special symbol.',
    sendResetPasscode: 'Send Reset Passcode',
    backToLogin: 'Back to Login',
    resetpassword: RESET_PASSWORD,
    newPassword: 'New Password*',
    passcode: 'Passcode*',
    btnResetPassword: 'Reset Password',
    resend: 'Resend',
    inlineMsgEmail: 'Email is required',
    newPwdIsRequired: 'New Password is required',
    inlineMsgAuth: 'Auth Code is required',
    resetPwdMsgStart: 'Check Email ',
    resetPwdMsgEnd: ' for Passcode.',
  },
  headerForSignIn: {
    title: 'Info BI',
  },
  changePassword: {
    title: 'Change Password',
    oldPassword: 'Old Password*',
    inlineMsgOldPwd: 'Old Password is required',
    newPassword: 'New Password*',
    inlineMsgNewPwd: 'New Password is required',
    confirmPassword: 'Confirm Password*',
    inlineMsgConfirm: 'Confirm Password is required',
    btnChangePassword: 'Change Password',
    passwordInfoMsg:
      'Password must contain minimum 8 and maximum 20 letters.' +
      'It must contain atleast one uppercase and one lowercase character.' +
      'It must contain atleast one digit. It must contain atleast one special symbol.',
    changePasswordStart: '    Password is Expired    ',
  },
  tooltipMesg: {
    message1: 'Password must contain minimum 8 and maximum 20 letters.',
    message2: 'It must contain atleast one uppercase character.',
    message3: 'It must contain atleast one lowercase character.',
    message4: 'It must contain atleast one digit.',
    message5: 'It must contain atleast one special symbol.',
  },
};
