import React, { useContext } from 'react';
import { FormErrorContainers } from 'info-ui-library';
import { useSelector } from 'react-redux';
import UIBasicTableGenerator from '../../info-data-table/components/BasicDataTable';
import {
  GridType,
  QueryType,
  INSERT_MODE,
  EDIT_MODE,
} from '../../constants/appeng.enum';
import SkeletonCore from '../../components/SkeletonCore';
import { useConfigQuery } from '../../custom-hooks/useConfigQuery';
import { ThemeContext } from '../../theme/ThemeContext';
import { useFetchAppGridData } from '../../custom-hooks/useFetchAppGridData';
import { getPrimaryDBCode, getFormId } from '../../utils/ConfigUtils';
import { IChildUitable } from '../interfaces/index';
import { useLocation } from 'react-router';

const ChildUITable: React.FC<IChildUitable> = (props: IChildUitable) => {
  const themeContext = useContext(ThemeContext);
  const currentLocation = useLocation();

  const { loading,  data } = useConfigQuery(
    // const { loading, error, data } = useConfigQuery(
    QueryType.COMPOSITE_ENTITY_NODE_QUERY,
    props.routerParameter.nodeId
  );
  const { loadingApp, appData } = useFetchAppGridData(
    props.routerParameter.gridId,
    props.routerParameter.data,
    props.routerParameter.pathname,
    props.routerParameter.nodeId,
    props.routerParameter.ceId,
    props.referralId ? props.referralId : props.routerParameter.gridId,
    false,
    false,
    props.parentId,
    props.portalId,
    props.routerParameter.AE_RELOAD
  );

  const portalDetails = useSelector(
    (state: any) => state.appState.portalDetails
  );

  if (loading || loadingApp || portalDetails.loading)
    return <SkeletonCore count={1} />;

  try {
    const primaryDBCode = getPrimaryDBCode(
      data.CompositeEntityNode.entity.logicalColumns
    );
    const insertFormId = getFormId(data.CompositeEntityNode, INSERT_MODE);
    const editFormId = getFormId(data.CompositeEntityNode, EDIT_MODE);
    const newProps = {
      ...props,
      datagrid: data.CompositeEntityNode.dataGrid,
      entityName: data.CompositeEntityNode.entity.name,
      entityId: data.CompositeEntityNode.entity.configObjectId,
      nodeId: data.CompositeEntityNode.configObjectId,
      gridType: GridType.CHILD,
      gridData: appData,
      primaryDBCode: primaryDBCode,
      insertFormId: insertFormId,
      editFormId: editFormId,
      referralId: props.referralId,
      location: currentLocation,
    };
    return <UIBasicTableGenerator {...newProps} />;
  } catch {
    const error = ['Error occurred in TextBox'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default ChildUITable;
