import DefaultColumnFilter from './filterGridComponents/DefaultColumnFilter';
import { fuzzyTextFilterFn } from './filterGridComponents/FilterType';
// import DefaultCell from './editableGridComponents/DefaultCell';
import MobileViewCell from './editableGridComponents/MobileViewCell';
export const deleteActionHandler = (e) => { };

export const viewEditActionHandler = (e) => { };

export const filterTypes = {
  fuzzyText: fuzzyTextFilterFn,
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
};


const customSortFunction = (rowA, rowB, columnId, isAsc) => {
  let valueA = rowA.values[columnId]||"";
  let valueB = rowB.values[columnId]||"";
  const isANewRow = rowA.original.expander === 'NewRow';
  const isBNewRow = rowB.original.expander === 'NewRow';
  if (isANewRow && !isBNewRow) return isAsc ? 1 : -1;
  if (!isANewRow && isBNewRow) return isAsc ? -1 : 1;
  if (valueA > valueB) return 1;
  if (valueA < valueB) return -1;
  return 0;
};


export const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: MobileViewCell,
  sortType: customSortFunction,
};

