import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { UserContext } from '../../user-context/UserContext';
import { generateFormFieldCards } from './CardGenerators';
import { IFormFieldGenerator } from '../interfaces/index';
import { useSelector } from 'react-redux';
import { IState } from '../../store/index';
import { ThemeContext } from '../../theme/ThemeContext';
import { FormErrorContainers } from 'info-ui-library';
 
const arrangeFFInRowsAndCols = (formFieldComponent: any, componentPerRow: any) => {
    let separateElements: any = [];
    let rowList: any = [];
    let colList: any = [];
    const componentsToDisplay = formFieldComponent.filter((ff: any) => ff !== undefined && ff !== 'No ff available')
    let counterColSpan = 0;
    let setLayout = componentPerRow === 1 ? 12 : componentPerRow === 3 ? 4 : componentPerRow === 2 ? 6 : componentPerRow === 4 ? 3 : componentPerRow === 5 || componentPerRow === 6 ? 2 : 12; //NOSONAR
    for (let i = 0; i < componentsToDisplay.length; i++) {
        if (parseInt(componentsToDisplay[i].props.configProp.colspan) === 1
            || parseInt(componentsToDisplay[i].props.configProp.colspan) === 0
            || !componentsToDisplay[i].props.configProp.colspan) {
            colList.push(componentsToDisplay[i]);
            counterColSpan++;
        } else if (parseInt(componentsToDisplay[i].props.configProp.colspan) === parseInt(componentPerRow)) {
            colList.push(componentsToDisplay[i]);
            rowList.push(colList);
            colList = [];
            counterColSpan = 0;
            continue;
        } else if (1 < parseInt(componentsToDisplay[i].props.configProp.colspan) < componentPerRow) {
            colList.push(componentsToDisplay[i]);
            counterColSpan = counterColSpan + parseInt(componentsToDisplay[i].props.configProp.colspan);
        }
        if (colList.length === parseInt(componentPerRow) && counterColSpan === parseInt(componentPerRow)) {
            rowList.push(colList);
            colList = [];
            counterColSpan = 0;
            continue;
        } else if (counterColSpan > parseInt(componentPerRow)) {
            rowList.push(colList.slice(0, colList.length - 1));
            colList = [];
            colList.push(componentsToDisplay[i]);
            counterColSpan = parseInt(componentsToDisplay[i].props.configProp.colspan);
            continue;
        }
        else if (colList.length !== parseInt(componentPerRow) && counterColSpan === parseInt(componentPerRow)) {
            rowList.push(colList);
            colList = [];
            counterColSpan = 0;
            continue;
        }
    }
    rowList.push(colList);
    let countKeyForRow = 0;
    let countKeyForColumn = 0;
    separateElements.push(rowList.map((row: any,index:any) => {
        countKeyForRow = countKeyForRow + 1;
        return <Row key={countKeyForRow+index}>
            {row.map((col: any,colIndex:any) => {
                countKeyForColumn = countKeyForColumn + 1;
                if (parseInt(col.props.configProp.colspan) === 1
                    || parseInt(col.props.configProp.colspan) === 0
                    || !col.props.configProp.colspan) {
                    return (<Col lg={setLayout} md={setLayout} sm={12} xs={12} key={"col1"+colIndex}>{col}</Col>)
                } else if (parseInt(col.props.configProp.colspan) === parseInt(componentPerRow)) {
                    return (<Col lg={12} md={12} sm={12} xs={12}  key={"col2"+colIndex}>{col}</Col>)
                } else if (1 < parseInt(col.props.configProp.colspan) < componentPerRow) {
                    let extractPosition = [1, 2, 4].includes(componentPerRow) ? 3 : 4;
                    return (<Col md={setLayout + extractPosition} sm={setLayout + 3} xs={12}  key={"col3"+colIndex}>{col}</Col>)
                }
                return null;
            })}
 
        </Row>
     
    })
  );
  return separateElements;
};
 
const FormFieldGenerator: React.FC<IFormFieldGenerator> = (props) => {
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);
 
  const formDataObject = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
 
  try {
    let formData;
    if (props.parentFormId && formDataObject) {
      formData =
        formDataObject.data[0].child[props.formId][props.occuranceNumber];
    } else if (formDataObject) {
      formData = formDataObject.data[0];
    }
    const formFieldsCard = generateFormFieldCards(
      props.configProp,
      props.formId,
      props.parentFormId,
      props.mode,
      props.occuranceNumber,
      props.nodeId,
      props.privilegeFS,
      userContext,
      formData,
      props.isEditableInFS,
      props.isAccessibleInFs,
      props.parentEntityId,
      props.childEntityId,
      props.referralId
    );
 
    const formFieldRows = arrangeFFInRowsAndCols(
      formFieldsCard,
      props.componentperrow
    );
    return <React.Fragment>{formFieldRows}</React.Fragment>;
  } catch (err) {
    const error = ['Error occurred in Form Field Generator'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};
 
export default React.memo(FormFieldGenerator);