import {
  // call,
  put,
  // takeEvery,
  all,
  // fork,
  select,
  // takeLatest,
} from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import * as base from '../base';
import { CardType, NodeType, UpsertMessage } from '../../constants/appeng.enum';
import {
  addModalReload,
  closeModals,
  getFormData,
  getFormDataUsingFormConfig,
  getGridData,
  setChartData,
  setFormData,
  setOpenModal,
  // setStore,
  setToggleMessages,
} from '../../actions';
import CardData from '../../models/carddata.model';

import { router } from '../../routes/Routes';
import { v4 as uuid } from 'uuid';
import DummyNode from '../../composite-entity/form/dummyNodeConfigStructure';
const getOpenModals = (state: any) => state.appState.openModals;
const getCard = (state: any) => state.appState.cardsData;

export function* gateWayForActionFlowOperation(
  response: any,
  action: any,
  data: any,
  flowType: any
): Generator<any, any, any> {
  if (flowType === 'CompositeEntity') {
    const existingOpenedModals = yield select(getOpenModals);
    const node = cloneDeep(data.CompositeEntityNode);
    const formId = base.getFormId(node, action.mode);
    const messages: string[] = [];
    if (
      response.mode === 'Insert' &&
      Object.keys(existingOpenedModals).length === 0
    ) {
      messages.push(
        response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
      );
      if (action.modalRequired) {
        const existingCardData = yield select(getCard);
        yield put(closeModals(0, formId));
        yield put(
          getGridData(
            node.dataGrid.configObjectId,
            action.referenceData ? action.referenceData : {},
            action.referralId,
            true,
            existingCardData[action.referralId].parentId,
            existingCardData[action.referralId].portalId
          )
        );
      }
      if (!action.modalRequired && !action.portalForm) {
        if (action.parentId) {
          const layoutData = {
            nodeId: action.nodeId,
            ceId: action.ceId,
            componentType:
              action.nodeType === NodeType.PARENTNODE
                ? 'ParentGrid'
                : 'ChildGrid',
            gridId: node.dataGrid.configObjectId,
            data: action.referenceData,
            referenceData: action.referenceData,
          };
          const existingCardData = yield select(getCard);
          const formData = existingCardData[action.referralId].data;
          if (!action.usePortalReferenceData) {
            // const cardData = new CardData(
            //   action.referralId,
            //   CardType.FORM,
            //   formData,
            //   action.portalId,
            //   action.parentId,
            //   undefined,
            //   {},
            //   existingCardData[action.referralId].options
            // );

            const routerParam: any = {};
            Object.assign(routerParam, action.location.state);
            routerParam['portalId'] = action.portalId
              ? action.portalId
              : action.parentId;
            routerParam['parentId'] = action.parentId;
            routerParam['referralId'] = action.referralId;
            routerParam[action.referralId] = {
              AE_RELOAD: {
                ID: uuid(),
                TS: new Date().getTime(),
                STATUS: 'R',
              },
              resetReferenceData: true,
            };

            router.navigate(-1);
          } else {
            // const cardData = new CardData(
            //   action.referralId,
            //   CardType.FORM,
            //   formData,
            //   action.portalId,
            //   action.parentId,
            //   layoutData,
            //   {},
            //   existingCardData[action.referralId].options
            // );
            const previousData = existingCardData[action.parentId]
              ? existingCardData[action.parentId].referenceData
              : {};
            Object.assign(previousData, layoutData);
            // const portalData = new CardData(
            //   action.parentId,
            //   CardType.PORTAL,
            //   {},
            //   action.portalId,
            //   action.parentId,
            //   previousData
            // );

            const routerParam: any = {};
            Object.assign(routerParam, action.location.state);
            routerParam['portalId'] = action.portalId
              ? action.portalId
              : action.parentId;
            routerParam['parentId'] = action.parentId;
            routerParam['referralId'] = action.referralId;
            routerParam[action.referralId] = layoutData;
            routerParam[action.parentId] = previousData;
            Object.assign(routerParam[action.parentId], {
              resetReferenceData: true,
            });
            yield router.navigate('/app/ce/portal/' + uuid(), {
              state: routerParam,
            });
          }
        } else {
          const routerParam: any = {};
          Object.assign(routerParam, action.location.state);
          routerParam['portalId'] = action.portalId
            ? action.portalId
            : action.parentId;
          routerParam['parentId'] = action.parentId;
          routerParam['referralId'] = action.referralId;
          routerParam[action.referralId] = {
            AE_RELOAD: {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            },
            resetReferenceData: true,
          };
          yield router.navigate('/app/ce/portal/' + uuid(), {
            state: routerParam,
          });
        }
      } else if (
        action.portalForm &&
        Object.keys(existingOpenedModals).length === 0
      ) {
        let existingCardData = yield select(getCard);
        let formData = existingCardData[action.referralId];
        let cardData = new CardData(
          action.referralId,
          CardType.FORM,
          formData.data,
          formData.portalId,
          formData.parentId,
          { INSERT_TRANSACTION_ID: uuid() },
          {},
          formData.options
        );
        yield put(
          setFormData(cardData, action.referralId, false, false, false)
        );
      }
    } else if (Object.keys(existingOpenedModals).length === 0) {
      const existingCardData = yield select(getCard);
      if (response.mode === 'Update') {
        messages.push(
          response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
        );
      } else if (response.mode === 'Delete') {
        messages.push(
          response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
        );
      }
      yield put(
        getFormData(
          action.nodeId,
          action.mode,
          action.nodeType,
          action.ceId,
          response.pk,
          action.referralId,
          action.resetForm,
          action.location,
          existingCardData[action.referralId].parentId,
          existingCardData[action.referralId].portalId,
          Object.assign({
            UPDATE_TRANSACTION_ID: uuid(),
            primaryDBCode: existingCardData[action.referralId].referenceData
              .primaryDBCode
              ? existingCardData[action.referralId].referenceData.primaryDBCode
              : action.primaryDBCode,
            actionType: 'UPSERT',
          }, existingCardData[action.referralId].referenceData),
          action.usePortalReferenceData,
          existingCardData[action.referralId].parentCardId,
          action.gridButtonAction ? action.gridButtonAction : false
        )
      );
    } else {
      if (response.mode === 'Insert') {
        messages.push(
          response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
        );
      } else if (response.mode === 'Delete') {
        messages.push(
          response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
        );
      } else {
        messages.push(
          response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
        );
      }
    }
    if (
      response.mode === 'Insert' &&
      Object.keys(existingOpenedModals).length !== 0
    ) {
      yield put(addModalReload(action.parentId));
      yield put(setOpenModal(action.portalId, true));
    } else if (response.mode === 'Update') {
      let existingCardsData = yield select(getCard);
      const cardsData = cloneDeep(existingCardsData);
      const currentCardData = cardsData[action.referralId];
      currentCardData.errorData = {};
      currentCardData.buttonClicked = '';
      yield all([put(setChartData(currentCardData, action.referralId))]);
    }
    yield put(setToggleMessages(messages));
  } else if (flowType === 'PortalForm') {
    const existingOpenedModals = yield select(getOpenModals);
    Object.assign(DummyNode.data.CompositeEntityNode.insertForm, data.Form);
    Object.assign(
      DummyNode.data.CompositeEntityNode.entity,
      data.Form.logicalEntity
    );
    data = DummyNode.data;
    const messages: string[] = [];
    if (response.mode === 'Insert') {
      messages.push(
        response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
      );
    } else if (response.mode === 'Delete') {
      messages.push(
        response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
      );
    } else {
      messages.push(
        response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
      );
    }
    switch (data.CompositeEntityNode.insertForm.formType) {
      case 'insertForm':
      case 'editForm':
      case 'form':
        if (response.mode === 'Insert') {
          if (action.parentId && !action.portalForm) {
            const layoutData = {};
            const existingCardData = yield select(getCard);
            const formData = existingCardData[action.referralId].data;

            if (!action.usePortalReferenceData) {
              const cardData = new CardData(
                action.referralId,
                CardType.FORM,
                formData,
                action.portalId,
                action.parentId,
                undefined,
                layoutData,
                existingCardData[action.referralId].options
              );

              const routerParam: any = {};
              Object.assign(routerParam, action.location.state);
              routerParam['portalId'] = action.portalId
                ? action.portalId
                : action.parentId;
              routerParam['parentId'] = action.parentId;
              routerParam['referralId'] = action.referralId;
              routerParam[action.referralId] = {
                resetReferenceData: true,
                AE_RELOAD: {
                  ID: uuid(),
                  TS: new Date().getTime(),
                  STATUS: 'R',
                },
              };

              router.navigate(-1);
            } else {
              const cardData = new CardData(
                action.referralId,
                CardType.FORM,
                formData,
                action.portalId,
                action.parentId,
                layoutData,
                {},
                existingCardData[action.referralId].options
              );
              const previousData = existingCardData[action.parentId]
                ? existingCardData[action.parentId].referenceData
                : {};
              Object.assign(previousData, layoutData);
              const portalData = new CardData(
                action.parentId,
                CardType.PORTAL,
                {},
                action.portalId,
                action.parentId,
                previousData
              );

              const routerParam: any = {};
              Object.assign(routerParam, action.location.state);
              routerParam['portalId'] = action.portalId
                ? action.portalId
                : action.parentId;
              routerParam['parentId'] = action.parentId;
              routerParam['referralId'] = action.referralId;
              routerParam[action.referralId] = layoutData;
              routerParam[action.parentId] = previousData;
              Object.assign(routerParam[action.parentId], {
                resetReferenceData: true,
              });
              yield router.navigate('/app/ce/portal/' + uuid(), {
                state: routerParam,
              });
            }
          } else if (
            response.mode === 'Insert' &&
            Object.keys(existingOpenedModals).length !== 0
          ) {
            yield put(addModalReload(action.parentId));
            yield put(setOpenModal(action.portalId, true));

          } else {
            const routerParam: any = {};
            Object.assign(routerParam, action.location.state);
            routerParam['portalId'] = action.portalId
              ? action.portalId
              : action.parentId;
            routerParam['parentId'] = action.parentId;
            routerParam['referralId'] = action.referralId;
            routerParam[action.referralId] = {
              AE_RELOAD: {
                ID: uuid(),
                TS: new Date().getTime(),
                STATUS: 'R',
              },
              resetReferenceData: true,
            };
            yield router.navigate('/app/ce/portal/' + uuid(), {
              state: routerParam,
            });
          }
        } else {
          let refferalIDArr = action.referralId.split('_');
          const existingCardData = yield select(getCard);
          yield put(
            getFormDataUsingFormConfig(
              action.parentFormId,
              'Update',
              action.nodeType,
              response.pk,
              action.referralId,
              action.resetForm,
              action.location,
              existingCardData[action.referralId].parentId,
              existingCardData[action.referralId].portalId,
              {
                transactionId: response.transactionId,
                primaryDBCode:
                  existingCardData[action.referralId].referenceData
                    .primaryDBCode,
                actionType: 'UPSERT',
                gridId: refferalIDArr[1],
              },
              action.usePortalReferenceData,
              existingCardData[action.referralId].rowData,
              existingCardData[action.referralId].tabGroupId,
              existingCardData[action.referralId].entityName,
              existingCardData[action.referralId].tabPortalId,
              existingCardData[action.referralId].entityId,
              existingCardData[action.referralId].parentCardId
            )
          );
        }
        break;
      case 'singleRecordForm': {
        const existingCardData = yield select(getCard);
        yield put(
          getFormDataUsingFormConfig(
            action.parentFormId,
            'Update',
            action.nodeType,
            response.pk,
            action.referralId,
            action.resetForm,
            action.location,
            existingCardData[action.referralId].parentId,
            existingCardData[action.referralId].portalId,
            { transactionId: response.transactionId },
            action.usePortalReferenceData
          )
        );
        break;
      }

      case 'onlyInsertForm': {
        const existingCardData = yield select(getCard);
        yield put(
          getFormDataUsingFormConfig(
            action.parentFormId,
            'Insert',
            action.nodeType,
            undefined,
            action.referralId,
            action.resetForm,
            action.location,
            existingCardData[action.referralId].parentId,
            existingCardData[action.referralId].portalId,
            { transactionId: response.transactionId },
            action.usePortalReferenceData
          )
        );
        break;
      }

      case 'displayInsertFormAftetMenuClick': {
        const existingCardData = yield select(getCard);
        yield put(
          getFormDataUsingFormConfig(
            action.parentFormId,
            'Update',
            action.nodeType,
            response.pk,
            action.referralId,
            action.resetForm,
            action.location,
            existingCardData[action.referralId].parentId,
            existingCardData[action.referralId].portalId,
            { transactionId: response.transactionId },
            action.usePortalReferenceData
          )
        );
        break;
      }
    }
    yield put(setToggleMessages(messages));
  }
}
