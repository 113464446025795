import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, FormErrorContainers } from 'info-ui-library';
import { confirmAlert } from 'react-confirm-alert';
import { ThemeContext } from '../../theme/ThemeContext';
import {
  actionFlowRouting,
  setOpenModalSaga,
  setPortalDetails,
  setRouterAndFormData,
} from '../../actions';
import {
  NodeType,
  DELETE_BUTTON_CLASS,
  WORKFLOW_BUTTON_CLASS,
  DELETE_FORM_DATA_CLASS,
  OPEN_PORTAL_IN_MODAL_CLASS,
  ActionType,
  QueryType,
  CardType,
  FormNavigationType,
} from '../../constants/appeng.enum';
import { UserContext } from '../../user-context/UserContext';
import { IConfigButtons } from '../interfaces';
import { getButtons, getMenuButtons } from '../CompositeEntityCommonUtils';
import { StyledConfirModalDiv } from '../form/FormStyle';
import ReactConfirmBoxCore from '../../components/ReactConfirmBoxCore';
import PortalModalGenerator from '../../new-portal-layout/PortalModalGenerator';
import { getCardModalButtonsInHeader } from '../../new-portal-layout/GridComponent';
import { getPortalId } from '../../new-portal-layout/Portal';
import { useConfigQuery } from '../../custom-hooks/useConfigQuery';
import { v4 as uuid } from 'uuid';
import CardData from '../../models/carddata.model';
import { useLocation, useNavigate, useNavigationType } from 'react-router';

const ConfigButtonGenerator: React.FC<IConfigButtons> = (
  props: IConfigButtons
) => {
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);
  const dispatch = useDispatch();
  const portalDetails = useSelector(
    (state: any) => state.appState.portalDetails
  );
  const openCardModals = useSelector((state: any) => state.appState.openModals);

  const formDataObject = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  const [loading, setLoading] = React.useState({ id: '', status: false });
  const location = useLocation();
  const navigationType = useNavigationType();
  const routerparams = {
    action: navigationType,
    pathname: location.pathname,
    state: location.state,
  };
  const [cardPortalId, setCardPortalId] = React.useState('');

  let portalId: any = '';
  portalId = getPortalId(
    props.routerParameter.state
      ? props.routerParameter.state['portalId']
      : portalDetails.portalId
        ? portalDetails.portalId
        : props.portalId
  ); //NOSONAR
  //@Neha

  const { data } = useConfigQuery(QueryType.PORTAL_QUERY, portalId);

  let parentPathList = props.referralId.split('_');
  let subComponentParentId = props.subComponentParentId
    ? props.subComponentParentId
    : props.referralId;
  if (
    parentPathList.length > 1 &&
    subComponentParentId &&
    subComponentParentId != '0_'
  ) {
    parentPathList = parentPathList.slice(0, -1);
    subComponentParentId = parentPathList.join('_') + '_';
  }

  React.useEffect(() => {
    if (formDataObject && loading && !formDataObject.buttonClicked) {
      setLoading({ id: '', status: false });
    }
  }, [formDataObject && formDataObject.buttonClicked]);

  const chartModalHeader = getCardModalButtonsInHeader(
    themeContext,
    cardPortalId,
    setOpenModalSaga,
    dispatch,
    portalId,
    subComponentParentId
  );

  let openModalRouteParam: any = {};

  Object.assign(openModalRouteParam, routerparams);
  if (
    openCardModals.hasOwnProperty(subComponentParentId + cardPortalId) &&
    openCardModals[subComponentParentId + cardPortalId].referenceData[
      subComponentParentId + cardPortalId
    ]
  ) {
    openModalRouteParam['state'][subComponentParentId + cardPortalId] =
      openCardModals[subComponentParentId + cardPortalId].referenceData[
        subComponentParentId + cardPortalId
      ];
  }

  try {
    const handleClick = (
      buttonType: any,
      actionName: any,
      modalRequired: any,
      childRelations?: any,
      buttonId?: any,
      buttonDetails?: any
    ) => {
      if (buttonId && buttonType !== 'openPortalInModal') {
        setLoading({ id: buttonId, status: true });
      }
      const handleButtonStatus = () => {
        setLoading({ id: buttonId, status: false });
      };
      const actionFlowRelation =
        childRelations && childRelations.length
          ? childRelations.find(
              (rel: any) =>
                rel.relationType === 'Button_ActionFlow' ||
                rel.relationType === 'MenuButton_ActionFlow'
            )
          : null;

      const actionFlowId =
        actionFlowRelation && actionFlowRelation.childItemId
          ? actionFlowRelation.childItemId
          : '';

      const attribute: any = {
        nodeId: props.nodeId,
        actionName: actionName,
        transactionName: buttonDetails ? buttonDetails.transactionName : null,
        keys: props.keys,
        referenceData: props.data ? props.data : {},
        displayName: props.displayName,
        nodeType: props.nodeType ? props.nodeType : NodeType.PARENTNODE,
        ceId: props.ceId,
        mode: props.mode,
        location: location,
        modalRequired: modalRequired,
        referralId: props.referralId,
        resetForm: props.resetForm,
        portalForm: props.portalForm,
        parentFormId: props.parentFormId,
        portalId: props.portalId,
        parentId: props.parentId,
        usePortalReferenceData: props.usePortalReferenceData,
        compositeEntityKey: props.compositeEntityKey
          ? props.compositeEntityKey
          : props.keys[props.routerParameter.primaryDBCode],
        entityId: props.entityId,
        buttonType: buttonType,
        buttonId: buttonId,
        gridButtonAction: props.formButton ? false : true,
        routerReferenceData: props.routerParameter.data,
        actionFlowId,
        callType: props.formButton ? 'POST' : "GET"
      };

      if (buttonDetails && buttonDetails.modalRequired) {
        const id = subComponentParentId + buttonDetails.portalId;
        setCardPortalId(buttonDetails.portalId);
        let existingCardList: any = [];
        existingCardList.push(props.parentId);
        const modalAttribute =
          buttonDetails.modalCss == 'Left Align Full Vertical View'
            ? 'left:0;right:auto;width:65%'
            : buttonDetails.modalCss == 'Right Align Full Vertical View'
              ? 'left:auto;right:0;width:65%'
              : buttonDetails.modalCss == 'Center Align Partial View'
                ? 'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
                : ''; //NOSONAR
        //@Neha
        dispatch(
          setOpenModalSaga(
            id,
            false,
            true,
            existingCardList,
            [id],
            {},
            modalAttribute
          )
        );
      } else if (buttonDetails && buttonDetails.portalId) {
        const routerParam: any = {
          url: '/app/ce/portal/' + uuid(),
          location: location,
          buttonType: FormNavigationType.PORTAL_FORM,
          resetReferenceData: false,
          routeStateParams: {},
        };

        const subComponentId = portalId.includes('_')
          ? portalId.split('_')[0] + '_'
          : 0 + '_';
        routerParam.routeStateParams['portalId'] =
          subComponentId + buttonDetails.portalId;
        routerParam.routeStateParams['parentId'] =
          subComponentId + buttonDetails.portalId;
        routerParam.routeStateParams['referralId'] =
          subComponentId + buttonDetails.portalId;
        routerParam.routeStateParams[subComponentId + buttonDetails.portalId] =
          props.routerParameter.referenceData;
        const details = {
          portalId: buttonDetails.portalId,
          data: props.routerParameter.referenceData,
          loading: true,
        };
        dispatch(setPortalDetails(details));
        dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      }

      switch (buttonType) {
        default:
          dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, attribute));
          break;

        case DELETE_BUTTON_CLASS:
        case DELETE_FORM_DATA_CLASS:
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <StyledConfirModalDiv
                  ioTheme={themeContext.ioTheme}
                  ioMode={themeContext.ioMode}
                  styleName={themeContext.styleName}
                  className="custom-ui"
                >
                  <h1>Are you sure?</h1>
                  <p>
                    {buttonDetails && buttonDetails.warningMessage
                      ? buttonDetails.warningMessage
                      : ''}
                  </p>
                  <Button
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    onClick={() => {
                      onClose();
                      handleButtonStatus();
                    }}
                    disabled={false}
                    name={'No'}
                  />
                  <Button
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    styleName={themeContext.styleName}
                    disabled={false}
                    name={'Yes'}
                    onClick={() => {
                      dispatch(
                        actionFlowRouting(ActionType.BUTTON_CLICK, attribute)
                      );
                      onClose();
                    }}
                  />
                </StyledConfirModalDiv>
              );
            },
          });

          break;
        case OPEN_PORTAL_IN_MODAL_CLASS:
          break;
      }
    };
    const buttons = getButtons(
      themeContext,
      props.buttonList ? props.buttonList : [],
      handleClick,
      userContext,
      formDataObject,
      props.buttonPanelPrivilege,
      props,
      loading
    );
    const menuButtons = getMenuButtons(
      themeContext,
      props.menuButtons ? props.menuButtons : [],
      handleClick,
      userContext,
      formDataObject,
      props.buttonPanelPrivilege,
      props,
      loading
    );
    if (
      formDataObject &&
      formDataObject.workflowActions &&
      formDataObject.workflowActions &&
      formDataObject.workflowActions.length
    ) {
      const workflowActions = formDataObject.workflowActions.map(
        (button: any, index: any) => {
          <Button
            styleName={themeContext.styleName}
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            key={uuid()}
            id={button.id}
            disabled={false}
            name={button.label}
            className={WORKFLOW_BUTTON_CLASS}
            onClick={() =>
              handleClick(WORKFLOW_BUTTON_CLASS, button.label, false)
            }
          />;
        }
      );
      buttons.push.apply(buttons, workflowActions);
    }
    return (
      <React.Fragment>
        <ReactConfirmBoxCore handleClick={handleClick} parentProps={props} />
        <React.Fragment>
          {openCardModals.hasOwnProperty(
            subComponentParentId + cardPortalId
          ) && (
            <PortalModalGenerator
              key={'keys_' + portalId}
              header={chartModalHeader}
              subportalId={cardPortalId}
              portalId={cardPortalId}
              routerParameter={openModalRouteParam}
              subComponentParentId={subComponentParentId}
              modalAttribute={
                openCardModals[subComponentParentId + cardPortalId].cssAttribute
              }
            />
          )}
        </React.Fragment>
        {buttons}
        {menuButtons}
      </React.Fragment>
    );
  } catch (e) {
    const error = ['Error occurred in Button Generator'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};
function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}

export default injectIntl(React.memo(ConfigButtonGenerator, isPropsAreEqual));
