import { OktaAuth } from '@okta/okta-auth-js';
import { AuthMessageEnum, ErrorCodeEnum } from '../constants';
import AuthError from '../model/auth.error';
import AuthUser from '../model/auth.user';
import AuthUserSession from '../model/user.session';
import { ISignUpParams, IUserInfo, UserInfo } from '../model/user.info';
import { JWTToken, UserSession } from '../model';
import { SignUpResult } from '../types';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import AuthProvider from './auth.provider';

const createAuthUser = (tokens: any): AuthUser => {
  const appid = tokens.accessToken.claims.appid
    ? tokens.accessToken.accessToken.claims.appid
    : tokens.accessToken.claims.aud.includes('api://')
      ? tokens.accessToken.claims.aud.split('://')[1]
      : tokens.accessToken.claims.aud;
  if (tokens.accessToken.claims) {
    let customAttributesList: any = Object.keys(
      tokens.accessToken.claims
    ).filter((key) => key.includes('extn.'));

    let customAttributes: any = {};
    customAttributes['appId'] = appid;
    if (customAttributesList.length) {
      customAttributesList.map((attribute: any) => {
        let attri = attribute.replace('extn.', '');
        customAttributes['APP_LOGGED_IN_USER_CONTEXT_' + attri] =
          tokens.accessToken.claims[attribute] &&
          tokens.accessToken.claims[attribute][0]
            ? tokens.accessToken.claims[attribute][0]
            : 'Not Mapped Yet';
      });
    }

    const userInfo: IUserInfo = {
      preferred_username: tokens.accessToken.claims.preferred_username,
      family_name: tokens.accessToken.claims.family_name,
      given_name: tokens.accessToken.claims.given_name,
      email: tokens.accessToken.claims.email,
      name: tokens.accessToken.claims.name,
      sub: tokens.accessToken.claims.sub,
      oid: tokens.accessToken.claims.uid,
      profile: 'ACTIVE',
      role: tokens.accessToken.claims.roles,
      customAttributes: customAttributes,
    };
    const sessions: AuthUserSession = new AuthUserSession(
      tokens.idToken.idToken,
      tokens.accessToken.accessToken,
      tokens.accessToken.claims.locale
    );
    const authUser: AuthUser = new AuthUser(
      tokens.accessToken.claims.preferred_username,
      new UserInfo(
        tokens.accessToken.claims.sub,
        tokens.accessToken.claims.uid,
        userInfo
      ),
      sessions
    );
    return authUser;
  }
  throw new Error('sub or userName attrb is missing');
};

export class OktaAuthProvider implements AuthProvider {
  private config: any;
  private authClient: OktaAuth;

  constructor(config: any) {
    this.config = config;
    this.authClient = new OktaAuth({
      clientId: config.provider[config.provider.type].clientId,
      redirectUri: config.provider[config.provider.type].redirectUri, // Or the redirect URI for your app
      issuer: config.provider[config.provider.type].issuer, // OIDC URL (e.g., https://dev-123456.okta.com/oauth2/default)
      scopes: config.provider[config.provider.type].scopes,
      postLogoutRedirectUri:
        config.provider[config.provider.type].postLogoutRedirectUri,
      pkce: true,
    });
  }

  // SignIn method using Okta credentials
  public async signIn(
    userName: string,
    password: string,
    locale: string
  ): Promise<void> {
    try {
      const sessionExists = await this.authClient.session.get();
      if (sessionExists.status === 'ACTIVE') {
        this.authClient.tokenManager.clear();
        await this.authClient.signOut();
      }
      await this.authClient.token.getWithRedirect({
        responseType: ['id_token', 'token'],
        scopes: this.config.provider[this.config.provider.type].scopes,
      });
    } catch (error) {
      console.log(`SignIn failed: ${error.message}`);
      throw new Error(`SignIn failed: ${error.message}`);
    }
  }

  public async providerTokenSetter(): Promise<AuthUser | any> {
    try {
      // Attempt to parse tokens from the URL
      const tokens = await this.authClient.token.parseFromUrl();
      this.authClient.tokenManager.setTokens(tokens.tokens);
      return createAuthUser(tokens.tokens);
    } catch (error) {
      console.warn('Error parsing tokens, attempting silent retrieval:', error);
      if (await this.authClient.session.exists()) {
        try {
          const tokens: any = await this.authClient.token.getWithoutPrompt({
            responseType: ['id_token', 'token'],
            scopes: ['openid', 'profile', 'email'],
          });
          this.authClient.tokenManager.setTokens(tokens.tokens);
          return createAuthUser(tokens.tokens);
        } catch (silentError) {
          console.error('Silent retrieval failed:', silentError);
        }
      } else {
        console.error('No session exists. Redirecting to login.');
        await this.authClient.token.getWithRedirect({
          responseType: ['id_token', 'token'],
          scopes: ['openid', 'profile', 'email'],
        });
        return undefined;
      }
    }
  }

  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    // Implement the sign-up logic here using Okta API
    const signupUrl = `${this.authClient.options.issuer}/v1/registration`;
    window.location.assign(signupUrl);
    throw new Error('SignUp logic not implemented yet');
  }

  public async signUpUserProfile(params: ISignUpParams, res: SignUpResult) {
    throw new Error('signUpUserProfile not implemented yet');
  }

  public async confirmSignUp(
    userName: string,
    code: string,
    transactionId: string
  ): Promise<string> {
    throw new Error('confirmSignUp not implemented yet');
  }

  public async resendSignUp(username: string): Promise<CodeDeliveryInfo> {
    throw new Error('resendSignUp not implemented yet');
  }

  public async signOut(): Promise<string> {
    try {
      // await this.authClient.closeSession();
      await this.authClient.signOut();
      return 'SUCCESS';
    } catch (error) {
      throw new Error(`SignOut failed: ${error.message}`);
    }
  }

  public async changePassword(
    userName: string,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    throw new Error('changePassword not implemented yet');
  }

  public async forgotPassword(userName: string): Promise<CodeDeliveryInfo> {
    const forgotPasswordUrl = `${this.authClient.options.issuer}/v1/recovery/password`;
    window.location.assign(forgotPasswordUrl);
    throw new Error('changePassword not implemented yet');
  }

  public async forgotPasswordSubmit(
    userName: string,
    code: string,
    password: string
  ): Promise<string> {
    throw new Error('forgotPasswordSubmit not implemented yet');
  }

  // Method to get the current authenticated user
  public async currentAuthenticatedUser(
    params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    const sessionExists = await this.authClient.session.get();
    if (sessionExists.status === 'ACTIVE') {
      return await this.providerTokenSetter();
    } else {
      throw new AuthError(
        AuthMessageEnum.USER_NOT_LOGGEDIN,
        ErrorCodeEnum.USER_NOT_LOGGEDIN
      );
    }
  }

  // Method to check the current session
  public async currentSession(): Promise<UserSession | any> {
    try {
      if (await this.authClient.session.exists()) {
        const idToken: any = await this.authClient.tokenManager.get('idToken');
        const accessToken: any =
          await this.authClient.tokenManager.get('accessToken');
        return new UserSession(
          new JWTToken(idToken.idToken),
          new JWTToken(accessToken.accessToken),
          ' '
        );
      } else {
        // throw new AuthError(
        //   AuthMessageEnum.USER_NOT_LOGGEDIN,
        //   ErrorCodeEnum.USER_NOT_LOGGEDIN
        // );
        return undefined;
      }
    } catch (error) {
      throw new Error(`Error getting session: ${error.message}`);
    }
  }

  // Method to update user attributes
  public async updateUserAttributes(
    user: AuthUser,
    attributes: object
  ): Promise<string> {
    // You could implement this functionality depending on your Okta setup
    return 'SUCCESS';
  }
}

// Singleton to create OktaAuthProvider
let oktaProvider: OktaAuthProvider;

const createOktaProvider = (config: any): OktaAuthProvider => {
  if (!oktaProvider) {
    oktaProvider = new OktaAuthProvider(config);
  }
  return oktaProvider;
};

const configureOkta = (config: any) => {
  // Additional Okta configuration logic (if needed)
};

export default createOktaProvider;
