import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoAuth } from '../auth';
import { AuthUser, UserSession } from '../model';
import { FloatingLabelInput } from './FloatingLabelInput';
import ErrorMsgComponent from './ErrorMessageComponent';
import { AuthProviderEnum, ErrorCodeEnum } from '../constants';
import CommonHeader from './CommonHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LanguageContext } from './App';
import config from '../config/index';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
const img = require('../greenBackground.jpg');
import styled from 'styled-components';
import {
  CustomButton,
  Form,
  LinkComponent,
  LinkSpan,
  LoginContainer,
  LoginSpanContainer,
  RedirectContainer,
  WrapperContainer,
} from './SignInStyle';

interface ISignIn {
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
  email: any;
  navigateTo: any;
}

const ToastStyle = styled.div`
  background-color: rgb(227, 252, 239) !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
  color: rgb(0, 102, 68) !important;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 4px;
  border-left: 5px solid rgb(54, 179, 126) !important;
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

const SignIn: React.FC<ISignIn> = (props: ISignIn) => {
  const context = useContext(LanguageContext);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showSignIn, setShowSignIn] = useState(true);
  const [inlineErrorUser, setInlineErrorUser] = useState(false);
  const [inlineErrorPassword, setInlineErrorPassword] = useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const providerType = InfoAuth.getConfig().provider.type;

  useEffect(() => {
    setShowToast(props.email ? true : false);
    if (
      providerType === AuthProviderEnum.MICROSOFT_AD ||
      providerType === AuthProviderEnum.MICROSOFT_AD_INFO_APPS || providerType === AuthProviderEnum.OKTA
    ) {
      signIn(navigate);
    }
  }, []);

  const signIn = async (navigate: any) => {
    switch (providerType) {
      case AuthProviderEnum.OKTA:{
        setLoading(true);
        await InfoAuth.signIn(
          userName,
          password,
          context.language
        );
        // let authUser =await InfoAuth.providerTokenSetter();
        // if (authUser != null) {
        //   const session: UserSession = await InfoAuth.currentSession();
        //   const accessToken: string = session.accessToken.token;
        // }
        setShowSignIn(true);

        navigate(props.navigateTo);
        break;
      }
      case AuthProviderEnum.MICROSOFT_AD_INFO_APPS:
      case AuthProviderEnum.MICROSOFT_AD: {
        setLoading(true);
        let authUser = await InfoAuth.signIn(
          userName,
          password,
          context.language
        );
        if (authUser != null) {
          const session: UserSession = await InfoAuth.currentSession();
          const accessToken: string = session.accessToken.token;
        }
        setShowSignIn(true);

        navigate(props.navigateTo);
        break;
      }
      default: {
        if (userName === '') {
          setInlineErrorUser(true);
          setPass(false);
          setshowMessage(false);
          setMessage('');
          setLoading(false);
        }
        if (password === '') {
          setInlineErrorPassword(true);
          setPass(false);
          setshowMessage(false);
          setMessage('');
          setLoading(false);
        }
        let authUser;
        if (userName !== '' && password !== '') {
          try {
            setLoading(true);
            authUser = await InfoAuth.signIn(
              userName,
              password,
              context.language
            );
            if (authUser != null) {
              const session: UserSession = await InfoAuth.currentSession();
              const accessToken: string = session.accessToken.token;
            }
            setShowSignIn(true);

            navigate(props.navigateTo);
          } catch (err) {
            setLoading(false);
            if (err.message != null) {
              if (err.code === ErrorCodeEnum.PENDING_CHANGE_PASSWORD) {
                navigate({
                  pathname: '/changePassword',
                  state: {
                    userName: userName,
                  },
                });
              } else if (err.code === ErrorCodeEnum.PENDING_VERIFICATION) {
                config.verificationType === 'code'
                  ? navigate({
                      pathname: '/confirmSignUp',
                      state: {
                        email: userName,
                        userId: err.userId,
                        showToast: true,
                      },
                    })
                  : setShowToast(true);
                toast.success('User verification pending. Check email', {
                  autoClose: 10000,
                  className: ToastStyle,
                });
              } else {
                setPass(false);
                setshowMessage(true);
                setMessage(err.message);
              }
            }
          }
        }
      }
    }
  };

  if (
    providerType !== AuthProviderEnum.MICROSOFT_AD &&
    providerType !== AuthProviderEnum.MICROSOFT_AD_INFO_APPS && 
    providerType !== AuthProviderEnum.OKTA
  ) {
    return (
      <div>
        <CommonHeader {...props} setLanguage={context.setLanguage} />
        {showToast && <ToastContainer hideProgressBar={true} />}
        {showSignIn && (
          <LoginContainer image={img}>
            <WrapperContainer>
              <Form>
                <LoginSpanContainer showMessage={showMessage}>
                  <strong>
                    <FormattedMessage id="signin.login" />
                  </strong>
                </LoginSpanContainer>
                <ErrorMsgComponent
                  pass={pass}
                  showMessage={showMessage}
                  message={message}
                />
                <FloatingLabelInput
                  id="userId"
                  dispalyLabel={<FormattedMessage id="signin.email" />}
                  onChange={setUserName}
                  value={userName}
                  inlineError={inlineErrorUser}
                  setInLineError={setInlineErrorUser}
                  type="email"
                  inlineMsg="Email is required"
                />
                <br />
                <FloatingLabelInput
                  id="Password"
                  dispalyLabel={<FormattedMessage id="signin.password" />}
                  onChange={setPassword}
                  value={password}
                  inlineError={inlineErrorPassword}
                  setInLineError={setInlineErrorPassword}
                  type="password"
                  inlineMsg="Password is required"
                />
                <br />
                <CustomButton
                  size="lg"
                  loading={loading}
                  onClick={() => signIn(navigate)}
                  className={'submit'}
                >
                  <FormattedMessage id="signin.signin" />
                </CustomButton>
                <RedirectContainer>
                  {config.forgetPasswordRequired && (
                    <LinkComponent to="/forgotPassword">
                      <LinkSpan showMessage={showMessage}>
                        <FormattedMessage id="signin.forgotpassword" />
                      </LinkSpan>
                    </LinkComponent>
                  )}
                  {config.signUpRequired && (
                    <LinkComponent to="/signUp">
                      <LinkSpan showMessage={showMessage}>
                        <FormattedMessage id="signin.signup" />
                      </LinkSpan>
                    </LinkComponent>
                  )}
                </RedirectContainer>
              </Form>
            </WrapperContainer>
          </LoginContainer>
        )}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default injectIntl(SignIn);
