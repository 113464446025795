import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// import moment from 'moment';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const PreRenderer = ({ cell, row, column: { id } }) => {
  const [value, setValue] = React.useState(cell.value);
  const [rowOriginal] = React.useState([row.original]);
  let innerWidth = window.innerWidth - 20;
  // const [rowOriginal, setRowOriginal] = React.useState([row.original]);

  const divRef = useRef(null);
  const [isTooltipEnable, setIsTooltipEnable] = useState(false);
  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  useEffect(() => {
    if (divRef.current) {
      const { scrollWidth, clientWidth } = divRef.current;
      setIsTooltipEnable(scrollWidth > clientWidth);
    }
  }, [divRef.current?.innerText]);

  // const timeConverter = (valDate) => {
  //   return moment.utc(valDate).local().format('DD-MMM-YYYY h:mm:ss A');
  // };

  const checkValidTag = (content) => {
    const validTagRegex = /<([a-zA-Z][a-zA-Z0-9-]*)([^>]*)(?:>(.*?)<\/\1>|\/>)/;
    return validTagRegex.test(content.join(''));
  };

  let evaluate = rowOriginal.map((key) => {
    try {
      return (!value || typeof value == 'number') ? value : eval('`' + value.replace(/`/g, '\\`') + '`'); //NOSONAR
    } catch (e) {
      console.log(':::::PreRenderer:::::', e.message, value);
    }
  });

  const sanitizedData = () => ({
    __html: evaluate.join(''),
  });

  let isValidTag = checkValidTag(evaluate);
  let modifiedData = isValidTag ? evaluate.join('') : evaluate.join('').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  let sanitizedTooltipContent = DOMPurify.sanitize(modifiedData, { ALLOWED_TAGS: [] });
  sanitizedTooltipContent = sanitizedTooltipContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const tooltipId = uuid();
  return cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    !cell.column.isEllipsesEnable ? (isValidTag ? <div
      dangerouslySetInnerHTML={sanitizedData()}
      style={{ padding: '10px 10px', lineHeight: 'normal' }}
    /> : <div style={{ padding: '10px 10px', lineHeight: 'normal' }}>{evaluate.join('')}</div>
  ) : cell.isRepeatedValue !== true &&
    value !== undefined &&
    value !== null &&
    cell.column.isEllipsesEnable ? (
    <>
      {isValidTag ?
        <div
          ref={divRef}
          data-tooltip-id={isTooltipEnable ? tooltipId : undefined}
          data-tooltip-content={sanitizedTooltipContent}
          style={{
            padding: '5px 5px',
            overflow: 'hidden',
            width: '100%',
            overflowWrap: 'break-word',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordBreak: 'break-all',
          }}
          dangerouslySetInnerHTML={sanitizedData()}
        /> : <div
          ref={divRef}
          data-tooltip-id={isTooltipEnable ? tooltipId : undefined}
          data-tooltip-content={sanitizedTooltipContent}
          style={{
            padding: '5px 5px',
            overflow: 'hidden',
            width: '100%',
            overflowWrap: 'break-word',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordBreak: 'break-all',
          }}>{evaluate.join('')}</div>
      }
      {isTooltipEnable && (
        <ReactTooltip
          place="bottom"
          style={{
            zIndex: 9999,
            color: 'white !important',
            maxWidth: `${innerWidth}px`,
            maxHeight: '400px',
            wordWrap: 'break-word',
            // overflow: 'auto'
          }}
          id={tooltipId}
          positionStrategy="fixed"
        />
      )}
    </>
  ) : (
    // <React.Fragment>
    <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
    // </React.Fragment>
  );
};

PreRenderer.propTypes = {
  column: PropTypes.any,
  'column.id': PropTypes.any,
  cell: PropTypes.any,
  'cell.value': PropTypes.any,
  'cell.isRepeatedValue': PropTypes.any,
  'cell.column.isEllipsesEnable': PropTypes.any,
  'cell.column.Header': PropTypes.any,
  row: PropTypes.any,
  'row.original': PropTypes.any,
};

export default React.memo(PreRenderer);