import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import {
  StyledGridConfigButtonSpan,
  StyledGridConfigButtonWrapper,
} from './GridStyle';
import { useConfigQuery } from '../../custom-hooks/useConfigQuery';
// import SkeletonCore from '../../components/SkeletonCore';
import { getPrivilege, isExpressionResolved } from '../../utils/ClientUtils';
import {
  NOPRIVILEGE,
  QueryType,
  INSERT_MODE,
  BUTTON_PANNEL_BOTTOM_POSITION,
  BUTTON_PANNEL_TOP_POSITION,
} from '../../constants/appeng.enum';
import { UserContext } from '../../user-context/UserContext';
import ConfigButtonGenerator from './ConfigButtonGenerator';
import {
  // getPrimaryDBCode,
  // getDispalyColumn,
  getForm,
} from '../../utils/ConfigUtils';
// import { isExpressionResolved } from '../../utils/ClientUtils';
import { extractLabel } from '../../utils/intlutils';
import { ThemeContext } from '../../theme/ThemeContext';
import { IGridConfigButton, IGridButtonGenerator } from '../interfaces/index';

const GridConfigButtonComponent: React.FC<IGridConfigButton> = (props) => {
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);

  const queryType =
    props.nodeId && props.nodeId !== ''
      ? QueryType.COMPOSITE_ENTITY_NODE_QUERY
      : QueryType.DATA_GRID_QUERY;
  const configId =
    props.nodeId && props.nodeId !== '' ? props.nodeId : props.gridId;
  const { loading, data } = useConfigQuery(queryType, configId);
  // const { loading, error, data } = useConfigQuery(queryType, configId);
  if (loading) return <div></div>;

  let nodeEditable = true;
  if (
    data?.CompositeEntityNode?.expressionAvailable &&
    data?.CompositeEntityNode?.editabilityRegex &&
    !isExpressionResolved(
      data?.CompositeEntityNode?.editabilityRegex,
      userContext.getUserDetail()
    )
  ) {
    nodeEditable = false;
  }
  // if (
  //   data.CompositeEntityNode &&
  //   data.CompositeEntityNode.expressionAvailable &&
  //   data.CompositeEntityNode.editabilityRegex &&
  //   !isExpressionResolved(
  //     data.CompositeEntityNode.editabilityRegex,
  //     userContext.getUserDetail()
  //   )
  // ) {
  //   nodeEditable = false;
  // }
  const dataGrid = data.CompositeEntityNode
    ? data.CompositeEntityNode.dataGrid
    : data.DataGrid;
  const privilegeDG = getPrivilege(
    dataGrid.privileges,
    userContext.getCurrentRole()
  );

  if (privilegeDG !== NOPRIVILEGE) {
    const buttonPanel = dataGrid.buttonPanels
      ? dataGrid.buttonPanels.filter(
        (buttonPanel: any) =>
          buttonPanel.buttonPanelPosition === BUTTON_PANNEL_TOP_POSITION ||
          buttonPanel.buttonPanelPosition === BUTTON_PANNEL_BOTTOM_POSITION
      )[0]
      : {};

    const buttonPanelPrivilege = getPrivilege(
      buttonPanel.privileges ? buttonPanel.privileges : [],
      userContext.getCurrentRole()
    );

    // const primaryDBCode = getPrimaryDBCode(
    //   data.CompositeEntityNode
    //     ? data.CompositeEntityNode.entity.logicalColumns
    //     : data.DataGrid.dataGridColumns
    // );

    let parentFormId = '';

    if (data.CompositeEntityNode) {
      parentFormId = getForm(INSERT_MODE, data.CompositeEntityNode).configObjectId;
    } else if (data.DataGrid.form) {
      parentFormId = data.DataGrid.form.configObjectId;
    }

    // const parentFormId = data.CompositeEntityNode
    //   ? getForm(INSERT_MODE, data.CompositeEntityNode).configObjectId
    //   : data.DataGrid.form
    //     ? data.DataGrid.form.configObjectId
    //     : '';

    // const referralId = data.CompositeEntityNode
    //   ? getForm(INSERT_MODE, data.CompositeEntityNode).configObjectId
    //   : data.DataGrid.form
    //     ? data.DataGrid.form.configObjectId
    //     : '';

    let referralId = '';

    if (data.CompositeEntityNode) {
      referralId = getForm(INSERT_MODE, data.CompositeEntityNode).configObjectId;
    } else if (data.DataGrid.form) {
      referralId = data.DataGrid.form.configObjectId;
    }
    let entityId = '';

    if (data?.CompositeEntityNode) {
      entityId = data.CompositeEntityNode.entity.configObjectId;
    } else if (data.DataGrid.form) {
      if (data?.DataGrid?.form?.logicalEntity) {
        entityId = data.DataGrid.form.logicalEntity.configObjectId;
      } else {
        entityId = '';
      }
    } else {
      entityId = '';
    }
    // const entityId = data.CompositeEntityNode
    //   ? data.CompositeEntityNode.entity.configObjectId
    //   : data.DataGrid.form
    //     ? data.DataGrid.form.logicalEntity
    //       ? data.DataGrid.form.logicalEntity.configObjectId
    //       : ''
    //     : '';
    const dataGridName = dataGrid.label ? dataGrid.label : dataGrid.name;
    return (
      <React.Fragment>
        {!props.setCardLabel && (
          <StyledGridConfigButtonSpan
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
          >
            {extractLabel(dataGridName, props.intl.locale)}
          </StyledGridConfigButtonSpan>
        )}
        <GridButtonGenerator
          intl={props.intl}
          setCardButtons={props.setCardButtons}
          nodeId={props.nodeId}
          displayName={''}
          nodeType={props.nodeType}
          ceId={props.ceId}
          gridId={props.gridId}
          parentFormId={parentFormId}
          buttonPanelPrivilege={buttonPanelPrivilege}
          buttonList={buttonPanel.buttons ? buttonPanel.buttons : []}
          nodeEditable={nodeEditable}
          routerParameter={props.routerParameter}
          referralId={referralId}
          entityId={entityId}
          handleClick={props.handleClick}
          setCardLabel={props.setCardLabel}
          dataGridName={dataGridName}
          portalId={props.portalId}
          parentId={props.parentId}
          isEditButtonEnable={dataGrid.isEditButtonEnable}
          editableGridEnable={props.editableGridEnable}
          setEditableGridEnable={props.setEditableGridEnable}
          menuButtons={buttonPanel.menuButtons ? buttonPanel.menuButtons : []}
          subComponentParentId={props.subComponentParentId}
        />
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
};

const GridButtonGenerator: React.FC<IGridButtonGenerator> = (props) => {
  let buttons: any;
  if (props.buttonPanelPrivilege !== NOPRIVILEGE) {
    if (!props.setCardButtons) {
      buttons = (
        <StyledGridConfigButtonWrapper>
          <ConfigButtonGenerator
            nodeId={props.nodeId}
            mode={INSERT_MODE}
            displayName={props.displayName}
            nodeType={props.nodeType}
            keys={{}}
            data={{}}
            portalId={props.portalId}
            parentId={props.parentId}
            ceId={props.ceId}
            gridId={props.gridId}
            parentFormId={props.parentFormId}
            buttonPanelPrivilege={props.buttonPanelPrivilege}
            buttonList={props.buttonList}
            formButton={false}
            nodeEditable={props.nodeEditable}
            routerParameter={props.routerParameter}
            resetForm={true}
            referralId={props.referralId}
            portalForm={false}
            entityId={props.entityId}
            compositeEntityKey={null}
            handleClick={props.handleClick}
            isEditButtonEnable={props.isEditButtonEnable}
            editableGridEnable={props.editableGridEnable}
            setEditableGridEnable={props.setEditableGridEnable}
            menuButtons={props.menuButtons}
            subComponentParentId={props.subComponentParentId}
          />
        </StyledGridConfigButtonWrapper>
      );
    } else {
      buttons = (
        <ConfigButtonGenerator
          nodeId={props.nodeId}
          mode={INSERT_MODE}
          displayName={props.displayName}
          nodeType={props.nodeType}
          keys={{}}
          portalId={props.portalId}
          parentId={props.parentId}
          data={{}}
          ceId={props.ceId}
          gridId={props.gridId}
          parentFormId={props.parentFormId}
          buttonPanelPrivilege={props.buttonPanelPrivilege}
          buttonList={props.buttonList}
          formButton={false}
          nodeEditable={props.nodeEditable}
          routerParameter={props.routerParameter}
          resetForm={true}
          referralId={props.referralId}
          portalForm={false}
          entityId={props.entityId}
          compositeEntityKey={null}
          handleClick={props.handleClick}
          isEditButtonEnable={props.isEditButtonEnable}
          editableGridEnable={props.editableGridEnable}
          setEditableGridEnable={props.setEditableGridEnable}
          menuButtons={props.menuButtons}
          subComponentParentId={props.subComponentParentId}
        />
      );
    }
  }

  useEffect(() => {
    if (props.setCardButtons) {
      props.setCardButtons(buttons);
    }
    if (props.setCardLabel) {
      props.setCardLabel(props.dataGridName);
    }
  }, [JSON.stringify(props.editableGridEnable)]);
  return <React.Fragment>{!props.setCardButtons && buttons}</React.Fragment>;
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
    JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}

export default injectIntl(
  React.memo(GridConfigButtonComponent, isPropsAreEqual)
);
