import React, { Suspense, useCallback, useState } from 'react';
import InfoTable from './Info_Table';
import ListInfoTableScroll from './Info_Table_Scroll';
import cloneDeep from 'lodash/cloneDeep';
import { getGridData, setGridData } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function loadingData() {
  return React.createElement(
    'div',
    { className: 'animated fadeIn pt-1 text-center' },
    'Loading...'
  );
}

function InfoDataGrid(props) {
  //const serverData = props.serverPaginationEnable && !props.scrollEnable ? [] : props.data;
  //const [data, setData] = useState(serverData);
  //const [copyData, setCopyData] = useState(props.data);
  // React.useEffect(() => {
  //     setData(serverData);
  // }, [serverData]);
  // React.useEffect(() => {
  //     setCopyData(props.data);
  // }, [props.data]);
  
  
  let cardsData = useSelector(
    (state) => state.appState.cardsData[props.referralId]
  );
  const dispatch = useDispatch();
  const [loading] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [pageCount] = useState(0);
  // const [pageCount, setPageCount] = useState(0);
  // const fetchIdRef = React.useRef(0);
  const [selectedPath, setSelectedPath] = useState(null);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // const fetchID = ++fetchIdRef.current;
    // setLoading(true);
    // setTimeout(() => {
    //   if (fetchID === fetchIdRef.current) {
    //     const startRow = pageSize * pageIndex;
    //     const endRow = startRow + pageSize;
    //     //setData(serverData.slice(startRow, endRow))
    //     // setPageCount(Math.ceil(serverData.length / pageSize))
    //     setLoading(false);
    //   }
    // }, 1000);
    if (
      parseInt(cardsData?.paginatedDetails?.page) - 1 !== pageIndex ||
      parseInt(cardsData?.paginatedDetails?.limit) !== pageSize
    ) {
      dispatch(
        getGridData(
          props.datagrid.configObjectId,
          cardsData.referenceData ? cardsData.referenceData : {},
          props.referralId,
          props.resetOtherGrids,
          props.parentId,
          props.portalId,
          props.tabRefresh,
          null,
          pageIndex + 1,
          pageSize
        )
      );
    }
  }, [cardsData]);

  //updating row data
  const updateMyData = useCallback(
    async (rowIndex, columnID, value, path) => {
      let keyMap = {};
      keyMap['ceId'] = props.ceId;
      keyMap['logicalEntityId'] = props.logicalEntityId;
      keyMap['nodeId'] = props.nodeId;
      if (path) {
        let primaryKey = '';
        let rowData = {};
        let dataSetter = cloneDeep(cardsData.data);
        let dataSetup = dataSetter.map((row, index) => {
          if (index === rowIndex) {
            primaryKey = row[props.primaryDBCode];
            rowData = props.row
              ? { ...row, ...props.row.original }
              : { ...row, [columnID]: value };
            return {
              ...row,
              [columnID]: value,
            };
          }
          return row;
        });
        //setData(dataSetup);
        let newDataMap = {};
        let valueMap = {};
        valueMap[columnID] = value;
        newDataMap['valueMap'] = valueMap;
        newDataMap['rowIndex'] = rowIndex;
        newDataMap['path'] = path.toString();
        newDataMap['valueChangedFor'] = columnID;
        newDataMap['primaryDBCode'] = props.primaryDBCode;
        keyMap['primaryKey'] = primaryKey;
        if (props.updateDataInline && props.referralId && primaryKey != '') {
          await props.updateDataInline(
            newDataMap,
            keyMap,
            props.referralId,
            rowData,
            setSelectedPath,
            props.datagrid
          );
        } else if (props.referralId) {
          let currentCard = cloneDeep(cardsData);
          currentCard.data = dataSetup;
          dispatch(setGridData(currentCard, false, true));
        }
      } else {
        let pathCopy = [...path];
        pathCopy.shift();
        let returnData = datamodifier(
          columnID,
          value,
          pathCopy,
          cardsData.data[path[0]]
        );
        let newdata = [...cardsData.data];
        newdata[path[0]] = returnData;
        //setData(newdata);
      }
      //setSelectedPath(path.toString());
    },
    [cardsData]
  );

  React.useEffect(() => {
    if (selectedPath) {
      setTimeout(() => {
        setSelectedPath(null);
      }, 100);
    }
  }, [selectedPath]);

  const datamodifier = useCallback((columnID, value, path, parentData) => {
    if (path.length === 1) {
      parentData.subRows[path[0]][columnID] = value;
    } else {
      let pathCopy = path;
      pathCopy.shift();
      datamodifier(columnID, value, pathCopy, parentData.subRows[path[0]]);
    }
    return parentData;
  }, []);

  let newProps = {};
  if (props.scrollEnable) {
    newProps = {
      ...props,
      ceId: props.ceId,
      nodeId: props.nodeId,
      pkDBCode: props.primaryDBCode,
      insertFormId: props.insertFormId,
      editFormId: props.editFormId,
      columns: props.columns,
      styleName: props.styleName,
      ioTheme: props.ioTheme,
      ioMode: props.ioMode,
      borderEnable: props.borderEnable,
      strippedEnable: props.strippedEnable,
      hoverEnable: props.hoverEnable,
      updateMyData: updateMyData,
      subComponentEnable: props.subComponentEnable,
      subComponentType: props.subComponentType,
      selectedPath: selectedPath,
      errorDataExtractor: props.errorDataExtractor,
      cardWidth: props.cardWidth,
      cardHeight: props.cardHeight,
      referralId: props.referralId,
      cardsDataSatus: props.cardsDataSatus,
      isRowSelectionEnable: props.isRowSelectionEnable,
      usePortalReferenceData: props.usePortalReferenceData,
      portalId: props.portalId,
      parentId: props.parentId,
      errorMap: props.errorMap,
      gridHeaderButtons: props.gridHeaderButtons,
      gridHeaderButtonsClick: props.gridHeaderButtonsClick,
      loadedIcon: props.loadedIcon,
      gridMeuButtons: props.gridMeuButtons
    };
  } else {
    newProps = {
      ...props,
      ceId: props.ceId,
      nodeId: props.nodeId,
      pkDBCode: props.primaryDBCode,
      insertFormId: props.insertFormId,
      editFormId: props.editFormId,
      columns: props.columns,
      styleName: props.styleName,
      ioTheme: props.ioTheme,
      ioMode: props.ioMode,
      borderEnable: props.borderEnable,
      strippedEnable: props.strippedEnable,
      hoverEnable: props.hoverEnable,
      updateMyData: updateMyData,
      fetchData: fetchData,
      loading: loading,
      pageCount: pageCount,
      serverPaginationEnable: props.serverPaginationEnable,
      subComponentEnable: props.subComponentEnable,
      subComponentType: props.subComponentType,
      selectedPath: selectedPath,
      errorDataExtractor: props.errorDataExtractor,
      cardWidth: props.cardWidth,
      cardHeight: props.cardHeight,
      referralId: props.referralId,
      pageCalculatedIndex: props.pageIndex,
      cardsDataSatus: props.cardsDataSatus,
      isRowSelectionEnable: props.isRowSelectionEnable,
      usePortalReferenceData: props.usePortalReferenceData,
      portalId: props.portalId,
      parentId: props.parentId,
      errorMap: props.errorMap,
      gridHeaderButtons: props.gridHeaderButtons,
      gridHeaderButtonsClick: props.gridHeaderButtonsClick,
      loadedIcon: props.loadedIcon,
      gridMeuButtons: props.gridMeuButtons
    };
  }

  return (
    <Suspense fallback={loadingData()}>
      {props.scrollEnable ? (
        <ListInfoTableScroll {...newProps} />
      ) : (
        <InfoTable {...newProps} />
      )}
    </Suspense>
  );
}

InfoDataGrid.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.any,
    id: PropTypes.any,
    values: PropTypes.any,
  }),

  datagrid: PropTypes.shape({
    configObjectId: PropTypes.any,
  }),

  referralId: PropTypes.any,
  resetOtherGrids: PropTypes.any,
  parentId: PropTypes.any,
  portalId: PropTypes.any,
  tabRefresh: PropTypes.any,
  ceId: PropTypes.any,
  logicalEntityId: PropTypes.any,
  nodeId: PropTypes.any,
  primaryDBCode: PropTypes.any,
  updateDataInline: PropTypes.any,
  scrollEnable: PropTypes.any,
  insertFormId: PropTypes.any,
  editFormId: PropTypes.any,
  columns: PropTypes.any,
  styleName: PropTypes.any,
  ioTheme: PropTypes.any,
  ioMode: PropTypes.any,
  borderEnable: PropTypes.any,
  strippedEnable: PropTypes.any,
  hoverEnable: PropTypes.any,
  subComponentEnable: PropTypes.any,
  subComponentType: PropTypes.any,
  errorDataExtractor: PropTypes.any,
  cardWidth: PropTypes.any,
  cardHeight: PropTypes.any,
  cardsDataSatus:PropTypes.any,
  isRowSelectionEnable: PropTypes.any,
  usePortalReferenceData: PropTypes.any,
  errorMap: PropTypes.any,
  gridHeaderButtons: PropTypes.any,
  gridMeuButtons: PropTypes.any,
  gridHeaderButtonsClick: PropTypes.any,
  loadedIcon: PropTypes.any,
  serverPaginationEnable: PropTypes.any,
  pageIndex: PropTypes.any,
};

export default React.memo(InfoDataGrid);
