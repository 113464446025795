import React, { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Button, FormErrorContainers } from 'info-ui-library';
import { useSelector, useDispatch } from 'react-redux';
import { StyledWarningModalDiv } from '../composite-entity/form/FormStyle';
import { setWarningMessages } from '../actions';
const ReactConfirmBoxCore: React.FC<any> = (props: any) => {
  const themeContext = useContext(ThemeContext);
  const warningMessages = useSelector(
    (state: any) => state.appState.warningMessages
  );
  // const cardsData = useSelector((state: any) => state.appState.cardsData);
  const dispatch = useDispatch();
  // const [cardId, setCardId] = useState('');
  try {
    if (Object.keys(warningMessages).length) {
      let copyWarningMessages: any;
      let card_Id: string = '';
      for (let [_, value] of Object.entries(warningMessages)) {
        // card_Id = key;
        copyWarningMessages = value;
      }

      const handleClose = () => {
        dispatch(setWarningMessages({}));
      };
      const handleOk = () => {
        // const buttonType = 'updateFormDataButton';
        // const actionName = 'Update';
        // const modalRequired = 'false';

        dispatch(setWarningMessages({}));
      };
      return (
        <StyledWarningModalDiv
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={themeContext.styleName}
          className="custom-ui"
        >
          <h1>!! Warnings !!</h1>
          {copyWarningMessages.map((warningMessage: any) => {
            return <p key={warningMessage}>{warningMessage}</p>;
          })}
          <Button
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
            disabled={false}
            name={'No'}
            onClick={() => handleClose()}
          />
          <Button
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={themeContext.styleName}
            disabled={false}
            name={'Yes'}
            onClick={() => handleOk()}
          />
        </StyledWarningModalDiv>
      );
    } else {
      return null;
    }
  } catch {
    const error = ['Error occurred in React Confirm Boc'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default ReactConfirmBoxCore;
