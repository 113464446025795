import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  setStore,
  DELETE_RECORD,
} from '../../actions';
import {
  UpsertMessage,
  CardType,
  RECORD_INDEX,
} from '../../constants/appeng.enum';
import { router } from '../../routes/Routes';
import { getPrimaryDBCode } from '../../utils/ConfigUtils';
import cloneDeep from 'lodash/cloneDeep';
import DummyNode from '../../composite-entity/form/dummyNodeConfigStructure';
import { validateFormRecord } from '../utilitySags';
import { v4 as uuid } from 'uuid';
const getCard = (state: any) => state.appState.cardsData;

export default function* deleteRecordsSaga() {
  yield all([fork(watchDeleteRecord)]);
}

function* watchDeleteRecord() {
  yield takeEvery(DELETE_RECORD, deleteRecordSaga);
}

export function* deleteRecordSaga(action: any): Generator<any, any, any> {
  let { data } = yield call(
    base.getConfigData,
    'FormQuery',
    action.parentFormId
  );
  const pkDBCode = getPrimaryDBCode(data.Form.logicalEntity.logicalColumns);
  if (data.Form.logicalEntity) {
    Object.assign(DummyNode.data.CompositeEntityNode.insertForm, data.Form);
    Object.assign(
      DummyNode.data.CompositeEntityNode.entity,
      data.Form.logicalEntity
    );
    data = DummyNode.data;
    const existing_CardData = yield select(getCard);
    let parentFormData = cloneDeep({
      ...existing_CardData[action.referralId]['data']['0'],
      attachmentData:
        existing_CardData[action.referralId]['data']['attachmentData'],
    });
    parentFormData[RECORD_INDEX] = 0;

    yield call(
      validateFormRecord,
      parentFormData,
      data.CompositeEntityNode.entity,
      action.referralId,
      action.mode,
      data.CompositeEntityNode.insertForm
    );
  }
  const existingCardData = yield select(getCard);
  if (
    Object.keys(existingCardData[action.referralId].errorData).length === 0 ||
    (existingCardData[action.referralId].errorData &&
      Object.keys(
        existingCardData[action.referralId].errorData.errorData
          .formFieldValidation
      ).length === 0)
  ) {
    let dataToSave = {
      transactionName: action.transactionName
        ? action.transactionName
        : action.logicalEntityIdOrName + '_Delete',
    };
    const response = yield call(
      base.deleteRecord,
      action.primaryKey,
      action.logicalEntityIdOrName,
      dataToSave
    );
    if (!response.errors) {
      if (action.redirectToGrid) {
        if (action.gridId) {
          const messages: string[] = [];
          messages.push(
            response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
          );
          yield put(setToggleMessages(messages));
          router.navigate('/app/ce/grid/' + uuid(), {
            state: {
              ceId: action.ceId,
              nodeId: action.nodeId,
              gridId: action.gridId,
            },
          });
        } else {
          const existingCardData = yield select(getCard);
          const formData = existingCardData[action.referralId].data;
          const layoutData = {};
          if (!action.usePortalReferenceData) {
            if (action.referralId.startsWith('0_')) {
              const cardData = new CardData(
                action.referralId,
                existingCardData[action.referralId].type,
                existingCardData[action.referralId].data,
                existingCardData[action.referralId].portalId,
                existingCardData[action.referralId].parentId,
                {},
                {},
                existingCardData[action.referralId].options
              );
              cardData.referenceData.AE_RELOAD = {
                ID: uuid(),
                TS: new Date().getTime(),
                STATUS: 'R',
              };
              yield put(
                setFormData(
                  cardData,
                  action.referralId,
                  action.resetForm,
                  false,
                  true
                )
              );
            } else {
              const cardDetails_1 = yield select(getCard);
              let parentCardId =
                existingCardData[action.referralId].parentCardId;
              let cardDetails = cloneDeep(cardDetails_1);
              let indexRemove = 0;
              cardDetails[parentCardId].data.map((dataObj: any, index: any) => {
                if (dataObj[pkDBCode] === action.primaryKey) {
                  indexRemove = index;
                }
              });
              let arrayDatas = cardDetails[parentCardId].data
                .slice(0, indexRemove)
                .concat(
                  cardDetails[parentCardId].data.slice(
                    indexRemove + 1,
                    cardDetails[parentCardId].data.length
                  )
                );
              cardDetails[parentCardId].data = arrayDatas;
              if (
                indexRemove.toString() &&
                Object.entries(cardDetails[parentCardId].datagridErrorData)
                  .length > 0
              ) {
                cardDetails[parentCardId].datagridErrorData = {};
              }
              Object.keys(cardDetails).forEach((key) => {
                if (key.includes(action.referralId)) {
                  delete cardDetails[key];
                } else if (key.includes(action.parentId)) {
                  delete cardDetails[action.parentId];
                }
              });
              const messages: string[] = [];
              messages.push(
                response.message
                  ? response.message
                  : UpsertMessage.DELETESUCCESSFUL
              );
              const updateStore: any = {
                cardsData: cardDetails,
                toggleMessages: messages,
              };
              yield put(setStore(updateStore));
            }
          } else {
            const cardData = new CardData(
              action.referralId,
              CardType.FORM,
              formData,
              action.portalId,
              action.parentId,
              layoutData,
              {},
              existingCardData[action.referralId].options
            );
            const previousData = existingCardData[action.parentId]
              ? existingCardData[action.parentId].referenceData
              : {};
            Object.assign(previousData, layoutData);
            const portalData = new CardData(
              action.parentId,
              CardType.PORTAL,
              {},
              action.portalId,
              action.parentId,
              previousData
            );
            yield all([
              put(
                setFormData(cardData, action.referralId, false, false, false)
              ),
              put(
                setFormData(portalData, action.parentId, false, false, false)
              ),
            ]);
          }
        }
      }
    } else {
      const messages: string[] = [];
      messages.push(UpsertMessage.VALIDATION_FAILED);
      const appError: any = {
        code: response.code,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      response.errors.map((err: any) => {
        const lastElement =
          err.location.split('=>')[err.location.split('=>').length - 1];
        if (isNaN(Number(lastElement))) {
          appError.errorData.formFieldValidation[err.location] = err.message;
        } else {
          appError.errorData.formValidation[err.location] = err.message;
        }
        return null;
      });
      let existingCardsData = yield select(getCard);
      // const cardsData = { ...existingCardsData };
      let allCardsData = cloneDeep(existingCardsData);
      allCardsData[action.referralId]['errorData'] = appError;
      allCardsData[action.referralId].buttonClicked = '';
      const updateStore: any = {
        cardsData: allCardsData,
        toggleMessages: messages,
      };
      yield put(setStore(updateStore));
    }
  }
}
