import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { SelectOption, FormErrorContainers } from 'info-ui-library';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonCore from '../components/SkeletonCore';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useSelectAllUserProfile } from '../custom-hooks/useSelectAllUserProfile';
import { useErrorData } from '../custom-hooks/useErrorData';
import { ISelectOptionCore } from './interfaces/index';
import {
  processErrorData,
  // processDropDownValues,
  getValueObject,
} from './ComponentsCommonUtils';
import { containsKey } from '../utils/ClientUtils';
import { useFormData } from '../custom-hooks/useFormData';
import { useLocation } from 'react-router';

export const generateUseroptionsInFormat = (selectedOptions: any) => {
  const optionsList = selectedOptions.map((option: any) => {
    const optionObject = {
      value: option[containsKey(option, 'firstName')],
      label: option[containsKey(option, 'firstName')],
      id: option[containsKey(option, 'firstName')],
    };
    return optionObject;
  });
  return optionsList;
};
const UserSelectOptionCore: React.FC<ISelectOptionCore> = (
  props: ISelectOptionCore
) => {
  const themeContext = useContext(ThemeContext);
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  const existingCards = useSelector((state: any) => state.appState.cardsData);

  // const id = props.parentFormId ? props.parentFormId : props.formId;
  // const key =
  //   id + '_' + props.occuranceNumber + '_' + props.configProp.configObjectId;
  let { response, loading } = useSelectAllUserProfile();

  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );

  if (loading) {
    return <SkeletonCore count={1} />;
  }
  let parsedOption = generateUseroptionsInFormat(response);
  const valueAsAnObject = getValueObject(initialvalue, parsedOption);

  const handleChange = (selectedOption: any) => {
    dispatch(
      changeFormData(
        props.formId,
        props.occuranceNumber,
        dbCode,
        selectedOption['value'],
        props.parentFormId,
        props.mode,
        props.nodeId,
        props.configProp.configObjectId,
        props.configProp.refreshFormOnChange,
        props.configProp.resetOnRefresh,
        props.configProp.placeHolder,
        props.referralId,
        currentLocation
      )
    );
  };
  try {
    const isDisplay = existingCards[props.referralId].type !== 'PortalDataGrid';
    if (isDisplay) {
      return (
        <SelectOption
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          value={valueAsAnObject}
          id={props.configProp.configObjectId}
          styleName={themeContext.styleName}
          label={extractLabel(props.configProp.label, props.intl.locale)}
          key={props.configProp.configObjectId}
          options={parsedOption}
          displayType={themeContext.componentType}
          errorMessage={parsedError}
          hasError={Boolean(errorData)}
          onChange={(value: any) => handleChange(value)}
          isSearchable={props.configProp.isSearchable}
          isDisabled={!props.privilegesMap.isEditable}
          isMulti={props.configProp.isMulti}
          mandatory={props.configProp.isMandatory}
        />
      );
    } else {
      return <SkeletonCore count={1} />;
    }
  } catch {
    const error = ['Error occurred in Select Option'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default injectIntl(React.memo(UserSelectOptionCore));
