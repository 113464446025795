import { CardType } from '../constants/appeng.enum';
// import { v4 as uuid } from 'uuid';

export default class CardData {
  id: string;
  type: CardType;
  data: any;
  referenceData: any;
  errorData: any;
  options: any;
  datagridErrorData: any;
  portalId: string;
  parentId: string;
  toggleTabRefresh: boolean;
  workflowActions: any;
  editableGrid: boolean;
  tabGroupId: string;
  entityName: string;
  tabPortalId: string;
  entityId: string;
  parentCardId: string;
  enableFilterInGrid: boolean;
  buttonClicked: string;
  isPreprocessorRunning: boolean;
  isAttachTypeApplicable: boolean;
  paginatedDetails?: any;

  constructor(
    id: string,
    type: CardType,
    data: any,
    portalId: string,
    parentId: string,
    referenceData?: any,
    errorData?: any,
    options?: any,
    datagridErrorData?: any,
    toggleTabRefresh?: boolean,
    editableGrid?: boolean,
    workflowActions?: any,
    tabGroupId?: string,
    entityName?: string,
    tabPortalId?: string,
    entityId?: string,
    parentCardId?: string,
    enableFilterInGrid?: boolean,
    buttonClicked?: string,
    isPreprocessorRunning?: boolean,
    isAttachTypeApplicable?: boolean,
    paginatedDetails?: any
  ) {
    this.id = id;
    this.type = type;
    this.data = data;
    this.referenceData = referenceData ? referenceData : null;
    this.errorData = errorData ? errorData : {};
    this.options = options ? options : {};
    this.datagridErrorData = datagridErrorData ? datagridErrorData : {};
    this.portalId = portalId;
    this.parentId = parentId;
    this.toggleTabRefresh = toggleTabRefresh ? toggleTabRefresh : false;
    this.editableGrid = editableGrid ? editableGrid : false;
    this.workflowActions = workflowActions;
    this.tabGroupId = tabGroupId ? tabGroupId : '';
    this.entityName = entityName ? entityName : '';
    this.tabPortalId = tabPortalId ? tabPortalId : '';
    this.entityId = entityId ? entityId : '';
    this.parentCardId = parentCardId ? parentCardId : '';
    this.enableFilterInGrid = enableFilterInGrid ? enableFilterInGrid : false;
    this.buttonClicked = buttonClicked ? buttonClicked : '';
    this.isPreprocessorRunning = isPreprocessorRunning
      ? isPreprocessorRunning
      : false;
    this.isAttachTypeApplicable = isAttachTypeApplicable
      ? isAttachTypeApplicable
      : false;
    this.paginatedDetails = paginatedDetails ? paginatedDetails : undefined;
    Object.freeze(this);
  }
}
