import { extractLabel } from '../utils/intlutils';
// import { containsKey } from '../utils/ClientUtils';
import { executeJavascriptPreprocessor, containsKey } from '../utils/ClientUtils';

export const processErrorData = (errorData: any, locale: any) => {
  let parsedError = '';
  if (errorData) {
    errorData.split(',').map((message: any) => {
      if (parsedError !== '') {
        parsedError = parsedError + ' , ' + extractLabel(message, locale);
      } else {
        parsedError = extractLabel(message, locale);
      }
    });
  }
  return parsedError;
};

export const generateOption = (options: any, isQuery: any, locale: any) => {
  if (!isQuery) {
    const optionAsList = options.split(',').map((option: any) => {
      const optionObject = {
        value: extractLabel(option.split(':')[0], locale),
        label: extractLabel(option.split(':')[1], locale),
        id: option.split(':')[0],
      };
      return optionObject;
    });

    return optionAsList;
  } else {
    const optionAsList = options.map((option: any) => {
      const optionObject = {
        value: option[containsKey(option, 'id')],
        label: option[containsKey(option, 'label')],
        id: option[containsKey(option, 'id')],
      };
      Object.assign(option, optionObject);
      return option;
    });
    return optionAsList;
  }
};

export const getValueObject = (initialvalue: any, selectedOptions: any) => {
  let value = null;
  if (selectedOptions) {
    selectedOptions.map((object: any) => {
      if (String(object.id) === String(initialvalue)) {
        value = object;
      }
      return null;
    });
  }
  return value;
};

export const getMultiValueObject = (
  initialvalue: any,
  selectedOptions: any
) => {
  let value = [] as any;
  if (selectedOptions && initialvalue) {
    initialvalue.split(',').map((ele: any) => {
      selectedOptions.map((object: any) => {
        if (String(object.id) === String(ele)) {
          value.push(object);
        }
        return null;
      });
    });
  }
  return value;
};
export const processDropDownValues = (
  multivalueList: any,
  locale: any,
  selectedOptions: any
) => {
  let parsedOption;
  if (multivalueList) {
    if (multivalueList.includes('JAVASCRIPT:')) {               //NOSONAR
      const query = multivalueList.replace('JAVASCRIPT:', '');    //NOSONAR
      let data = executeJavascriptPreprocessor(query, {});
      parsedOption = generateOption(data, true, locale);
    } else {
      parsedOption = generateOption(multivalueList, false, locale);
    }
  } else if (selectedOptions) {
    parsedOption = generateOption(selectedOptions, true, locale);
  }
  return parsedOption;
};

const generateRadioButtonOption = (options: any, isQuery: any, locale: any) => {
  const optionObject: any = {};
  if (!isQuery) {
    options.split(',').map((option: any) => {
      const optionRecord = option.split(':');
      optionObject[optionRecord[0]] = extractLabel(optionRecord[1], locale);
      return null;
    });
    return optionObject;
  } else {
    options.map((option: any) => {
      optionObject[option[containsKey(option, 'id')]] = extractLabel(
        option[containsKey(option, 'label')],
        locale
      );
      return null;
    });
    return optionObject;
  }
};

export const processRadioButtonOptions = (
  multivalueList: any,
  locale: any,
  selectedOptions: any
) => {
  let parsedOption;
  if (multivalueList) {
    parsedOption = generateRadioButtonOption(multivalueList, false, locale);
  } else if (selectedOptions) {
    parsedOption = generateRadioButtonOption(selectedOptions, true, locale);
  }
  return parsedOption;
};
