import React from 'react';
import { RadioButton } from 'info-ui-library';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const EditableRadioCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [value, setValue] = React.useState(cell.value);
  const [isEditable, setEditable] = React.useState(false);

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;
  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  const onChange = (e) => {
    setValue(e);
    if (value !== e && updateMyData) {
      updateMyData(row.index, id, e, row.id);
    }
  };

  // const handleBlur = () => {
  //   setEditable(false);
  // };

  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  const handleClick = () => {
    if (errorData || (errorData && errorData.path !== row.id.toString())) {
      setEditable(false);
    } else {
      setEditable(true);
    }
  };
  // let tooltipID = 'radioButtonError_' + id + '_' + row.id.toString();
  return cell.column.editEnable && isEditable && cell.column?.changeEnable ? (
    // <React.Fragment>
      <div
        style={{
          height: '100%',
          position: 'relative',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '90%'
              : '99%',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <RadioButton
          value={value}
          onChange={(e) => onChange(e)}
          options={cell.column.optionList}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          className={'radioForm'}
          isInlineGridComponent={true}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    // </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true && value ? (
        <div onClick={() => handleClick()} style={{ padding: '0 10px' }} role='presentation'>
          {value}
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          onClick={() => handleClick()}
          role='presentation'
        ></div>
      )}
    </React.Fragment>
  );
};

EditableRadioCell.propTypes = {
  row: PropTypes.any, 
  cell: PropTypes.any,
  column: PropTypes.any, 
  'column.id': PropTypes.any,
  updateMyData: PropTypes.any,
  'cell.value': PropTypes.any, 
  'cell.column.useErrorDataUsingDBCode': PropTypes.any, 
  'cell.column.referralId': PropTypes.any, 
  'row.id': PropTypes.any,
  'row.index': PropTypes.any, 
  'cell.column.editEnable': PropTypes.any,
  'cell.column.changeEnable': PropTypes.any,
  'cell.column.optionList': PropTypes.any, 
  'cell.column.styleName': PropTypes.any,
  'cell.column.ioTheme': PropTypes.any, 
  'cell.column.ioMode': PropTypes.any, 
  'cell.isRepeatedValue': PropTypes.any, 
};

export default React.memo(EditableRadioCell);
