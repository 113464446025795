import React, { useEffect } from 'react';
import moment from 'moment';
import { DateTimePicker } from 'info-ui-library';
import DataGridToolTip from './DataGridToolTip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const DateTimePickerCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [value, setValue] = React.useState(cell.value);
  // const [isEditable, setEditable] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);
  const [eventValue, setEventValue] = React.useState('');
  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
      row.id.toString(),
      id,
      cell.column.referralId,
      true
    )
    : null;

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setIsEditable(true);
      // setEditable(true);
    } else {
      setIsEditable(false);
      // setEditable(false);
    }
  }, [errorData]);

  const handleClick = () => {
    if (errorData || (errorData && errorData.path !== row.id.toString())) {
      setIsEditable(false);
      // setEditable(false);
    } else {
      setIsEditable(true);
      // setEditable(true);
    }
  };

  const onChange = (e) => {
    let dateTime = e ? moment(e).format('YYYY-MM-DD HH:mm:ss') : null;
    setEventValue(dateTime);
    setValue(e);
  };

  const onBlur = () => {
    setIsEditable(false);
    // setEditable(false);
  };

  const onClickEvent = () => {
    if (value && value !== eventValue && updateMyData) {
      updateMyData(row.index, id, eventValue, row.id);
    }
    setIsEditable(false);
    // setEditable(false);
  };

  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value]);

  // let tooltipID = 'timePickerError_' + id + '_' + row.id.toString();
  let newFormat =
    cell?.column?.dateFormat
      ? cell?.column?.dateFormat.replaceAll('d', 'D')
      : cell?.column?.dateFormat;
  // cell.column && cell.column.dateFormat
  //   ? cell.column.dateFormat.replaceAll('d', 'D')
  //   : cell.column.dateFormat;
  newFormat = newFormat ? newFormat.replaceAll('a', 'A') : newFormat;
  // let utcTime = value
  //   ? moment.utc(value)
  //   : value
  //     ? moment.utc(value).toDate()
  //     : null;
  let utcTime;

  if (value) {
    utcTime = moment.utc(value);
  } else if (value) {
    utcTime = moment.utc(value).toDate();
  } else {
    utcTime = null;
  }
  let utcToLocalDate = utcTime ? moment(utcTime).local().format() : null;
  let utcToLocalDateNonEditable = utcTime
    ? moment(utcTime).local().format(newFormat)
    : null;
  const [valueEdit, setValueEdit] = React.useState(utcToLocalDateNonEditable);
  useEffect(() => {
    setValue(utcToLocalDate);
  }, [utcToLocalDate, isEditable]);

  useEffect(() => {
    setValueEdit(utcToLocalDateNonEditable);
  }, [utcToLocalDateNonEditable]);

  const handleOnBlur = () => {
    //    setEditable(false);
  };

  // let maximumDate =
  //   cell.column &&
  //     cell.column.configProp &&
  //     cell.column.configProp.maxDate &&
  //     cell.column.configProp.maxDate === 'Today'
  //     ? new Date()
  //     : cell.column &&
  //       cell.column.configProp &&
  //       cell.column.configProp.maxDate &&
  //       cell.column.configProp.maxDate !== 'Today'
  //       ? new Date(cell.column.configProp.maxDate)
  //       : undefined;

  let maximumDate = cell.column?.configProp?.maxDate === 'Today'
    ? new Date()
    : cell.column?.configProp?.maxDate
      ? new Date(cell.column.configProp.maxDate)
      : undefined;


  // let minimumDate =
  //   cell.column &&
  //     cell.column.configProp &&
  //     cell.column.configProp.minDate &&
  //     cell.column.configProp.minDate === 'Today'
  //     ? new Date()
  //     : cell.column &&
  //       cell.column.configProp &&
  //       cell.column.configProp.minDate &&
  //       cell.column.configProp.minDate !== 'Today'
  //       ? new Date(cell.column.configProp.minDate)
  //       : undefined;

  let minimumDate = cell.column?.configProp?.minDate === 'Today'
    ? new Date()
    : cell.column?.configProp?.minDate
      ? new Date(cell.column.configProp.minDate)
      : undefined;

  return cell.column.editEnable && isEditable && cell.column?.changeEnable ? (
    // <React.Fragment>
    <div
      style={{
        border:
             errorData?.dbCode === id
          // errorData && errorData['dbCode'] && errorData['dbCode'] === id
            ? '1px solid red'
            : 'none',
        borderRadius: '5px',
      }}
      data-tooltip-id={errorData?.message}
      data-tooltip-content={errorData?.message}
    >
      <DateTimePicker
        styleName={cell.column.styleName}
        ioTheme={cell.column.ioTheme}
        ioMode={cell.column.ioMode}
        label={''}
        displayType={'SingleLine'}
        showTimeSelect={false}
        timeInputLabel="Time:"
        showTimeInput
        showYearDropdown={cell.column.configProp.showYearDropdown}
        showMonthDropdown={cell.column.configProp.showMonthDropdown}
        dateFormat={cell.column.dateFormat}
        maxDate={maximumDate}
        minDate={minimumDate}
        onBlur={handleOnBlur}
        timeIntervals={15}
        autoComplete="off"
        onChange={(e) => onChange(e)}
        selected={value ? new Date(value) : null}
        isInlineGridComponent={true}
        widthCell={cell.column.width}
        withPortal
        shouldCloseOnSelect={false}
        onCalendarClose={onClickEvent}
        className={errorData && !value ? 'inlineGridError' : 'inlineGrid'}
      />
      <ReactTooltip
        place={'bottom'}
        style={{
          zIndex: 9999,
          color: 'white !important',
          backgroundColor:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? 'rgb(255, 0, 0)'
              : 'none',
        }}
        id={
          errorData && errorData['dbCode'] && errorData['dbCode'] === id
            ? errorData.message
            : ''
        }
      />
    </div>
    // </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true &&
        value !== undefined &&
        value !== null &&
        value !== '' ? (
        <div
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
          role='presentation'
          style={{
            padding: '10px 10px',
            height: '100%',
          }}
        >
          <DataGridToolTip
            width={cell.column.width}
            tooltipValue={
              // value && valueEdit && isEditable ? valueEdit : valueEdit
              valueEdit
            }
          />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
          role='presentation'
        ></div>
      )}
    </React.Fragment>
  );
};

DateTimePickerCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    isRepeatedValue: PropTypes.any,
    column: PropTypes.shape({
      useErrorDataUsingDBCode: PropTypes.any,
      id: PropTypes.any,
      referralId: PropTypes.any,
      dateFormat: PropTypes.any,
      ioMode: PropTypes.any,
      configProp: PropTypes.shape({
        maxDate: PropTypes.any,
        minDate: PropTypes.any,
        showYearDropdown: PropTypes.any,
        showMonthDropdown: PropTypes.any,
      }),
      editEnable: PropTypes.any,
      changeEnable: PropTypes.any,
      styleName: PropTypes.any,
      ioTheme: PropTypes.any,
      width: PropTypes.any,
    }),
  }),

  row: PropTypes.shape({
    id: PropTypes.any,
    index: PropTypes.any,
  }),

  column: PropTypes.shape({
    id: PropTypes.any,
    dateFormat: PropTypes.any,
    configProp: PropTypes.shape({
      maxDate: PropTypes.any,
      minDate: PropTypes.any,
      showYearDropdown: PropTypes.any,
      showMonthDropdown: PropTypes.any,
    }),
    width: PropTypes.any,
  }),

  updateMyData: PropTypes.any,
};

export default React.memo(DateTimePickerCell);
