import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
 
const DataGridToolTip = ({ width, tooltipValue }) => {
  const tooltipRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tooltipId = tooltipValue ? `tooltip-${uuidv4()}` : '';
 
 
  useEffect(() => {
    if (tooltipRef.current) {
      setIsOverflowing(
        tooltipRef.current.scrollWidth > tooltipRef.current.clientWidth
      );
    }
  }, [tooltipValue]);
 
  return (
    <>
      {tooltipId && (
        <div
          ref={tooltipRef}
          data-tooltip-id={isOverflowing ? tooltipId : undefined}
          data-tooltip-content={isOverflowing ? tooltipValue : undefined}
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            width: width + 'px',
            //width:'100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
           {tooltipValue}
        </div>
      )}
      {isOverflowing && (
        <ReactTooltip id={tooltipId} style={{ zIndex: 1000 }} />
      )}
    </>
  );
};
 
DataGridToolTip.propTypes = {
  width: PropTypes.any,
  tooltipValue: PropTypes.any
};
 
export default React.memo(DataGridToolTip);