import React from 'react';
import { SelectOption } from 'info-ui-library';
import PropTypes from 'prop-types';

function SelectColumnFilter({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    styleName,
    ioMode,
    ioTheme,
    formId,
    occuranceNumber,
    configProp,
    processDropDownValues,
    cardsData,
    parentProps,
  },
}) {
  const [value, setValue] = React.useState({ value: undefined, label: 'All' });
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const onChange = (e) => {
    setFilter(e.id);
    setValue(e);
  };
  let optionList = [];

  if (configProp && optionList.length === 0 && configProp.multivalueList) {
    optionList = processDropDownValues(
      configProp.multivalueList,
      parentProps.intl.locale,
      optionList
    );
  } else if (configProp && optionList.length === 0 && cardsData) {
    let referenceID =
      formId + '_' + occuranceNumber + '_' + configProp.configObjectId;
    optionList = cardsData.options[referenceID]
      ? cardsData.options[referenceID]
      : [];
  }
  let newOptionList = [{ id: undefined, label: 'All', value: 'All' }];
  if (optionList.length === 0) {
    options.forEach(function (option, index) {
      let optionMap = {};
      optionMap['id'] = option;
      optionMap['label'] = option;
      optionMap['value'] = option;
      optionList.push(optionMap);
    });
  } else {
    optionList.forEach(function (optionLi, index) {
      let optionId = optionLi.id ? optionLi.id : optionLi.Id ? optionLi.Id : optionLi.ID;
      let optionValue = optionLi.label ? optionLi.label : optionLi.Lable ? optionLi.Lable : optionLi.LABEL;
      if (options.includes(optionId)) {
        let optionMap = {};
        optionMap['id'] = optionId;
        optionMap['label'] = optionValue;
        optionMap['value'] = optionValue;
        newOptionList.push(optionMap);
      }
    });
  }

    const customStyles = {
        singleValue: (base, state) => ({
            ...base,
            'padding-bottom': '3px!important',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'black!important'
        }),
    }
   
    return (
        <SelectOption
            value={value}
            onChange={(e) => onChange(e)}
            styleName={styleName}
            ioTheme={ioTheme}
            ioMode={ioMode}
            configId={"grid_filter_" + configProp?.configObjectId}
            label={""}
            displayType={'SingleLine'}
            options={newOptionList}
            isInlineGridComponent={true}
            styles={customStyles}
            isAsyncOption={true}
        />
    )
}

SelectColumnFilter.propTypes = {
  column: PropTypes.any,
  'column.filterValue': PropTypes.any,
  'column.setFilter': PropTypes.any,
  'column.preFilteredRows': PropTypes.any,
  'column.id': PropTypes.any,
  'column.styleName': PropTypes.any,
  'column.ioMode': PropTypes.any,
  'column.ioTheme': PropTypes.any,
  'column.formId': PropTypes.any,
  'column.occuranceNumber': PropTypes.any,
  'column.configProp': PropTypes.any,
  'column.processDropDownValues': PropTypes.any,
  'column.cardsData': PropTypes.any,
  'column.parentProps': PropTypes.any,
  'column.preFilteredRows.forEach': PropTypes.any,
  'column.configProp.multivalueList': PropTypes.any,
  'column.parentProps.intl.locale': PropTypes.any,
  'column.parentProps.intl': PropTypes.any,
  'column.configProp.configObjectId': PropTypes.any,
  'column.cardsData.options': PropTypes.any
};

export default SelectColumnFilter;
