import AuthUser from '../model/auth.user';
import { ISignUpParams } from '../model/user.info';
import UserSession from '../model/user.session';
import AuthProvider from '../provider/auth.provider';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import { SignUpResult } from '../types';
import { InfoAuthProvider } from '../provider';
import api from '../info-auth-api/api';
import * as jose from 'jose';
import { tokenHandler } from '../utils/aws.token.validator';
import { SECRET_KEY } from '../constants';

export default class InfoAuth {
  private static authInstance: InfoAuth;
  private authProvider?: AuthProvider;
  private infoAuthProvider?: InfoAuthProvider;
  private coreConfig: any;

  private constructor() {}

  public setConfig(coreConfig: any) {
    this.coreConfig = coreConfig;
  }

  public getConfig() {
    return this.coreConfig;
  }

  public setAuthProvider(authProvider: AuthProvider) {
    this.authProvider = authProvider;
  }

  public setInfoAuthProvider(infoAuthProvider: InfoAuthProvider) {
    this.infoAuthProvider = infoAuthProvider;
  }

  public static get INSTANCE() {
    if (InfoAuth.authInstance === undefined) {
      InfoAuth.authInstance = new InfoAuth();
    }
    return InfoAuth.authInstance;
  }

  public isConfigured() {
    return this.authProvider !== undefined;
  }

  public async currentAuthenticatedUser(
    params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    return await this.getAuthProvider().currentAuthenticatedUser(params);
  }

  public async providerTokenSetter(): Promise<AuthUser>{
    const authUser: AuthUser = await this.getAuthProvider().providerTokenSetter();
    return authUser;
  }

  public async signIn(
    username: string,
    password: string,
    locale: string
  ): Promise<AuthUser | void> {
    const authUser: AuthUser = await this.getAuthProvider().signIn(
      username,
      password,
      locale
    );
    const currentSession = await this.currentSession();
    const authToken = currentSession?.accessToken?.token;
    if (this.coreConfig.activityTracking.isRequired) {
      await createActivityTracking('Login', authToken, this.coreConfig);
    }
    return authUser;
  }

  public async signOut(): Promise<string> {
    let currentSession;
    try {
      currentSession = await this.currentSession();
    } catch (e) {
      return await this.getAuthProvider().signOut();
    }
    const authToken = currentSession.accessToken.token;
    const result: string = await this.getAuthProvider().signOut();
    if (this.coreConfig.activityTracking.isRequired) {
      await createActivityTracking('Logout', authToken, this.coreConfig);
    }
    return result;
  }

  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    const res: SignUpResult = await this.getAuthProvider().signUp(params);
    if (this.infoAuthProvider) {
      await this.infoAuthProvider.signUpUserProfile(params, res);
    }
    return res;
  }

  public async confirmSignUp(
    username: string,
    code: string,
    transactionId: string
  ): Promise<string> {
    return await this.getAuthProvider().confirmSignUp(
      username,
      code,
      transactionId
    );
  }

  public async insertDefaultUserMapping(
    userId: string,
    transactionId: string
  ): Promise<string> {
    if (this.infoAuthProvider) {
      return await this.infoAuthProvider.insertDefaultUserMapping(
        userId,
        transactionId
      );
    }
    return 'FAILURE';
  }

  public async resendSignUp(username: string): Promise<CodeDeliveryInfo> {
    return await this.getAuthProvider().resendSignUp(username);
  }

  public async changePassword(
    user: string,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    return await this.getAuthProvider().changePassword(
      user,
      oldPassword,
      newPassword
    );
  }

  public async forgotPassword(username: string): Promise<CodeDeliveryInfo> {
    return await this.getAuthProvider().forgotPassword(username);
  }

  public async forgotPasswordSubmit(
    username: string,
    code: string,
    password: string
  ): Promise<string> {
    return await this.getAuthProvider().forgotPasswordSubmit(
      username,
      code,
      password
    );
  }

  public async currentSession(): Promise<UserSession> {
    return await this.getAuthProvider().currentSession();
  }

  public async updateUserAttributes(
    user: AuthUser,
    attributes: object
  ): Promise<string> {
    return 'SUCCESS';
  }

  private getAuthProvider() {
    if (this.authProvider !== undefined) {
      return this.authProvider;
    }
    throw new Error('Configuration is not done ');
  }
}

const createActivityTracking = async (
  userActivity: any,
  token: any,
  coreConfig: any
) => {
  let userData: any = {};
  switch (coreConfig.provider.type.default) {
    case 'aws':
      userData = await tokenHandler(token);
      break;
    case 'infoauth':
      userData = jose.jwtVerify(token, SECRET_KEY);
      break;

    default:
      throw new Error('Invalid Auth Provider');
  }

  const userDataMap = new Map(Object.entries(userData));
  const activityTrackingData = {
    userActivityTarget: userActivity,
    userActivityType: userActivity,
    projectId: 0,
    applicationId: 0,
    userAction: userActivity,
    timestamp: new Date(),
    createdByUserId: userDataMap.get('sub'),
    createdByUserName: userDataMap.get('sub'),
  };

  const res = await api.createActivityTracking(
    coreConfig.activityTracking.url,
    activityTrackingData,
    token,
    coreConfig.provider.type.default
  );
};
