import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: -5px !important;
  max-height: 250px !important;
  overflow: auto !important;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
function MultiSelectColumnFilter({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    styleName,
    ioMode,
    ioTheme,
    formId,
    occuranceNumber,
    configProp,
    processDropDownValues,
    cardsData,
    parentProps,
  },
}) {
  const [dropDownState, setDropState] = useState(false);
  const [selectedOptionList, setSelectedOptionList] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState('');
  let optionList = [];
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const onHandleClick = (id, isChecked) => {
    let modifiedOptionList = selectedOptionList;
    if (isChecked) {
      modifiedOptionList.push("'" + id + "'");
    } else {
      let index = modifiedOptionList.indexOf("'" + id + "'");
      if (index > -1) {
        modifiedOptionList.splice(index, 1);
      }
    }

    let optionData = '';
    let optionLabelData = '';
    if (modifiedOptionList.length) {
      modifiedOptionList.forEach(function (value, index) {
        let modifyData = value.replace("'", '').replace("'", '');
        if (!optionData) {
          optionData = modifyData;
        } else {
          optionData = optionData + ',' + modifyData;
        }

        optionList.forEach(function (option, index) {
          if (modifyData === option.id) {
            if (!optionLabelData) {
              optionLabelData = option.label;
            } else {
              optionLabelData = optionLabelData + ' , ' + option.label;
            }
          }
        });
      });
    }
    setFilter(optionData);
    setSelectedLabel(optionLabelData);
    setSelectedOptionList(modifiedOptionList);
  };

  if (configProp && optionList.length === 0 && configProp.multivalueList) {
    optionList = processDropDownValues(
      configProp.multivalueList,
      parentProps.intl.locale,
      optionList
    );
  } else if (configProp && optionList.length === 0 && cardsData) {
    let referenceID =
      formId + '_' + occuranceNumber + '_' + configProp.configObjectId;
    optionList = cardsData.options[referenceID]
      ? cardsData.options[referenceID]
      : [];
  }
  let newOptionList = [];

  if (optionList.length === 0) {
    options.forEach(function (option, index) {
      let optionMap = {};
      optionMap['id'] = option;
      optionMap['label'] = option;
      optionMap['value'] = option;
      optionList.push(optionMap);
    });
  } else {
    optionList.forEach(function (optionLi, index) {
      if (options.length) {
        options.forEach(function (value, index) {
          if (value) {
            let splitData = value.split(',');
            if (splitData.includes(optionLi.id)) {
              if (!newOptionList.includes(optionLi)) {
                newOptionList.push(optionLi);
              }
            }
          }
        });
      }
    });
  }

  const toggleDropdown = () => {
    setDropState(!dropDownState);
  };

  return (
    // <React.Fragment>
      <StyledDropdown
        direction="down"
        isOpen={dropDownState}
        toggle={toggleDropdown}
      >
        <DropdownToggle nav>
          <div
            title={selectedLabel}
            style={{ color: 'white', fontWeight: '500', height: '20px' }}
          >
            {`${selectedOptionList.length} of ${newOptionList.length} Selected`}
          </div>
        </DropdownToggle>

        {newOptionList.length ? (
          <StyledDropdownMenu
            positionFixed={true}
            selectedLabel={selectedLabel}
          >
            {newOptionList
              ? newOptionList.map((component) => {
                  let selected_context_List = selectedOptionList;
                  let isChecked = selected_context_List.includes(
                    "'" + component.id + "'"
                  );
                  // let isChecked = selected_context_List.includes(
                  //   "'" + component.id + "'"
                  // )
                  //   ? true
                  //   : false;
                  return (
                    <DropdownItem
                      onClick={() => onHandleClick(component.id, !isChecked)}
                    >
                      <label id={component.id}>
                        <input
                          type="checkbox"
                          defaultChecked={isChecked}
                          id={component.id}
                        />
                        &nbsp;{component.label}
                      </label>
                    </DropdownItem>
                  );
                })
              : null}
          </StyledDropdownMenu>
        ) : (
          ''
        )}
      </StyledDropdown>
    // </React.Fragment>
  );
}

MultiSelectColumnFilter.propTypes = {
  column: PropTypes.any,
  'column.filterValue': PropTypes.any,
  'column.setFilter': PropTypes.any,
  'column.preFilteredRows': PropTypes.any,
  'column.id': PropTypes.any,
  'column.styleName': PropTypes.any,
  'column.ioMode': PropTypes.any,
  'column.ioTheme': PropTypes.any,
  'column.formId': PropTypes.any,
  'column.occuranceNumber': PropTypes.any,
  'column.configProp': PropTypes.any,
  'column.processDropDownValues': PropTypes.any,
  'column.cardsData': PropTypes.any,
  'column.parentProps': PropTypes.any,
  'column.preFilteredRows.forEach': PropTypes.any,
  'column.configProp.multivalueList': PropTypes.any,
  'column.parentProps.intl': PropTypes.any,
  'column.parentProps.intl.locale': PropTypes.any,
  'column.configProp.configObjectId': PropTypes.any,
  'column.cardsData.options': PropTypes.any
};


export default MultiSelectColumnFilter;
