import { AuthMessageEnum, ErrorCodeEnum } from '../constants';
// import { lazy } from 'react';
import AuthError from '../model/auth.error';
import AuthUser from '../model/auth.user';
import AuthUserSession from '../model/user.session';
import { ISignUpParams, IUserInfo, UserInfo } from '../model/user.info';
import { JWTToken, UserSession } from '../model';
import { SignUpResult } from '../types';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import AuthProvider from './auth.provider';
import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  PopupRequest,
} from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

// const ACCESS_TOKEN = 'accesstoken';
const createAuthUser = (
  idToken: any,
  accessToken: any,
  locale: any
): AuthUser => {
  const decodedAccessToken: any = jwtDecode(accessToken);
  const decodedIdToken: any = jwtDecode(idToken);
  const appid = decodedAccessToken.appid
    ? decodedAccessToken.appid
    : decodedAccessToken.aud.includes('api://')
      ? decodedAccessToken.aud.split('://')[1]
      : decodedAccessToken.aud;
  if (decodedAccessToken) {
    let customAttributesList: any = Object.keys(decodedAccessToken).filter(
      (key) => key.includes('extn.')
    );

    let customAttributes: any = {};
    customAttributes['appId'] = appid;
    if (customAttributesList.length) {
      customAttributesList.map((attribute: any) => {
        let attri = attribute.replace('extn.', '');
        customAttributes['APP_LOGGED_IN_USER_CONTEXT_' + attri] =
          decodedAccessToken[attribute] && decodedAccessToken[attribute][0]
            ? decodedAccessToken[attribute][0]
            : 'Not Mapped Yet';
      });
    }

    const userInfo: IUserInfo = {
      preferred_username: decodedAccessToken.preferred_username,
      family_name: decodedAccessToken.family_name,
      given_name: decodedAccessToken.given_name,
      email: decodedAccessToken.email,
      name: decodedAccessToken.name,
      sub: decodedAccessToken.sub,
      oid: decodedAccessToken.oid,
      profile: 'ACTIVE',
      role: decodedAccessToken.roles,
      customAttributes: customAttributes,
    };
    const sessions: AuthUserSession = new AuthUserSession(
      idToken,
      accessToken,
      locale
    );
    const authUser: AuthUser = new AuthUser(
      decodedIdToken.preferred_username,
      new UserInfo(decodedIdToken.sub, decodedAccessToken.oid, userInfo),
      sessions
    );
    return authUser;
  }
  throw new Error('sub or userName attrb is missing');
};

export class MicrosoftADProvider implements AuthProvider {
  private config: any;
  private authClient: any;
  private account: AccountInfo | any;
  private loginRequest: PopupRequest | any;
  private silentProfileRequest: SilentRequest | any;

  constructor(config: any) {
    this.config = config;
    const microsoftADConfig = config.provider[config.provider.type];
    this.authClient = new PublicClientApplication(microsoftADConfig);
    this.account = null;
    this.setRequestObjects(config);
  }

  public async providerTokenSetter(): Promise<AuthUser> {
    throw new Error(`Yet to implement`);
  }

  private async setRequestObjects(config: any): Promise<void> {
    this.loginRequest = {
      scopes: ['openid', 'profile'],
    };
    this.silentProfileRequest = {
      scopes: ['openid', 'profile'],
      account: this.account,
      forceRefresh: false,
    };
    if (config.provider[config.provider.type].customScope.length) {
      config.provider[config.provider.type].customScope.map((scope: any) => {
        this.loginRequest.scopes.push(scope);
        this.silentProfileRequest.scopes.push(scope);
      });
    }
    await this.authClient.initialize();
  }

  private getAccount(userName: any) {
    // need to call getAccount here?
    const currentAccounts = this.authClient.controller.getAllAccounts();
    if (currentAccounts === null || currentAccounts.length === 0) {
      return null;
    }

    if (currentAccounts.length > 1) {
      for (let userAccount of currentAccounts) {
        if (userAccount.homeAccountId.split('.')[0] === userName) {
          return userAccount;
        }
      }
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }

  private async handleResponse(
    response: AuthenticationResult | null,
    userName: string
  ) {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount(userName);
    }
  }

  /**
   * Log In
   * @param username
   * @param password
   */
  public async signIn(
    userName: string,
    password: string,
    locale: string
  ): Promise<AuthUser | any> {
    try {
      //Before doing login clear cache for msal browser
      this.authClient.controller.browserStorage?.temporaryCacheStorage?.windowStorage?.clear();
      const resp: AuthenticationResult =
        await this.authClient.controller.loginPopup(this.loginRequest);
      await this.handleResponse(resp, resp.uniqueId);
      return createAuthUser(resp.idToken, resp.accessToken, locale);
    } catch (e) {
      switch (e.errorCode) {
        case 'interaction_required':
        case 'consent_required':
        case 'login_required':
          throw new AuthError(e.errorMessage);
        default:
          throw e;
      }
    }
  }
  /**
   * Sign up with username, password and other attrbutes like phone, email
   * @param params
   */
  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    try {
      const res: SignUpResult = {
        sub: '',
        preferred_username: '',
        isUserConfirmed: false,
      };
      return res;
    } catch (e) {
      const apiError =
        e.errorResponse.errors && e.errorResponse.errors.length
          ? e.errorResponse.errors[0]
          : undefined;
      if (!apiError) {
        throw e;
      }
      switch (apiError.reason) {
        case ErrorCodeEnum.INVALID_EMAIL_FORMAT:
          throw new AuthError(
            apiError.message,
            ErrorCodeEnum.INVALID_EMAIL_FORMAT
          );
        case ErrorCodeEnum.WEAK_PASSWORD:
          throw new AuthError(apiError.message, ErrorCodeEnum.WEAK_PASSWORD);
        default:
          throw new AuthError(e.message, ErrorCodeEnum.SYSTEM_ERROR);
      }
      // throw e;
    }
  }

  public async signUpUserProfile(params: ISignUpParams, res: SignUpResult) {
    try {
    } catch (e) {
      throw e;
    }
  }

  /**
   * Send the verification code to confirm sign up
   * @param username
   * @param code
   */
  public async confirmSignUp(
    userName: string,
    code: string,
    transactionId: string
  ): Promise<string> {
    // try {
    //   return 'SUCCESS';
    // } catch (e) {
    //   throw e;
    // }
    return 'SUCCESS';
  }

  /**
   * Request to resend the verification code
   * @param username
   */
  public async resendSignUp(username: string): Promise<CodeDeliveryInfo> {
    try {
      const delivery: CodeDeliveryInfo = {
        attributeName: '',
        deliveryMedium: '',
        destination: '',
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  /**
   * logout
   */
  public async signOut(): Promise<string> {
    const logOutRequest: EndSessionRequest = {
      account: this.account,
    };

    this.authClient.controller.logout(logOutRequest);
    return 'SUCCESS';
  }

  /**
   * Change password by authenticated user
   * @param user
   * @param oldPassword
   * @param newPassword
   */
  public async changePassword(
    userName: string,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    // try {
    //   return 'SUCCESS';
    // } catch (e) {
    //   throw e;
    // }
    return 'SUCCESS';
  }

  /**
   * Initiate a forgot password request
   * @param username
   */
  public async forgotPassword(userName: string): Promise<CodeDeliveryInfo> {
    try {
      const delivery: CodeDeliveryInfo = {
        attributeName: '',
        deliveryMedium: '',
        destination: '',
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Confirm a new password using a confirmation Code
   * @param username
   * @param code
   * @param password
   */
  public async forgotPasswordSubmit(
    userName: string,
    code: string,
    password: string
  ): Promise<string> {
    // try {
    //   return 'SUCCESS';
    // } catch (e) {
    //   throw e;
    // }
    return 'SUCCESS';
  }

  /**
   * Get current authenticated user
   * @param params
   */
  public async currentAuthenticatedUser(
    params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    if (!this.account) {
      await this.handleResponse(null, '');
    }
    if (this.account) {
      this.silentProfileRequest.account = this.account;
      const response: AuthenticationResult =
        await this.authClient.controller.acquireTokenSilent(
          this.silentProfileRequest
        );
      if (response.idToken && response.accessToken) {
        return createAuthUser(response.idToken, response.accessToken, 'en');
      }
    } else {
      return undefined;
    }
    // kept the below code for reference. This basically refers how to fetch token brom browser
    // let accessToken: any = "";
    // const idToken: any = await this.authClient.controller.browserStorage.getIdTokenEntity(this.config.provider[config.provider.type].auth.clientId
    //     , this.account);
    // const cacheRecords: any = await this.authClient.controller.browserStorage.getCache();
    // Object.keys(cacheRecords).map(key => {
    //     if (key.includes(ACCESS_TOKEN)) {
    //         accessToken = JSON.parse(cacheRecords[key]).secret;
    //     }
    // })
    // return createAuthUser(idToken.secret, accessToken, 'en');
  }

  /**
   * Get current user's session
   */
  public async currentSession(): Promise<UserSession | any> {
    try {
      if (!this.account) {
        await this.handleResponse(null, '');
      }
      if (this.account) {
        this.silentProfileRequest.account = this.account;
        const response: AuthenticationResult =
          await this.authClient.controller.acquireTokenSilent(
            this.silentProfileRequest
          );
        if (response.idToken && response.accessToken) {
          return new UserSession(
            new JWTToken(response.idToken),
            new JWTToken(response.accessToken),
            ' '
          );
        }
      } else {
        // return new AuthError(
        //   AuthMessageEnum.USER_NOT_LOGGEDIN,
        //   ErrorCodeEnum.USER_NOT_LOGGEDIN
        // );
        return undefined;
      }
    } catch (error) {
      throw new Error(`Error getting session: ${error.message}`);
    }
  }

  /**
   *
   * @param user Update user attributes
   * @param attributes
   */
  public async updateUserAttributes(
    user: AuthUser,
    attributes: object
  ): Promise<string> {
    return 'SUCCESS';
  }
}

let microsoftADProvider: MicrosoftADProvider;

const createMicrosoftADProvider = (config: any): MicrosoftADProvider => {
  if (microsoftADProvider === null || microsoftADProvider === undefined) {
    microsoftADProvider = new MicrosoftADProvider(config);
  }
  return microsoftADProvider;
};

export default createMicrosoftADProvider;
