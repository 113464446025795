import React, { useState, useEffect } from 'react';
import { Button } from 'info-ui-library';
import PropTypes from 'prop-types';

const TablePagination = ({
  filteredRows,
  gotoPage,
  canPreviousPage,
  pageCount,
  canNextPage,
  pageIndex,
  pageOptions,
  setPageSize,
  pageSize,
  nextPage,
  previousPage,
  styleName,
  ioTheme,
  ioMode,
  totalRows,
}) => {
  const [inputValue, setInputValue] = useState(pageIndex + 1);
  const [currentPage, setCurrentPage] = useState(pageIndex);
  // useEffect(() => {
  //   setRowsInTable(pageOptions.length ? pageOptions.length : totalRows);
  // }, [pageOptions, totalRows]);

  useEffect(() => {
    if (currentPage !== pageIndex && currentPage <= pageOptions.length) {
      setInputValue(currentPage + 1);
      gotoPage(currentPage);
    } else {
      setInputValue(pageIndex + 1);
    }
    // if(currentPage>=pageOptions.length ){
    //   gotoPage(0)
    // }
    
  }, [pageIndex]);
  useEffect(()=>{
    setCurrentPage(0);
    gotoPage(0);
  },[pageOptions.length])

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const page = Number(value);
    if (page >= 1 && page <= pageOptions.length) {
      setCurrentPage(page - 1);
      gotoPage(page - 1); // Convert to 0-based index
    }
  };

  const handleBlur = () => {
    const page = Number(inputValue);
    if (page < 1) {
      setInputValue(1);
      // setCurrentPage(0); // Convert to 0-based index
      gotoPage(0);
    } else if (page > pageOptions.length) {
      setInputValue(pageOptions.length);
      setCurrentPage(pageOptions.length - 1);
      gotoPage(pageOptions.length - 1); // Convert to 0-based index
    }
  };

  const onForward = () => {
    setCurrentPage(pageIndex + 1);
    nextPage();
  };

  const onBackward = () => {
    setCurrentPage(pageIndex - 1);
    previousPage();
  };
  const onFastForward = () => {
    setCurrentPage(pageCount - 1);
    gotoPage(pageCount - 1);
  };

  const onFastBackward = () => {
    setCurrentPage(0);
    gotoPage(0);
  };
  return (
    <div
      style={{
        padding: '3px',
      }}
    >
        <>
          <Button
            onClick={() => onFastBackward()}
            disabled={!canPreviousPage}
            styleName={styleName}
            ioTheme={ioTheme}
            ioMode={ioMode}
            size="sm"
            className="fa fa-fast-backward"
            name={''}
            style={{
              padding: '0.1rem 1.6rem',
              fontSize: '1rem',
            }}
          />
          <Button
            onClick={() => onBackward()}
            disabled={!canPreviousPage}
            styleName={styleName}
            ioTheme={ioTheme}
            ioMode={ioMode}
            size="sm"
            className="fa fa-step-backward"
            name={''}
            style={{
              padding: '0.1rem 1.6rem',
              fontSize: '1rem',
            }}
          />
          <Button
            onClick={() => onForward()}
            disabled={!canNextPage}
            styleName={styleName}
            ioTheme={ioTheme}
            ioMode={ioMode}
            size="sm"
            className="fa fa-step-forward"
            name={''}
            style={{
              padding: '0.1rem 1.6rem',
              fontSize: '1rem',
            }}
          />
          <Button
            onClick={() => onFastForward()}
            disabled={!canNextPage}
            styleName={styleName}
            ioTheme={ioTheme}
            ioMode={ioMode}
            size="sm"
            className="fa fa-fast-forward"
            name={''}
            style={{
              padding: '0.1rem 1.6rem',
              fontSize: '1rem',
            }}
          />
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span style={{ marginTop: '-3px' }}>
            | Go to page:{' '}
            <input
              type="number"
              value={inputValue}
              min={1}
              max={pageOptions.length}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{
                width: '90px',
                fontSize: '1rem',
                padding: '0px 5px',
              }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{
              padding: '0px 5px',
              marginTop: '-3px',
              marginLeft: '5px',
              fontSize: '1rem',
              '@media (maxWidth: 600px)': {
                fontSize: '0.8rem',
              },
            }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </>
      
      <span style={{ marginLeft: '5px' }}>
        Total number of records:{' '}
        <strong> {filteredRows ? filteredRows.length : totalRows ? totalRows : ''}</strong>
      </span>
    </div>
  );
};

TablePagination.propTypes = {
  filteredRows: PropTypes.any,
  gotoPage: PropTypes.any,
  canPreviousPage: PropTypes.any,
  pageCount: PropTypes.any,
  canNextPage: PropTypes.any,
  pageIndex: PropTypes.any,
  pageOptions: PropTypes.any,
  setPageSize: PropTypes.any,
  pageSize: PropTypes.any,
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  styleName: PropTypes.any,
  ioTheme: PropTypes.any,
  ioMode: PropTypes.any,
  totalRows: PropTypes.any,
};


export default React.memo(TablePagination);
