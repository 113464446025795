import { useSelector } from 'react-redux';

export function useErrorData(location: any, referralId: any) {
  const existingCardsData = useSelector(
    (state: any) => state.appState.cardsData
  );
  const existingErrorData = existingCardsData[referralId]
    ? existingCardsData[referralId].errorData
    : [];

  let errorMessage = undefined;
  if (
    existingErrorData['code'] === 406 &&
    existingErrorData['errorData']['formFieldValidation'][location]
  ) {
    errorMessage =
      existingErrorData['errorData']['formFieldValidation'][location];
  }
  return errorMessage;
}

export function useErrorDataUsingDBCode(
  path: any,
  dbCode: any,
  referralId: any,
  applicableToSingle: any
) {
  const existingCardsData = useSelector(
    (state: any) => state.appState.cardsData
  );
  const existingErrorData = existingCardsData[referralId]
    ? existingCardsData[referralId].datagridErrorData
    : undefined;
  if(existingErrorData?.[path]?.[dbCode]){
  // if (
  //   existingErrorData &&
  //   existingErrorData[path] &&
  //   existingErrorData[path][dbCode]
  // ) {
    let errorMessage: any = {};
    errorMessage['path'] = existingErrorData[path]['path'];
    errorMessage['message'] = existingErrorData[path][dbCode];
    errorMessage['dbCode'] = dbCode;
    return errorMessage;
  }
  let result : any ;
  if (existingErrorData) {
    if (Object.entries(existingErrorData).length === 0) {
      result = undefined;
    } else if (applicableToSingle) {
      result = existingErrorData[path] ? existingErrorData : undefined;
    } else {
      result = existingErrorData;
    }
  } else {
    result = undefined;
  }
  return result;
  // return existingErrorData
  //   ? Object.entries(existingErrorData).length === 0
  //     ? undefined
  //     : applicableToSingle
  //       ? existingErrorData[path]
  //         ? existingErrorData
  //         : undefined
  //       : existingErrorData
  //   : undefined;
}
