import React, { useCallback, useContext } from 'react';
import DataGrid from './dataGridComponents/Info_Data_Grid';
import { FormErrorContainers } from 'info-ui-library';
import { ThemeContext } from '../../theme/ThemeContext';
import IDataGrid from '../../models/idatagrid.model';
// import { GridType } from '../../constants/appeng.enum';
import { injectIntl } from 'react-intl';
import { UserContext } from '../../user-context/UserContext';
import { getPrivilege } from '../../utils/ClientUtils';
import { NOPRIVILEGE,  GridType } from '../../constants/appeng.enum';
import {
  dataGridPropsExtractor,
  dataGridSelectOptionExtractor,
} from '../containers/DataGridColumnExtracter';
import {
  downloadGridReport,
  getPortalGridSelectedRowData,
  updateInlineRecord,
  getGridColumnOptions,
} from '../../actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorDataUsingDBCode } from '../../custom-hooks/useErrorData';
import { processDropDownValues } from '../../components/ComponentsCommonUtils';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';

interface IUIBasicTableGenerator {
  datagrid: any;
  entityName: string;
  entityId: string;
  nodeId: string;
  gridType: string;
  gridData: any;
  primaryDBCode: string;
  insertFormId: string;
  editFormId: string;
  intl: any;
}

const UIBasicTableGenerator: React.FC<IUIBasicTableGenerator> = (
  props: any
) => {
  const themeContext = useContext(ThemeContext);
  const currentLocation = useLocation();
  const datagridConfig: IDataGrid = props.datagrid;
  const userContext = useContext(UserContext);
  let pageIndex = useSelector(
    (state: any) => state.appState.gridIndexes[props.referralId]
  );
  let cardsData = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  // let cardsDataSatus = cardsData
  //   ? cardsData.data
  //     ? cardsData.data.length > 0
  //       ? 'pass'
  //       : 'empty'
  //     : undefined
  //   : undefined;
  let cardsDataSatus;
  if(cardsData){
    cardsDataSatus = cardsData?.data
        ? cardsData?.data?.length > 0
          ? 'pass'
          : 'empty'
        : undefined
  }else{
    cardsDataSatus = undefined;
  }
  let privilegeDG ;
  const dispatch = useDispatch();
  privilegeDG = getPrivilege(
    datagridConfig.privileges,
    userContext.getCurrentRole()
  );

  const updateDataInline = useCallback(
    (
      rowdata: any,
      keyMap: any,
      referralId: any,
      data: any,
      setSelectedPath: any,
      datagrid: any
    ) => {
      rowdata.valueMap = { ...data, ...rowdata.valueMap };
      dispatch(
        updateInlineRecord(
          keyMap.primaryKey,
          keyMap.logicalEntityId,
          keyMap.nodeId,
          keyMap.ceId,
          rowdata,
          referralId,
          'Update',
          setSelectedPath,
          datagrid
        )
      );
    },
    []
  );

  const handleClick = useCallback(
    (
      column: any,
      rowDetails: any,
      props: any,
      navigate: any,
      currentLocation: any
    ) => {
      const rowDetail = rowDetails.original;
      if (props.handleClick) {
        props.handleClick(column, rowDetail, props, props.setCardPortalId);
      } else {
        switch (column.actionColumnType) {
          case 'View/Edit':
            if (
              props.gridType === GridType.PARENT &&
              props.isPortalGrid != true
            ) {
              navigate({
                pathname: '/app/ce/form/' + uuidv4(),
                state: {
                  referenceData:
                    currentLocation.state != undefined
                      ? currentLocation.state.data
                      : props.gridData,
                  data: rowDetail,
                  formType: 'Edit',
                  ceId:
                    currentLocation.state != undefined
                      ? currentLocation.state.ceId
                      : props.portalGrid.ceId,
                  nodeId:
                    currentLocation.state != undefined
                      ? currentLocation.state.nodeId
                      : props.portalGrid.nodeId,
                  nodeType:
                    currentLocation.state != undefined
                      ? currentLocation.state.nodeType
                      : '',
                  insertFormId: props.insertFormId,
                  editFormId: props.editFormId,
                  primaryDBCode: props.primaryDBCode,
                  mode: 'Edit',
                },
              });
            } else if (props.isPortalGrid == true) {
              dispatch(
                getPortalGridSelectedRowData(props.referralId, rowDetail)
              );
            } else {
              navigate({
                pathname: '/app/ce/child/form',
                state: {
                  referenceData: currentLocation.state.data,
                  data: rowDetail,
                  formType: 'Edit',
                  ceId: currentLocation.state.ceId,
                  nodeId: currentLocation.state.nodeId,
                  nodeType: currentLocation.state.nodeType,
                  insertFormId: props.insertFormId,
                  editFormId: props.editFormId,
                  primaryDBCode: props.primaryDBCode,
                },
              });
            }
            break;
          case 'Custom':
            let certificate_name = column.hrefValue;
            certificate_name = certificate_name.split('=').pop();
            certificate_name = certificate_name.replace(
              new RegExp('-.*', 'gm'),
              ''
            );

            certificate_name = certificate_name + '-' + props.intl.locale;

            navigate({
              pathname: '/app/ce/pdfcertificate',
              state: {
                hrefValue: certificate_name,
                rowDetail: rowDetail,
                ceId: currentLocation.state.ceId,
                nodeId: currentLocation.state.nodeId,
                gridId: currentLocation.state.gridId,
              },
            });
            break;
          case 'CustomReport':
            dispatch(
              downloadGridReport(
                rowDetail[props.primaryDBCode],
                'Tax_Collection-10(Form4)',
                props.intl.locale,
                props.primaryDBCode,
                currentLocation
              )
            );
            break;
        }
      }
    },
    []
  );

  const gridHeaderButtonsClick = useCallback((button: any, props: any) => {
    if (props.gridButtonClick) {
      props.gridButtonClick(button, props);
    }
  }, []);

  let selectOptionList: any = dataGridSelectOptionExtractor(datagridConfig);
  if (
    selectOptionList &&
    selectOptionList.optionIds.length > 0 &&
    cardsDataSatus !== 'empty'
  ) {
    let isRunCall = false;
    for(let a of selectOptionList.optionIds){
      let referenceID =
        selectOptionList['formId'] +
        '_' +
        selectOptionList['occuranceNumber'] +
        '_' +
        a;
      if (cardsData.options[referenceID] === undefined) {
        isRunCall = true;
        break;
      }
    }
    // for (let i = 0; i < selectOptionList.optionIds.length; i++) {
    //   let referenceID =
    //     selectOptionList['formId'] +
    //     '_' +
    //     selectOptionList['occuranceNumber'] +
    //     '_' +
    //     selectOptionList.optionIds[i];
    //   if (cardsData.options[referenceID] === undefined) {
    //     isRunCall = true;
    //     break;
    //   }
    // }
    if (isRunCall) {
      dispatch(
        getGridColumnOptions(
          selectOptionList['parentFormId'],
          selectOptionList['formId'],
          selectOptionList.optionIds,
          selectOptionList['occuranceNumber'],
          props.nodeId,
          props.referralId,
          currentLocation,
          props.referenceData
        )
      );
    }
  }

  let returnData = dataGridPropsExtractor(
    datagridConfig,
    userContext,
    themeContext.styleName,
    themeContext.ioTheme,
    themeContext.ioMode,
    props.intl.locale,
    handleClick,
    props,
    useErrorDataUsingDBCode,
    cardsData,
    processDropDownValues
  );



  let loaded =
    props.gridData.length === 0 || selectOptionList.optionIds.length === 0
      ? true
      : selectOptionList.optionIds.length <=
          Object.keys(cardsData.options).length
        ? true
        : false;
  try {
    if (privilegeDG !== NOPRIVILEGE) {
      const newProps = {
        ...props,
        styleName: themeContext.styleName,
        ioTheme: themeContext.ioTheme,
        ioMode: themeContext.ioMode,
        borderEnable: returnData.dataGridProps.borderEnable,
        strippedEnable: returnData.dataGridProps.strippedEnable,
        hoverEnable: returnData.dataGridProps.hoverEnable,
        columns: returnData.dataGridProps.columns,
        data: props.gridData,
        scrollEnable: returnData.dataGridProps.scrollEnable,
        serverPaginationEnable: returnData.dataGridProps.serverPaginationEnable,
        subComponentEnable: returnData.dataGridProps.subComponentEnable,
        subComponentType: returnData.dataGridProps.subComponentType,
        primaryDBCode: props.primaryDBCode,
        logicalEntityId: props.entityId,
        nodeId: props.nodeId,
        ceId: currentLocation.state.ceId
          ? currentLocation.state.ceId
          : props.portalGrid
            ? props.portalGrid.ceId
            : undefined,
        insertFormId: props.insertFormId,
        editFormId: props.editFormId,
        updateDataInline: updateDataInline,
        errorDataExtractor: useErrorDataUsingDBCode,
        cardWidth: props.cardWidth,
        cardHeight: props.cardHeight,
        referralId: props.referralId,
        pageIndex: pageIndex,
        cardsDataSatus: cardsDataSatus,
        usePortalReferenceData: props.usePortalReferenceData,
        portalId: props.portalId,
        parentId: props.parentId,
        isRowSelectionEnable: returnData.dataGridProps.rowSelectionEnable,
        gridHeaderButtons: returnData.dataGridProps.gridButtons.filter(button => button.type=='button'),
        gridMeuButtons: returnData.dataGridProps.gridButtons.filter(button => button.type=='menuButton'),
        gridHeaderButtonsClick: gridHeaderButtonsClick,
        gridDataAlignment: returnData.dataGridProps.gridDataAlignment,
        gridHeaderAlignment: returnData.dataGridProps.gridHeaderAlignment,
        loadedIcon: loaded,
      };
      return <DataGrid {...newProps} />;
    } else {
      const error = ['Dynamic UI Table has no privilege'];
      return (
        <FormErrorContainers
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={'danger'}
          lists={error}
        />
      );
    }
  } catch (e) {
    const error = ['Error occurred in Dynamic UI Table'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};
function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.gridData) === JSON.stringify(nextProps.gridData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable &&
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData)
  );
}
export default injectIntl(React.memo(UIBasicTableGenerator));
