import React from 'react';
// import React, { useState, useEffect } from 'react';
// import { call, put } from 'redux-saga/effects';
import { userDetailsJwtKey } from '../api/index';
import { createStorage } from '../storage';
import { AUTH_TOKEN } from '../constants/appeng.enum';

export function useSelectAllUserProfile() {
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const storage = createStorage();
        const authToken = storage.getItem(AUTH_TOKEN);
        const userDetails = await userDetailsJwtKey();
        const headers: any = {
          authorization: authToken,
          userDetails: userDetails,
          provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
        };
        const res = await fetch(
          window.env.REACT_APP_APPENG_AUTH_URL + '/userProfiles',
          { headers: headers }
        );
        const json = await res.json();
        setResponse(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { response, error, loading };
}
