import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DateTimePicker, FormErrorContainers } from 'info-ui-library';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { extractLabel } from '../utils/intlutils';
import { ThemeContext } from '../theme/ThemeContext';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { changeFormData } from '../actions/index';
import { IDateTimePickerCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const DateTimePickerCore: React.FC<IDateTimePickerCore> = (
  props: IDateTimePickerCore
) => {
  const currentLocation = useLocation();

  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );

  let utcTime :any;
  if(initialvalue && props.configProp.isApplyDateFormat){
    utcTime = moment.utc(initialvalue)
  }else{
    utcTime = initialvalue ? moment.utc(initialvalue).toDate() : null;
  }

  // let utcTime =
  //   initialvalue && props.configProp.isApplyDateFormat
  //     ? moment.utc(initialvalue)
  //     : initialvalue
  //       ? moment.utc(initialvalue).toDate()
  //       : null;
  let utcToLocalDate = utcTime ? moment(utcTime).local().format() : null;

  const [value, setValue] = React.useState(utcToLocalDate);

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  let maximumDate: any;
  if(props?.configProp?.maxDate === 'Today'){
    maximumDate = new Date();
  }else{
    maximumDate = props?.configProp?.maxDate !== 'Today' ? new Date(props?.configProp?.maxDate) : undefined ;
  }
  // let maximumDate: any =
  //   props.configProp.maxDate && props.configProp.maxDate === 'Today'
  //     ? new Date()
  //     : props.configProp.maxDate && props.configProp.maxDate !== 'Today'
  //       ? new Date(props.configProp.maxDate)
  //       : undefined;


  let minimumDate: any;
  if(props?.configProp?.minDate === 'Today'){
    minimumDate = new Date();
  }else{
    minimumDate = props?.configProp?.minDate !== 'Today' ?  new Date(props?.configProp?.minDate) : undefined ;
  }
  // let minimumDate: any =
    // props.configProp.minDate && props.configProp.minDate === 'Today'
    //   ? new Date()
    //   : props.configProp.minDate && props.configProp.minDate !== 'Today'
    //     ? new Date(props.configProp.minDate)
    //     : undefined;

  const themeContext = useContext(ThemeContext);
  const [selectedDateTime, setSelectedDateTime] = useState(
    initialvalue ? new Date(initialvalue) : null
  );

  useEffect(() => {
    if (
      initialvalue &&
      new Date(initialvalue) &&
      selectedDateTime &&
      selectedDateTime.getTime() !== new Date(initialvalue).getTime()
    ) {
      let formattedDate: any;
      if (initialvalue) {
        formattedDate = new Date(initialvalue);
      }
      setSelectedDateTime(formattedDate);
    }
  });
  const dispatch = useDispatch();
  try {
    const handleChange = useCallback((updatedValue: any, e: any) => {
      setValue(updatedValue);
      let extractedDate: any = updatedValue
        ? moment(updatedValue).format('YYYY-MM-DD HH:mm:ss')
        : null;
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          dbCode,
          extractedDate,
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
      if (e) {
        e.preventDefault();
      }
    }, []);

    return (
      <DateTimePicker
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        displayType={themeContext.componentType}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        onChange={(updatedValue: any, e: any) => handleChange(updatedValue, e)}
        mandatory={props.configProp.isMandatory}
        showYearDropdown={props.configProp.showYearDropdown}
        showMonthDropdown={props.configProp.showMonthDropdown}
        dateFormat={props.configProp.formatDate}
        selected={value ? new Date(value) : null}
        errorMessage={parsedError}
        autoComplete="off"
        timeIntervals={15}
        configId={props.configProp.configObjectId}
        disabled={!props.privilegesMap.isEditable}
        hasError={Boolean(errorData)}
        maxDate={maximumDate}
        minDate={minimumDate}
        showTimeSelect={false}
        timeInputLabel="Time:"
        showTimeInput
        shouldCloseOnSelect={false}
      />
    );
  } catch {
    const error = ['Error occurred in DatePicker'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(DateTimePickerCore));
