import {
  PrivilegeEnum,
  NOPRIVILEGE,
  UpsertMessage,
} from '../constants/appeng.enum';
import DOMPurify from 'dompurify';
const { ApolloClient } = await import('@apollo/client');
const { InMemoryCache } = await import('@apollo/client');

window.env.REACT_APP_APPENG_META_URL =
    window.env.HOST_URL + '/api-meta/graphql';
export const client = new ApolloClient({
  uri: window.env.REACT_APP_APPENG_META_URL,
  cache: new InMemoryCache(),
});

export const parseStringToObject = (parsingInput: string) => {
  let objToReturn: any = {};
  if (parsingInput) {
    parsingInput.split(';').forEach((key) => {
      objToReturn[key.split(':')[0]] = key.split(':')[1];
    });
  }
  return Object.keys(objToReturn).length ? objToReturn : '';
};

export function containsKey(object: any, key: any) {
  const value = Object.keys(object).find(
    (k) => k.toLowerCase() === key.toLowerCase()
  );
  return value ? value : '';
}

export const isExpressionResolved = (expression: any, input: any) => {
  expression = manipulateExpression(expression, input);
  try {
    return DOMPurify.sanitize(eval(expression));  //NOSONAR
  } catch (e) {
    return false;
  }
};

const manipulateExpression = (expression: any, input: any) => {
  const regexOr = new RegExp('\\b' + 'or' + '\\b', 'ig');
  if (expression.startsWith('JAVASCRIPT$$')) {
    expression = expression.substring(expression.indexOf('$') + 2);
    expression = expression.replace(regexOr, '||');
    return expression;
  } else {
    expression = expression
      .replace(/#/, '')
      .replace(/{/, '')
      .replace(/}/, '')
      .replace(regexOr, '||');

    Object.entries(input).forEach(([key, value]) => {
      if (key !== '' && key !== undefined) {
        const reg = new RegExp('\\b' + key + '\\b', 'g');
        const value = 'input.' + key;
        expression = expression.replace(reg, value);
      }
    });
    return expression;
  }
};

export const getPrivilege = (privileges: any[], currentRole: any[]) => {
  if (!privileges) {
    privileges = ['NOPRIVILEGE'];
  }
  const hasPrivileges: any[] = [];

  if (!privileges) {
    return NOPRIVILEGE;
  }
  currentRole.forEach(function (element) {
    const selectedPrivilege = privileges
      .filter((privilege) => String(privilege['roleId']) === String(element))
      .map((privilege) => {
        return privilege.privilegeType;
      });
    if (selectedPrivilege.length > 0) {
      hasPrivileges.push(selectedPrivilege[0]);
    }
  });
  const broaderPrivilege = getBroaderPrivilege(hasPrivileges);
  return broaderPrivilege;
};

const getBroaderPrivilege = (hasPrivileges: any) => {
  let smallerPriority = 0;
  let broaderPrivilege = NOPRIVILEGE;
  hasPrivileges.forEach(function (privilege: any) {
    if (privilege !== null && PrivilegeEnum[privilege] > smallerPriority) {
      smallerPriority = PrivilegeEnum[privilege];
      broaderPrivilege = privilege;
    } else if (privilege === null || privilege === undefined) {
      broaderPrivilege = NOPRIVILEGE;
    }
  });
  return broaderPrivilege;
};

export const executeJavascriptPreprocessor = (rule: any, input: any) => {
  try {
    return eval('(function() {' + rule + '}())');     //NOSONAR
  } catch (e) {
    return {
      errors: UpsertMessage.JAVASCRIPT_PREPROCESSOR,
      code: 417,
      message: e.message,
    };
  }
};

export const executeJavascriptPreprocessorUsingFunction = (
  rule: any,
  data: any
) => {
  const strFunction =
    '"use strict"; return function(data){' + '\n' + rule + '\n' + '}';

  const dynamicFunc = new Function('data', strFunction)();   //NOSONAR
  return dynamicFunc(data);
};

export const sortConfigObjects = (configs: any) => {
  let configsList = [...configs];
  configsList.sort(function (a: any, b: any) {
    return a.order - b.order;
  });
};
