import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AceEditor, FormErrorContainers } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { IAceEditorCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const AceEditorCore: React.FC<IAceEditorCore> = (props: IAceEditorCore) => {
  const currentLocation = useLocation();
  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    props.configProp.logicalColumn.dbCode,
    props.parentFormId,
    props.referralId
  );

  let errorData: any = useErrorData(props.location, props.referralId);

  let parsedError = processErrorData(errorData, props.intl.locale);

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  try {
    const handleChange = (updatedValue: string) => {
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          props.configProp.logicalColumn.dbCode,
          updatedValue,
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
    };
    return (
      <AceEditor
        mode={'mysql'}
        collapseEnable={true}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={initialvalue}
        displayType={themeContext.componentType}
        handleChange={(editorStateParam: any) => handleChange(editorStateParam)}
        errorMessage={parsedError}
        hasError={Boolean(errorData)}
        mandatory={props.configProp.isMandatory}
        configId={props.configProp.configObjectId}
      />
    );
  } catch {
    const error = ['Error occurred in AceEditor'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(AceEditorCore));
