import React from 'react';
import FormFieldGenerator from './FormFieldGenerator';
import { extractLabel } from '../../utils/intlutils';
import {
  FormSection as LibraryFS,
  Button,
  FormErrorContainers,
} from 'info-ui-library';
import MultiSelectCore from '../../components/MultiSelectCore';
import AutocompleteCore from '../../components/AutocompleteCore';
import TextBoxCore from '../../components/TextBoxCore';
import PhoneNumberInputCore from '../../components/PhoneNumberInputCore';
import RadioButtonCore from '../../components/RadioButtonCore';
import CheckBoxCore from '../../components/CheckBoxCore';
import SelectOptionCore from '../../components/SelectOptionCore';
import UserSelectOptionCore from '../../components/userSelectOptionCore';

import DateTimePickerCore from '../../components/DateTimePickerCore';
import TimePickerCore from '../../components/TimePickerCore';
import { getPrivilege, isExpressionResolved } from '../../utils/ClientUtils';
import { NOPRIVILEGE, EDIT, VIEW } from '../../constants/appeng.enum';
import TextAreaCore from '../../components/TextAreaCore';
import PasswordCore from '../../components/PasswordCore';
import ProfileCore from '../../components/ProfileCore';
import DraftEditorCore from '../../components/DraftEditorCore';
// import { IFormSection } from '../interfaces';
import ChildUITable from '../grid/ChildUITable';
import DatePickerCore from '../../components/DatePickerCore';
import AceEditorCore from '../../components/AceEditorCore';
import LabelCore from '../../components/LabelCore';
import cloneDeep from 'lodash/cloneDeep';
import DropzoneCore from '../../components/DropzoneCore';
import { processNodeEditibility } from '../CompositeEntityCommonUtils';
interface IFormSectionGrid {
  nodeName: any;
  context: any;
  tabOptions: any;
  gridId: String;
  nodeId: String;
  referralId: String;
  routerParameter: any;
  locale: any;
}

const getFormSectionsAndGrid = (
  gridId: any,
  routerParameter: any,
  context: any,
  referralId: any,
  nodeName: any,
  locale: any
) => {
  try {
    return (
      <LibraryFS
        id={gridId}
        componentperrow={1}
        styleName={context.styleName}
        label={extractLabel(nodeName, locale)}
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
      >
        <ChildUITable
          routerParameter={routerParameter}
          referralId={referralId}
          parentId={''}
          portalId={''}
        />
      </LibraryFS>
    );
  } catch {
    const error = ['Error occurred in get FormSections And Grid'];
    return (
      <FormErrorContainers
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

const getFormSectionsAndFF = (
  formSection: any,
  context: any,
  occuranceNumber: any,
  formId: any,
  parentFormId: any,
  mode: any,
  nodeId: any,
  privilegeFS: any,
  isAccessible: any,
  isEditable: any,
  parentEntityId: any,
  childEntityId: any,
  locale: any,
  referralId: any
) => {
  try {
    return (
      <LibraryFS
        id={formSection.configObjectId}
        componentperrow={formSection.componentsPerRow}
        styleName={context.styleName}
        label={extractLabel(formSection.headerLabel, locale)}
        data-tip={extractLabel(formSection.headerLabel, locale)}
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
      >
        <FormFieldGenerator
          componentperrow={formSection.componentsPerRow}
          occuranceNumber={occuranceNumber}
          formSectionId={formSection.configObjectId}
          formId={formId}
          configProp={formSection}
          parentFormId={parentFormId}
          mode={mode}
          nodeId={nodeId}
          privilegeFS={privilegeFS}
          isAccessibleInFs={isAccessible}
          isEditableInFS={isEditable}
          parentEntityId={parentEntityId}
          childEntityId={childEntityId}
          referralId={referralId}
        />
      </LibraryFS>
    );
  } catch {
    const error = ['Error occurred in get FormSections And Formfields'];
    return (
      <FormErrorContainers
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};
const extactDBCodeForParticularFormSection = (
  formSection: any,
  entityId: any
) => {
  let dbCodeMap: any = {};
  formSection.formFields.forEach((formField: any) => {
    let dbCode = entityId + '#' + formField.logicalColumn.dbCode;
    dbCodeMap[dbCode] = '';
  });
  return dbCodeMap;
};

export const generateComplexFormSectionGridCards: React.FC<IFormSectionGrid> = (
  props: IFormSectionGrid
) => {
  let options = { ...props.tabOptions };
  let routerParameterCopy = { ...props.routerParameter };
  routerParameterCopy['gridId'] = props.gridId;
  routerParameterCopy['nodeId'] = props.nodeId;
  let fsLayout = getFormSectionsAndGrid(
    props.gridId,
    routerParameterCopy,
    props.context,
    props.referralId,
    props.nodeName,
    props.locale
  );
  if (options[extractLabel('Family', props.locale)]) {
    options[extractLabel('Family', props.locale)].push(fsLayout);
  } else {
    options[extractLabel(props.nodeName, props.locale)] = [fsLayout];
  }
  return options;
};

export const generateComplexFormSectionCards = (
  nodeName: any,
  formSections: any,
  context: any,
  tabOptions: any,
  formId: any,
  parentFormId: any,
  mode: any,
  occuranceNumber: any,
  handleClick: any,
  isRepeatable: any,
  isFirstFormData: any,
  nodeId: any,
  formData: any,
  userContext: any,
  parentEntityId: any,
  childEntityId: any,
  locale: any,
  addAllowed: any,
  isDeletionAllowed: any,
  formPrivilege: any,
  isFormEditable: any,
  referralId: any,
  tabDetailMap: any,
  setTabDetailMap: any,
  isMaxRepeatCompleted: any,
  isMinRepeatedCompleted: any,
  details?: any
) => {
  try {
    let options = { ...tabOptions };
    let firstFormSection = true;
    const sortderFormSection = [...formSections].sort((a: any, b: any) =>
      a.order > b.order ? 1 : -1
    );
    sortderFormSection.forEach((formSection: any, index: any) => {
      let isAccessible = true;
      let isEditable = isFormEditable;
      let privilegeFS = formPrivilege;
      let tabName = extractLabel(formSection.tabGroup, locale);
      let entityId = childEntityId || parentEntityId;
      // let entityId = childEntityId ? childEntityId : parentEntityId;
      let dbCodeMap = extactDBCodeForParticularFormSection(
        formSection,
        entityId
      );
      const isNewFormData = Boolean(formData?.isNew);
      // const isNewFormData = formData && formData.isNew ? true : false;
      if (tabDetailMap[tabName] === undefined) {
        let tabMap: any = {};
        tabMap[tabName] = dbCodeMap;

        setTabDetailMap({ ...tabDetailMap, ...tabMap });
      } else {
        tabDetailMap[tabName] = { ...tabDetailMap[tabName], ...dbCodeMap };
      }
      privilegeFS = getPrivilege(
        formSection.privileges,
        userContext.getCurrentRole()
      );
      if (
        privilegeFS !== NOPRIVILEGE &&
        formSection.expressionAvailable &&
        Object.entries(formData).length > 0
      ) {
        let updatedFormData = { ...formData };
        Object.assign(updatedFormData, userContext.getUserDetail());
        isAccessible = isConfigObjectAccessible(
          formSection.accessibilityRegex,
          updatedFormData
        );
        isEditable = isConfigObjectEditable(
          isAccessible,
          privilegeFS,
          formSection.editabilityRegex,
          updatedFormData,
          formPrivilege,
          isFormEditable
        );
      }
      if (privilegeFS !== NOPRIVILEGE && isAccessible) {
        let updatedFormData = { ...formData };
        let nodeEditable: any;

        if (details?.nodeEditabilityRegex) {
          nodeEditable = processNodeEditibility(details.nodeExpressionAvailble, details.nodeEditabilityRegex, updatedFormData);
        } else if (details?.portalFormPrivilege === 'VIEW') {
          nodeEditable = false;
        } else {
          nodeEditable = true;
        }

        // let nodeEditable = details && details.nodeEditabilityRegex ? processNodeEditibility(details.nodeExpressionAvailble, details.nodeEditabilityRegex, updatedFormData) : details?.portalFormPrivilege === 'VIEW' ? false : true;
        // let nodeEditable = details && details.nodeEditabilityRegex ? processNodeEditibility(details.nodeExpressionAvailble, details.nodeEditabilityRegex, updatedFormData) : details && details.portalFormPrivilege === 'VIEW' ? false : true;
        let fsLayout;
        if (Object.entries(formData).length > 0) {
          if (isRepeatable) {
            const minusBtnRequired =
              index === Object.entries(sortderFormSection).length - 1 &&
              occuranceNumber < 0;
            fsLayout = (
              <React.Fragment>
                {firstFormSection &&
                  isFirstFormData &&
                  addAllowed &&
                  !isMaxRepeatCompleted && (
                    <div style={{paddingTop:'10px', marginLeft:'-5px'}}>
                    <Button
                      styleName={context.styleName}
                      ioTheme={context.ioTheme}
                      ioMode={context.ioMode}
                      id={'jehbrvberhv-98798'}
                      disabled={!nodeEditable || !isEditable}
                      name={''}
                      onClick={() =>
                        handleClick(
                          formId,
                          parentFormId,
                          'ADD',
                          occuranceNumber,
                          userContext.getUserDetail(),
                          isNewFormData
                        )
                      }
                      className="fa fa-plus"
                    />
                    </div>
                  )}
                {getFormSectionsAndFF(
                  formSection,
                  context,
                  occuranceNumber,
                  formId,
                  parentFormId,
                  mode,
                  nodeId,
                  privilegeFS,
                  isAccessible,
                  isEditable,
                  parentEntityId,
                  childEntityId,
                  locale,
                  referralId
                )}

                {minusBtnRequired && addAllowed && !isMinRepeatedCompleted && (
                  <Button
                    styleName={context.styleName}
                    ioTheme={context.ioTheme}
                    ioMode={context.ioMode}
                    id={'jehbrvberhv-987928'}
                    name={''}
                    disabled={!nodeEditable || !isEditable}
                    onClick={() =>
                      handleClick(
                        formId,
                        parentFormId,
                        'MINUS',
                        occuranceNumber,
                        userContext.getUserDetail(),
                        isNewFormData
                      )
                    }
                    className="fa fa-minus"
                  />
                )}
                {occuranceNumber >= 0 &&
                  isDeletionAllowed &&
                  !isMinRepeatedCompleted &&
                  index === Object.entries(sortderFormSection).length - 1 && (
                    <Button
                      styleName={context.styleName}
                      ioTheme={context.ioTheme}
                      ioMode={context.ioMode}
                      id={'jehbrvberhv-987918'}
                      name={''}
                      disabled={!nodeEditable || !isEditable}
                      onClick={() =>
                        handleClick(
                          formId,
                          parentFormId,
                          'DELETE',
                          occuranceNumber,
                          userContext.getUserDetail(),
                          isNewFormData
                        )
                      }
                      className={
                        isNewFormData ? 'fa fa-minus' : 'fa fa-trash-o'
                      }
                    />
                  )}
              </React.Fragment>
            );
          } else {
            fsLayout = getFormSectionsAndFF(
              formSection,
              context,
              occuranceNumber,
              formId,
              parentFormId,
              mode,
              nodeId,
              privilegeFS,
              isAccessible,
              isEditable,
              parentEntityId,
              childEntityId,
              locale,
              referralId
            );
          }
        } else {
          if (isRepeatable && firstFormSection) {
            fsLayout = (
              // <React.Fragment>
              <div style={{paddingTop:'10px', marginLeft:'-5px'}}>
              <Button
                styleName={context.styleName}
                ioTheme={context.ioTheme}
                ioMode={context.ioMode}
                id={'jehbrvberhv-98798'}
                name={''}
                disabled={!nodeEditable || !isEditable}
                onClick={() =>
                  handleClick(
                    formId,
                    parentFormId,
                    'ADD',
                    occuranceNumber,
                    userContext.getUserDetail(),
                    isNewFormData
                  )
                }
                className="fa fa-plus"
              />
              </div>
              // </React.Fragment>
            );
          }
        }

        if (options[extractLabel(formSection.tabGroup, locale)]) {
          options[extractLabel(formSection.tabGroup, locale)].push(fsLayout);
        } else if (
          nodeName &&
          !formSection.tabGroup &&
          options[extractLabel(nodeName, locale)]
        ) {
          options[extractLabel(nodeName, locale)].push(fsLayout);
        } else {
          if (formSection.tabGroup !== null) {
            options[extractLabel(formSection.tabGroup, locale)] = [fsLayout];
          } else {
            options[extractLabel(nodeName, locale)] = [fsLayout];
          }
        }
      }
      firstFormSection = false;
      return null;
    });

    return options;
  } catch (e) {
    const error = ['Error occurred in generate Complex FormSection Cards'];
    return (
      <FormErrorContainers
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export const generateFormFieldCards = (
  formSection: any,
  formId: any,
  parentFormId: any,
  mode: any,
  occuranceNumber: any,
  nodeId: any,
  privilegeFS: any,
  userContext: any,
  formData: any,
  isEditableInFS: any,
  isAccessibleInFs: any,
  parentEntityId: any,
  childEntityId: any,
  referralId: any
) => {
  const sortedFormFields = cloneDeep(formSection.formFields);
  sortedFormFields.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));

  const formFieldCards = sortedFormFields.map((formField: any) => {
    let isEditable = isEditableInFS;
    let isAccessible = isAccessibleInFs;
    let isMandatoryIconAccessibilityRegex = isConfigObjectAccessible(
      formField.mandatoryIconAccessibilityRegex,
      formData
    );
    const dbCode = formField.logicalColumn
      ? formField.logicalColumn.dbCode
      : formField.dbCode;
    let location: string;

    if (childEntityId) {
      location = parentEntityId + '=>' + 0 + '=>' + childEntityId + '=>' + occuranceNumber + '=>' + dbCode;
    } else if (parentEntityId) {
      location = parentEntityId + '=>' + occuranceNumber + '=>' + dbCode;
    } else {
      location = formId + '=>' + occuranceNumber + '=>' + dbCode;
    }
      // const location = childEntityId
      //   ? parentEntityId +
      //   '=>' +
      //   0 +
      //   '=>' +
      //   childEntityId +
      //   '=>' +
      //   occuranceNumber +
      //   '=>' +
      //   dbCode
      //   : parentEntityId
      //     ? parentEntityId + '=>' + occuranceNumber + '=>' + dbCode
      //     : formId + '=>' + occuranceNumber + '=>' + dbCode;
      let privilegesMap;
      privilegesMap = processPrivileges(
        privilegeFS,
        formField.privileges,
        userContext,
        isEditable,
        isAccessible,
        formField.expressionAvailable,
        formField.accessibilityRegex,
        formField.editabilityRegex,
        EDIT,
        formData
      );

      let previewPrivilegesMap, deletePrivilegesMap;
      if (formField.type === 'Dropzone') {
        previewPrivilegesMap = processPrivileges(
          privilegeFS,
          formField.privileges,
          userContext,
          isEditable,
          isAccessible,
          formField.expressionAvailable,
          formField.previewAccessibilityRegex,
          formField.previewEditabilityRegex,
          EDIT,
          formData
        );

        deletePrivilegesMap = processPrivileges(
          privilegeFS,
          formField.privileges,
          userContext,
          isEditable,
          isAccessible,
          formField.expressionAvailable,
          formField.deleteAccessibilityRegex,
          formField.deleteEditabilityRegex,
          EDIT,
          formData
        );
      }

    let buttonPrivilegesMap;
    if (formField.buttons && formField.buttons.length > 0) {
      buttonPrivilegesMap = processPrivileges(
        privilegeFS,
        formField.buttons[0].privileges,
        userContext,
        isEditable,
        isAccessible,
        formField.buttons[0].expressionAvailable,
        formField.buttons[0].accessibilityRegex,
        formField.buttons[0].editabilityRegex,
        EDIT,
        formData
      );
    }
    isEditable = privilegesMap.isEditable;
    isAccessible = privilegesMap.isAccessible;
    if (isAccessible) {
      switch (formField.type) {
        case 'TextBox':
          return (
            <TextBoxCore
              formId={formId}
              mode={mode}
              key={formField.configObjectId}
              configProp={formField}
              parentFormId={parentFormId}
              occuranceNumber={occuranceNumber}
              nodeId={nodeId}
              privilegesMap={privilegesMap}
              parentEntityId={parentEntityId}
              childEntityId={childEntityId}
              location={location}
              referralId={referralId}
              mandatoryIconAccessibilityRegex={
                isMandatoryIconAccessibilityRegex
              }
            />
          );
        case 'Dropzone':
          return (
            <DropzoneCore
              formId={formId}
              mode={mode}
              key={formField.configObjectId}
              configProp={formField}
              parentFormId={parentFormId}
              occuranceNumber={occuranceNumber}
              nodeId={nodeId}
              privilegesMap={privilegesMap}
              previewPrivilegesMap={previewPrivilegesMap}
              deletePrivilegesMap={deletePrivilegesMap}
              parentEntityId={parentEntityId}
              childEntityId={childEntityId}
              location={location}
              referralId={referralId}
              acceptedFileTypes={formField.acceptedFileTypes}
              maxFilesAllowed={formField.maxFilesAllowed}
              isReorderAllowed={formField.isReorderAllowed}
            />
          );

          case 'PhoneNumber':
            return (
              <PhoneNumberInputCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'SelectOption':
            return (
              <SelectOptionCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
                buttonPrivilegesMap={buttonPrivilegesMap}
              />
            );
          case 'UserSelectOption':
            return (
              <UserSelectOptionCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={false}
                buttonPrivilegesMap={buttonPrivilegesMap}
              />
            );
          case 'MultiSelect':
            return (
              <MultiSelectCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
                buttonPrivilegesMap={buttonPrivilegesMap}
              />
            );
          case 'TextArea':
            return (
              <TextAreaCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'Autocomplete':
            return (
              <AutocompleteCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
              />
            );
          case 'RadioButton':
            return (
              <RadioButtonCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'CheckBox':
            return (
              <CheckBoxCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'DatePicker':
            return (
              <DatePickerCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'TimePicker':
            return (
              <TimePickerCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );
          case 'Password':
            return (
              <PasswordCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
              />
            );
          case 'ProfilePic':
            return (
              <ProfileCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                intl
                referralId={referralId}
              />
            );
          case 'CkEditor':
            return (
              <DraftEditorCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={
                  isMandatoryIconAccessibilityRegex
                }
              />
            );

          case 'JsonEditor':
            return (
              <AceEditorCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
              />
            );
          case 'DateTimePicker':
            return (
              <DateTimePickerCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                childEntityId={childEntityId}
                location={location}
                referralId={referralId}
                mandatoryIconAccessibilityRegex={false}
              />
            );
          case 'Label':
            return (
              <LabelCore
                formId={formId}
                mode={mode}
                key={formField.configObjectId}
                configProp={formField}
                parentFormId={parentFormId}
                occuranceNumber={occuranceNumber}
                nodeId={nodeId}
                privilegesMap={privilegesMap}
                parentEntityId={parentEntityId}
                location={location}
                referralId={referralId}
              />
            );
          default:
            return 'No ff available';
        }
      }
    });
  return formFieldCards;
};

export const generateSimpleFormSectionCards = (
  formSections: any,
  formId: any,
  context: any,
  parentFormId: any,
  mode: any,
  occuranceNumber: any,
  nodeId: any,
  formData: any,
  userContext: any,
  formPrivilege: any,
  isFormEditable: any,
  parentEntityId: any,
  locale: any,
  referralId: any
) => {
  try {
    formSections.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    return formSections.map((formSection: any) => {
      let isAccessible = true;
      let isEditable = isFormEditable;
      let privilegeFS;
      // let privilegeFS = formPrivilege;

      privilegeFS = getPrivilege(
        formSection.privileges,
        userContext.getCurrentRole()
      );
      if (privilegeFS !== NOPRIVILEGE && formSection.expressionAvailable) {
        let updatedFormData = { ...formData };
        Object.assign(updatedFormData, userContext.getUserDetail());
        isAccessible = isConfigObjectAccessible(
          formSection.accessibilityRegex,
          updatedFormData
        );
        isEditable = isConfigObjectEditable(
          isAccessible,
          privilegeFS,
          formSection.editabilityRegex,
          updatedFormData,
          formPrivilege,
          isFormEditable
        );
      }
      if (privilegeFS !== NOPRIVILEGE && isAccessible) {
        return (
          <LibraryFS
            key={formSection.configObjectId}
            id={formSection.configObjectId}
            componentperrow={formSection.componentsPerRow}
            styleName={context.styleName}
            label={extractLabel(formSection.headerLabel, locale)}
            ioTheme={context.ioTheme}
            ioMode={context.ioMode}
            data-tip={extractLabel(formSection.headerLabel, locale)}
          >
            <FormFieldGenerator
              componentperrow={formSection.componentsPerRow}
              formSectionId={formSection.configObjectId}
              mode={mode}
              parentFormId={parentFormId}
              formId={formId}
              configProp={formSection}
              occuranceNumber={occuranceNumber}
              nodeId={nodeId}
              privilegeFS={privilegeFS}
              isAccessibleInFs={isAccessible}
              isEditableInFS={isEditable}
              parentEntityId={parentEntityId}
              childEntityId={undefined}
              referralId={referralId}
            />
          </LibraryFS>
        );
      }
      return null;
    });
  } catch {
    const error = ['Error occurred in generate simple formSection cards'];
    return (
      <FormErrorContainers
        ioTheme={context.ioTheme}
        ioMode={context.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

const processPrivileges = (
  privilegeFS: any,
  privileges: any,
  userContext: any,
  isEditable: any,
  isAccessible: any,
  expressionAvailable: any,
  accessibilityRegex: any,
  editabilityRegex: any,
  formPrivilege: any,
  defaultFormData: any
) => {
  let privilegesMap: any = {};
  let privilegeFF = EDIT;
  if (formPrivilege === EDIT && privilegeFS === EDIT) {
    privilegeFF = getPrivilege(privileges, userContext.getCurrentRole());
    if (privilegeFF === EDIT) {
      if (isEditable) {
        isEditable = true;
      } else {
        isEditable = false;
      }
    } else if (privilegeFF === VIEW) {
      isEditable = false;
    } else {
      isAccessible = false;
    }
  } else {
    privilegeFF = getPrivilege(privileges, userContext.getCurrentRole());
    if (privilegeFF === NOPRIVILEGE) {
      isAccessible = false;
    } else {
      isEditable = false;
    }
  }

  if (privilegeFF !== NOPRIVILEGE && expressionAvailable) {
    let updatedFormData = { ...defaultFormData };
    Object.assign(updatedFormData, userContext.getUserDetail());
    isAccessible =
      isAccessible &&
      isFormFieldAccessible(isAccessible, accessibilityRegex, updatedFormData);
    isEditable =
      isEditable &&
      isFormFieldEditable(
        isAccessible,
        isEditable,
        privilegeFS,
        privilegeFF,
        editabilityRegex,
        updatedFormData,
        formPrivilege
      );
  }
  privilegesMap['isEditable'] = isEditable;
  privilegesMap['isAccessible'] = isAccessible;
  return privilegesMap;
};

const isFormFieldAccessible = (
  isAccessible: any,
  accessibilityRegex: any,
  updatedFormData: any
) => {
  if (accessibilityRegex !== '' && accessibilityRegex !== null) {
    if (!isExpressionResolved(accessibilityRegex, updatedFormData)) {
      isAccessible = false;
    } else {
      isAccessible = true;
    }
  }
  return isAccessible;
};

const isFormFieldEditable = (
  isAccessible: any,
  isEditable: any,
  privilegeFS: any,
  privilegeFF: any,
  editabilityRegex: any,
  updatedFormData: any,
  formPrivilege: any
) => {
  if (
    isAccessible &&
    privilegeFS !== VIEW &&
    formPrivilege !== VIEW &&
    privilegeFF !== VIEW &&
    editabilityRegex !== '' &&
    editabilityRegex !== null
  ) {
    if (!isExpressionResolved(editabilityRegex, updatedFormData)) {
      isEditable = false;
    } else {
      isEditable = true;
    }
  }
  return isEditable;
};

export const isConfigObjectAccessible = (
  accessibilityRegex: any,
  updatedFormData: any
) => {
  let isAccessible = true;
  if (
    accessibilityRegex !== '' &&
    accessibilityRegex != null &&
    !isExpressionResolved(accessibilityRegex, updatedFormData)
  ) {
    isAccessible = false;
  }
  return isAccessible;
};

export const isConfigObjectEditable = (
  isAccessible: any,
  currentObjectPrivilege: any,
  editabilityRegex: any,
  updatedFormData: any,
  parentPrivilege: any,
  isEditable: any
) => {
  if (
    isAccessible &&
    parentPrivilege === EDIT &&
    currentObjectPrivilege === EDIT &&
    editabilityRegex !== '' &&
    editabilityRegex != null &&
    !isExpressionResolved(editabilityRegex, updatedFormData)
  ) {
    isEditable = false;
  }
  return isEditable;
};
