import React, { useContext,lazy, Suspense } from 'react';
import { parseReferenceData } from '../portalLayout/PortalCommonUtils';

// import { lazy, Suspense } from 'react';
import { IPortalLayout } from './interfaces';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import { getPrivilege } from '../utils/ClientUtils';
import { UserContext } from '../user-context/UserContext';
import SkeletonCore from '../components/SkeletonCore';

const PortalCardCore = lazy(() => import('./PortalCard'));

const loadingComponent = () => {
  return <SkeletonCore count={1} />;
};

const StyledCol = styled(Col)`
  padding-left: ${(p: any) =>
    p.$portalStyle && p.$portalStyle["padding-left"]
      ? p.$portalStyle["padding-left"] + "!important;"
      : "5px!important"};
  padding-right: ${(p: any) =>
    p.$portalStyle && p.$portalStyle["padding-right"]
      ? p.$portalStyle["padding-right"] + "!important;"
      : "5px!important"};
`;
const StyledHeader = styled(Card.Header)`
  padding: 8px !important;
  background-color: rgb(255, 255, 255) !important;
  font-size: 1.5vw !important;
  @media (max-width: 700px) {
    font-size: 18px !important;
  }
  text-align: center !important;
`;

const StyledCard = styled(Card)`
  width: 100% !important;
`;

const getCardWidth = (width: any) => {
  width = width ? JSON.stringify(width) : 'true';
  switch (width.toLowerCase().trim()) {
    case 'true':
      return 'auto';
    default:
      return parseInt(width);
  }
};
const getCardStyle = (cStyle: any) => {
  const cardStyl = { ...cStyle };
  const cardStyle: string = cardStyl.cardStyle;
  const cardStyleList = cardStyle ? cardStyle.split(';') : [];
  let cardStyleMap: any = {};

  if (cardStyleList) {
    cardStyleList.forEach(function (elem: any) {
      const styleMap = elem.split(':');

      if (styleMap && styleMap.length === 2) {
        cardStyleMap[styleMap[0].trim()] = styleMap[1].trim();
      }
    });
  }
  const cardHeaderStyle = cardStyl.cardHeaderStyle;
  const cardHeaderStyleList = cardHeaderStyle ? cardHeaderStyle.split(';') : [];
  let cardHeaderMap: any = {};

  if (cardHeaderStyleList) {
    cardHeaderStyleList.forEach(function (elem: any) {
      const styleMap = elem.split(':');

      if (styleMap && styleMap.length === 2) {
        cardHeaderMap[styleMap[0].trim()] = styleMap[1].trim();
      }
    });
  }
  cardStyleMap['header'] = cardHeaderMap;

  const cardBodyStyle = cardStyl.cardBodyStyle;
  const cardBodyStyleList = cardBodyStyle ? cardBodyStyle.split(';') : [];
  let cardBStyleMap: any = {};

  if (cardBodyStyleList) {
    cardBodyStyleList.forEach(function (elem: any) {
      const styleMap = elem.split(':');

      if (styleMap && styleMap.length === 2) {
        cardBStyleMap[styleMap[0].trim()] = styleMap[1].trim();
      }
    });
  }
  cardStyleMap['body'] = cardBStyleMap;
  return cardStyleMap;
};
const PortalLayout: React.FC<IPortalLayout> = (props: IPortalLayout) => {
  const userContext = useContext(UserContext);
  let portalCardsMap: any = {};
  if (props.portalCards) {
    let keyWithOrder: any = {};
    props.portalCards.map((cardLayout: any, index: any) => {
      let privilegeFS = 'EDIT';
      if (cardLayout.privileges) {
        privilegeFS = getPrivilege(
          cardLayout.privileges,
          userContext.getCurrentRole()
        );
      }
      if (privilegeFS !== 'NOPRIVILEGE') {
        if (cardLayout.order && !portalCardsMap[cardLayout.order]) {
          keyWithOrder[cardLayout.order] = cardLayout.order;
          portalCardsMap[cardLayout.order] = cardLayout;
        } else if (cardLayout.order && portalCardsMap[cardLayout.order]) {
          portalCardsMap[keyWithOrder[cardLayout.order] + 0.1] = cardLayout;
          keyWithOrder[cardLayout.order] = keyWithOrder[cardLayout.order] + 0.1;
        } else {
          keyWithOrder[index] = index;
          portalCardsMap[index] = cardLayout;
        }
      }
    });
  }
  let sortedKeys = Object.keys(portalCardsMap);
  const data =
    sortedKeys.length > 0 ? (
      sortedKeys.map((key,index) => {
        const dataSharing = portalCardsMap[key].cardDataSharing
          ? portalCardsMap[key].cardDataSharing
          : props.portalDataSharing;
        let portalDataString = parseReferenceData(
          dataSharing,
          portalCardsMap[key],
          props.parentId
        );
        const portalCard = { ...portalCardsMap[key] };
        const responsiveLayoutList = portalCard.responsiveLayout
          ? JSON.parse(portalCard.responsiveLayout)
          : [];
        portalCard.configObjectId =
          props.subComponentParentId + portalCard.configObjectId;
        // const widthByScreenSize = portalCard.w ? portalCard.w.split(',') : null;
        let lg: any = { span: 12 };
        let md: any = { span: 12 };
        let sm: any = { span: 12 };
        let xs: any = { span: 12 };
        responsiveLayoutList.map((responsiveLayout: any) => {
          switch (responsiveLayout.screenSize) {
            case 'lg':
              lg = responsiveLayout.size
                ? responsiveLayout.order
                  ? {
                      span: responsiveLayout.size,
                      order: responsiveLayout.order,
                    }
                  : { span: responsiveLayout.size }
                : 12;
              break;
            case 'md':
              md = responsiveLayout.size
                ? responsiveLayout.order
                  ? {
                      span: responsiveLayout.size,
                      order: responsiveLayout.order,
                    }
                  : { span: responsiveLayout.size }
                : 12;
              break;
            case 'sm':
              sm = responsiveLayout.size
                ? responsiveLayout.order
                  ? {
                      span: responsiveLayout.size,
                      order: responsiveLayout.order,
                    }
                  : { span: responsiveLayout.size }
                : 12;
              break;
            case 'xs':
              xs = responsiveLayout.size
                ? responsiveLayout.order
                  ? {
                      span: responsiveLayout.size,
                      order: responsiveLayout.order,
                    }
                  : { span: responsiveLayout.size }
                : 12;
              break;
          }
        });
        const cardStyle = getCardStyle(portalCard);
        const cardComponent = (
          <Suspense fallback={loadingComponent()}>
            <PortalCardCore
              subComponentParentId={props.subComponentParentId}
              layoutType={props.layoutType}
              parentId={props.parentId}
              cardLayout={{ ...portalCard }}
              portalDataString={portalDataString}
              portalId={props.portalId}
              routerParameter={props.routerParameter}
              cardStyle={{ ...cardStyle }}
              headerButtons={props.headerButtons}
              tabGroupId={props.tabGroupId}
              tabPortalId={props.tabPortalId}
              parentCardId={props.parentCardId}
              key={"portal_card"+index+props.portalId}
            />
          </Suspense>
        );
        return (
          <StyledCol
            xs={xs}
            md={md}
            lg={lg}
            sm={sm}
            $portalStyle={props.portalStyle}
            key={portalCard.configObjectId}
          >
            {cardComponent}{' '}
          </StyledCol>
        );
      })
    ) : (
      <StyledCard>
        {(!props.portalCards ||
          (props.portalCards && props.portalCards.length == 0)) && (
          <StyledHeader>Something is coming soon.</StyledHeader>
        )}
      </StyledCard>
    );
  return (
    <Container fluid>
      <Row>{data}</Row>
    </Container>
  );
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.parentId === nextProps.parentId &&
    Object.is(prevProps.routerParameter, nextProps.routerParameter)
  );
}

export default React.memo(PortalLayout, isPropsAreEqual);
